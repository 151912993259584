import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';

import UserDto from 'types/api/UserDto';

const useColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    const columnHelper = createColumnHelper<UserDto>();

    return [
      columnHelper.accessor((value) => `${value.first_name} ${value.last_name}`, {
        id: 'name',
        header: t('pages.organizationProfile.users.name'),
        sortingFn: 'text',
        size: 40,
      }),
      columnHelper.accessor('user_title', {
        header: t('pages.organizationProfile.users.userTitle'),
        sortingFn: 'text',
        size: 80,
      }),
      columnHelper.accessor('email', {
        header: t('pages.organizationProfile.users.email'),
        sortingFn: 'text',
        size: 80,
      }),
      columnHelper.accessor('user_role', {
        header: t('pages.organizationProfile.users.systemRole'),
        sortingFn: 'text',
        size: 60,
      }),
      columnHelper.accessor('phone_number', {
        header: t('pages.organizationProfile.users.phoneNumber'),
        sortingFn: 'text',
        size: 60,
      }),
    ];
  }, [t]);
};

export default useColumns;
