import React, { FC } from 'react';
import { Column } from '@tanstack/react-table';

import AdvancedDateRangePickerInput, { DateRangeValue } from 'components/forms/raw/AdvancedDateRangePickerInput';
import TicketWithConnection from 'types/TicketWithConnection';

import './TicketDateFilter.scss';

export interface TicketDateFilterProps {
  column: Column<TicketWithConnection, string>;
}

const TicketDateFilter: FC<TicketDateFilterProps> = ({ column }) => {
  const columnFilterValue = (column.getFilterValue() as DateRangeValue) ?? null;

  return (
    <AdvancedDateRangePickerInput
      value={columnFilterValue}
      onChange={column.setFilterValue}
      variant="filled"
      noLabel
      clearable
      className="enkrateia-table-filter enkrateia-ticket-date-filter"
    />
  );
};

export default TicketDateFilter;
