import React, { FC } from 'react';

import { useSelector } from 'store';
import ArchElementsMap from 'types/ArchElementsMap';
import ArchElementGeneric from 'types/ArchElementGeneric';

import { ArchContextProvider } from './context/ArchContext';
import TraroArch from './components/TraroArch/TraroArch';
import NoElementSelectedPlaceholder from './components/NoElementSelectedPlaceholder/NoElementSelectedPlaceholder';
import SpecificElementDescription from './components/SpecificElementDescription/SpecificElementDescription';
import BricksElementsDescription from './components/BricksElementsDescription/BricksElementsDescription';

import './ArchOverview.scss';

export interface ArchOverviewProps {}

const ArchOverview: FC<ArchOverviewProps> = () => {
  const archElement = useSelector((store) => store.arch.archElement);
  return (
    <div className="enkrateia-arch-project-overview">
      <ArchContextProvider>
        <TraroArch />
        {archElement === null && <NoElementSelectedPlaceholder />}
        {archElement !== null && ArchElementsMap[archElement] !== ArchElementGeneric.BRICK && (
          <SpecificElementDescription archElementSpecific={archElement} />
        )}
        {archElement !== null && ArchElementsMap[archElement] === ArchElementGeneric.BRICK && (
          <BricksElementsDescription />
        )}
      </ArchContextProvider>
    </div>
  );
};

export default ArchOverview;
