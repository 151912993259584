import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';

import TicketDto from 'types/api/TicketDto';
import { formatDate } from 'hooks/useFormattedDate';
import { TicketIdCell, MarkdownHoverCell } from 'components/Table';

const useColumns = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    const columnHelper = createColumnHelper<TicketDto>();

    return [
      columnHelper.accessor('id', {
        header: t('common.tickets.ticketNumber'),
        cell: TicketIdCell,
        filterFn: 'includesString',
        size: 40,
      }),
      columnHelper.accessor('ticket_status', {
        header: t('common.tickets.status'),
        size: 40,
      }),
      columnHelper.accessor((value) => `${value.assigned_first_name} ${value.assigned_last_name}`, {
        id: 'assigned',
        header: t('common.tickets.assignedTo'),
        size: 40,
      }),
      columnHelper.accessor((value) => formatDate(value.due_date), {
        id: 'date',
        header: t('common.tickets.dueDate'),
        size: 40,
      }),
      columnHelper.accessor('short_description', {
        header: t('common.tickets.description'),
        filterFn: 'includesString',
        cell: MarkdownHoverCell,
      }),
      columnHelper.accessor('id', {
        id: 'placeholder',
        header: '',
        cell: '',
      }),
    ];
  }, [t]);
};

export default useColumns;
