/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import RFPResponse from 'types/api/RFPResponse';
import RFPResponseStatus from 'types/RFPResponseStatus';
import RFPSubmitResponseStatus from 'types/RFPSubmitResponseStatus';

import {
  saveRfpResponse,
  updateRfpTraroBrick,
  updateRfpElementResponse,
  updateRfpElementScore,
  addElementComment,
  updateKliparoRfpElementScore,
  submitRfpResponse,
  updateKliparoRfpElementStatus,
} from './thunks';
import OrganizationDto from '../../types/api/OrganizationDto';
import QuestionStatus from '../../types/QuestionStatus';
import RFP from '../../types/api/RFP';
import TraroElementResponse from '../../types/api/TraroElementResponse';

interface Slice {
  data: RFPResponse;
  loading: boolean;
  updating: boolean;
  error: string | undefined;
  requestedId: number | undefined;
}

const INITIAL_RFP_RESPONSE_DATA: Slice['data'] = {
  id: -1,
  rfp_response_status: RFPResponseStatus.OPEN,
  submissions_remaining: 0,
  last_update: '',
  project_name: '',
  traro_element_response_list: [],
  rfp_submit_response_status: RFPSubmitResponseStatus.NO_SUBMISSIONS_REMAINING,
  organization: {} as OrganizationDto,
  rfp_response_score: undefined,
  review_due_date: '',
};

export const rfpResponseSlice = createSlice({
  name: 'rfp',
  initialState: {
    data: { ...INITIAL_RFP_RESPONSE_DATA },
    error: undefined,
    loading: false,
    updating: false,
    requestedId: undefined,
  } as Slice,
  reducers: {
    reset: (state) => {
      state.data = { ...INITIAL_RFP_RESPONSE_DATA };
      state.loading = false;
      state.error = undefined;
    },
    setRfpResponseData: (state, { payload }: PayloadAction<{ rfpResponse: RFPResponse; rfp?: RFP }>) => {
      const data = { ...payload.rfpResponse };

      if (payload.rfp) {
        const traroElements = payload.rfp.traro_element_list;

        // For each missing traro response element, create one with basic data
        traroElements.forEach(({ arch_element_specific }) => {
          if (
            data.traro_element_response_list.find((e) => e.arch_element_specific === arch_element_specific) !==
            undefined
          ) {
            return;
          }

          data.traro_element_response_list.push({
            arch_element_specific,
            response_text: '',
            question_score: null,
            question_status: QuestionStatus.BEGIN,
            traro_element_score: [],
            traro_element_comment: [],
            traro_element: -1,
            kliparo_response_list: [],
            ticket_list: [],
          });
        });
      }

      state.data = payload.rfpResponse;
    },
    setRfpElementResponse: (state, { payload }: PayloadAction<TraroElementResponse>) => {
      const index = state.data.traro_element_response_list.findIndex(
        (e) => e.arch_element_specific === payload.arch_element_specific,
      );

      state.data.traro_element_response_list[index] = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveRfpResponse.pending, (state) => {
      state.updating = true;
      state.error = undefined;
    });
    builder.addCase(saveRfpResponse.fulfilled, (state, action) => {
      state.data = action.payload;
      state.updating = false;
    });
    builder.addCase(saveRfpResponse.rejected, (state, action) => {
      state.data = { ...INITIAL_RFP_RESPONSE_DATA };
      state.updating = false;
      state.error = action.error.message;
    });
    builder.addCase(submitRfpResponse.pending, (state) => {
      state.updating = true;
      state.error = undefined;
    });
    builder.addCase(submitRfpResponse.fulfilled, (state, action) => {
      state.data = action.payload;
      state.updating = false;
    });
    builder.addCase(submitRfpResponse.rejected, (state, action) => {
      state.updating = false;
      state.error = action.error.message;
    });
    builder.addCase(updateRfpElementResponse.pending, (state) => {
      state.updating = true;
      state.error = undefined;
    });
    builder.addCase(updateRfpElementResponse.fulfilled, (state, action) => {
      const index = state.data.traro_element_response_list.findIndex(
        (e) => e.arch_element_specific === action.payload.data.arch_element_specific,
      );

      if (index !== -1 && !action.payload.noStoreSync) {
        state.data.traro_element_response_list[index] = action.payload.data;
      }

      state.updating = false;
    });
    builder.addCase(updateRfpElementResponse.rejected, (state, action) => {
      state.data = { ...INITIAL_RFP_RESPONSE_DATA };
      state.updating = false;
      state.error = action.error.message;
    });
    builder.addCase(updateKliparoRfpElementScore.pending, (state) => {
      state.updating = true;
      state.error = undefined;
    });
    builder.addCase(updateKliparoRfpElementScore.fulfilled, (state, action) => {
      const index = state.data.traro_element_response_list.findIndex(
        (e) => e.arch_element_specific === action.payload.arch_element_specific,
      );

      if (index !== -1) {
        state.data.traro_element_response_list[index] = action.payload;
      }

      state.updating = false;
    });
    builder.addCase(updateKliparoRfpElementScore.rejected, (state, action) => {
      state.updating = false;
      state.error = action.error.message;
    });
    builder.addCase(updateKliparoRfpElementStatus.pending, (state) => {
      state.updating = true;
      state.error = undefined;
    });
    builder.addCase(updateKliparoRfpElementStatus.fulfilled, (state, action) => {
      const index = state.data.traro_element_response_list.findIndex(
        (e) => e.arch_element_specific === action.payload.arch_element_specific,
      );

      if (index !== -1) {
        state.data.traro_element_response_list[index] = action.payload;
      }

      state.updating = false;
    });
    builder.addCase(updateKliparoRfpElementStatus.rejected, (state, action) => {
      state.updating = false;
      state.error = action.error.message;
    });
    builder.addCase(updateRfpElementScore.pending, (state) => {
      state.updating = true;
      state.error = undefined;
    });
    builder.addCase(updateRfpElementScore.fulfilled, (state, action) => {
      const index = state.data.traro_element_response_list.findIndex(
        (e) => e.arch_element_specific === action.payload.arch_element_specific,
      );

      if (index !== -1) {
        state.data.traro_element_response_list[index] = action.payload;
      }

      state.updating = false;
    });
    builder.addCase(updateRfpElementScore.rejected, (state, action) => {
      state.data = { ...INITIAL_RFP_RESPONSE_DATA };
      state.updating = false;
      state.error = action.error.message;
    });
    builder.addCase(addElementComment.pending, (state) => {
      state.updating = true;
      state.error = undefined;
    });
    builder.addCase(addElementComment.fulfilled, (state, action) => {
      const index = state.data.traro_element_response_list.findIndex(
        (e) => e.arch_element_specific === action.payload.arch_element_specific,
      );

      if (index !== -1) {
        state.data.traro_element_response_list[index] = action.payload;
      }

      state.updating = false;
    });
    builder.addCase(addElementComment.rejected, (state) => {
      state.updating = false;
    });
    builder.addCase(updateRfpTraroBrick.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(updateRfpTraroBrick.fulfilled, (state, action) => {
      const indexToReplace = state.data.traro_element_response_list.findIndex(
        (element) => element.arch_element_specific === action.payload.arch_element_specific,
      );

      if (indexToReplace !== -1) {
        state.data.traro_element_response_list.splice(indexToReplace, 1, action.payload);
      } else {
        state.data.traro_element_response_list.push(action.payload);
      }
    });
  },
});

export default rfpResponseSlice.reducer;
