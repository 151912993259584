import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useTicket } from 'api';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import LoaderOverlay from 'components/LoaderOverlay/LoaderOverlay';
import Background from 'components/Background/Background';

import TicketForm from './components/TicketForm/TicketForm';
import TicketComments from './components/TicketComments/TicketComments';

import './TicketPage.scss';

export interface TicketPageProps {}

const TicketPage: FC<TicketPageProps> = () => {
  const { id } = useParams<{ id: string }>();

  if (id === undefined) {
    throw new Error();
  }

  const { data, isLoading } = useTicket(id);

  return (
    <div className="enkrateia-ticket-detail-page">
      <Header />
      <LoaderOverlay open={isLoading} />
      <main>
        {data && <TicketForm ticket={data} />}
        {data && <TicketComments ticket={data} />}
      </main>
      <Footer />
      <Background />
    </div>
  );
};

export default TicketPage;
