import TicketDto from 'types/api/TicketDto';

import API, { ApiResponse } from './api';
import FormSubmitError from '../errors/FormSubmitError';

interface SaveTicketResponse extends ApiResponse {
  ticket_list: [TicketDto];
}

export const saveTicket = async (ticket: Omit<TicketDto, 'assigned_user'> & { assigned_user: number }) => {
  try {
    if (ticket.traro_element_response_id === null) {
      delete ticket.traro_element_response_id;
    }

    return (await API.post<SaveTicketResponse>('/ticket-list/', { ticket_list: [ticket] })).data;
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};
