import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'store';
import { InputOption } from 'components/forms/raw/SelectInput';
import TraroElementResponse from 'types/api/TraroElementResponse';
import QuestionSection from 'types/api/QuestionSection';
import { getQuestionSectionForResponse } from 'store/rfpResponse/helpers';

const useQuestionNumberOptions = (rfpRelated?: boolean): InputOption<number | null>[] => {
  const { t } = useTranslation();
  const sections = useSelector((state) => state.rfp.data.kliparo_section_list);
  const responses = useSelector((state) => state.rfpResponse.data.traro_element_response_list);

  return useMemo(() => {
    if (!rfpRelated) {
      return [{ value: null, label: t('common.none') }];
    }

    const options = responses
      .map((response) => [response, getQuestionSectionForResponse(sections, response)])
      .filter(([, question]) => question !== null) as [TraroElementResponse, QuestionSection][];

    return options
      .reduce(
        (acc, [response, question]) => {
          acc.push({
            label: question.traro_element.element_name,
            value: response.id as number,
          });

          return acc;
        },
        [{ value: null, label: t('common.none') }] as InputOption<number | null>[],
      )
      .sort((a, b) => {
        if (typeof a.label === 'string' && typeof b.label === 'string') {
          return a.label.localeCompare(b.label);
        }

        return 0;
      });
  }, [t, sections, responses, rfpRelated]);
};

export default useQuestionNumberOptions;
