import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import TicketDto from 'types/api/TicketDto';
import AutoSaveWatcher, { AutoSaveIndicator } from 'components/AutoSaveWatcher';
import FormikSelectInput from 'components/forms/formik/FormikSelectInput/FormikSelectInput';
import FormikDatePickerInput from 'components/forms/formik/FormikDatePickerInput/FormikDatePickerInput';
import Markdown from 'components/Markdown/Markdown';
import TicketStatus from 'types/TicketStatus';
import useUser from 'hooks/useUser';
import useSeverityOptions from 'hooks/useSeverityOptions';
import useCategoryOptions from 'hooks/useCategoryOptions';
import useFormattedDate, { DEFAULT_DATE_FORMAT_WITH_WEEKDAY } from 'hooks/useFormattedDate';

import TicketStatusIndicator from './components/TicketStatusIndicator/TicketStatusIndicator';
import LinkedCriterionField from './components/LinkedCriterionField/LinkedCriterionField';
import LoaderPlaceholder from './components/LoaderPlaceholder/LoaderPlaceholder';
import TicketActions from './components/TicketActions/TicketActions';
import useConnectedQuestionOptions from './hooks/useConnectedQuestionOptions';
import useTicketAssignedUserOptions from './hooks/useTicketAssignedUserOptions';
import useInitialValues, { TicketFormState } from './hooks/useInitialValues';
import useTicketSubmit from './hooks/useTicketSubmit';
import useDataLoader from './hooks/useDataLoader';
import useSchema from './hooks/useSchema';
import useTicketTitle from './hooks/useTicketTitle';

import './TicketForm.scss';

export interface CommentFormProps {
  ticket: TicketDto;
}

const TicketForm: FC<CommentFormProps> = ({ ticket }) => {
  const { t } = useTranslation();
  const schema = useSchema();
  const [onSubmit, isSubmitting] = useTicketSubmit();
  const { isReviewer } = useUser();

  const { connectedRfpResponse, ticketConnectedElement, availableRFPs, loading } = useDataLoader(ticket);

  const selectableUserOptions = useTicketAssignedUserOptions(
    ticket,
    availableRFPs,
    connectedRfpResponse,
    ticketConnectedElement,
  );

  const selectableLinkedCriteria = useConnectedQuestionOptions(connectedRfpResponse);
  const selectableSeverityOptions = useSeverityOptions();
  const selectableCategoryOptions = useCategoryOptions();

  const disabled = isSubmitting || loading || ticket.ticket_status === TicketStatus.CLOSED;
  const dueDate = useFormattedDate(ticket.due_date, DEFAULT_DATE_FORMAT_WITH_WEEKDAY);

  const initialValues = useInitialValues(
    ticket,
    selectableLinkedCriteria,
    selectableSeverityOptions,
    selectableCategoryOptions,
  );
  const { title, className } = useTicketTitle(ticket);

  if (loading || (!!ticket.traro_element_response_id && !initialValues.linkedCriterion)) {
    return (
      <LoaderPlaceholder
        ticketTitle={title}
        headerClassName={className}
        hasLinkedCriterion={ticket.traro_element_response_id !== null}
      />
    );
  }

  return (
    <Formik<TicketFormState>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <AutoSaveWatcher>
        <Form className="enkrateia-ticket-form">
          <section className={className}>
            <div className="max-width">
              <h2>{title}</h2>
            </div>
          </section>
          <AutoSaveIndicator />
          <section className="form-elements">
            <label>{t('common.tickets.status')}:</label>
            <TicketStatusIndicator status={ticket.ticket_status} />
            <label>{t('common.tickets.assignedTo')}:</label>
            <FormikSelectInput variant="filled" name="assignedTo" options={selectableUserOptions} disabled={disabled} />
            <label>{t('common.tickets.dueDate')}:</label>
            {isReviewer ? (
              <FormikDatePickerInput
                noLabel
                variant="filled"
                name="dueDate"
                disabled={disabled || !isReviewer}
                format={DEFAULT_DATE_FORMAT_WITH_WEEKDAY}
              />
            ) : (
              <span className="description">{dueDate}</span>
            )}
            {isReviewer && (
              <>
                <label>{t('common.tickets.severity')}:</label>
                <FormikSelectInput
                  variant="filled"
                  name="severity"
                  options={selectableSeverityOptions}
                  disabled={disabled}
                />
              </>
            )}
            <label>{t('common.tickets.category')}:</label>
            {isReviewer ? (
              <FormikSelectInput
                variant="filled"
                name="category"
                options={selectableCategoryOptions}
                disabled={disabled}
              />
            ) : (
              <span className="description">
                {selectableCategoryOptions.find((option) => option.value === ticket.source)?.value}
              </span>
            )}
            {ticket.traro_element_response_id !== null && (
              <>
                <label>{t('common.tickets.linkedCriterion')}:</label>
                <LinkedCriterionField
                  ticket={ticket}
                  selectableLinkedCriteria={selectableLinkedCriteria}
                  disabled={disabled}
                />
              </>
            )}
            <label>{t('common.tickets.ticketDescription')}:</label>
            <Markdown linkTarget="_blank" className="description">
              {ticket.short_description}
            </Markdown>
          </section>
          <TicketActions
            selectableUsers={selectableUserOptions}
            ticket={ticket}
            disabled={disabled}
            isLoading={isSubmitting || loading}
          />
        </Form>
      </AutoSaveWatcher>
    </Formik>
  );
};

export default TicketForm;
