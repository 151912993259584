import { useMutation } from '@tanstack/react-query';

import API from 'services/api';
import FormSubmitError from 'errors/FormSubmitError';
import { AxiosError } from 'axios';

interface CreatePasswordParams<T = any> {
  password: string;
  confirmPassword: string;
  encodedEmail: string;
  token: string;
  meta: T;
}

interface CreatePasswordResponse {}

const createPassword = async <T = any>({ password, confirmPassword, encodedEmail, token }: CreatePasswordParams<T>) => {
  try {
    return (
      await API.post<CreatePasswordResponse>(`/create-password/${encodedEmail}/${token}`, {
        password,
        password_confirm: confirmPassword,
      })
    ).data;
  } catch (e) {
    let message = 'An error occurred while sending request to the server. Please try again.';

    if (e instanceof AxiosError && e.response?.data.error_message !== undefined) {
      message = e.response.data.error_message;
    }

    throw new FormSubmitError(message);
  }
};

interface UseCreatePasswordMutationProps<T> {
  onSuccess?: (data: CreatePasswordResponse, variables: CreatePasswordParams<T>) => Promise<void>;
  onError?: (error: Error, variables: CreatePasswordParams<T>) => Promise<void>;
}

const useCreatePasswordMutation = <T = any>({ onSuccess, onError }: UseCreatePasswordMutationProps<T>) =>
  useMutation<CreatePasswordResponse, FormSubmitError, CreatePasswordParams<T>>(createPassword, {
    onSuccess,
    onError,
  });

export default useCreatePasswordMutation;
