import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ScoringRubric from 'types/api/ScoringRubric';
import helpIcon from 'assets/img/helpIcon.svg';

import './Scoring.scss';
import ScoringRubricRow from './components/ScoringRubricRow/ScoringRubricRow';

export interface ScoringProps {
  score: string | number;
  maxScore: string | number;
  scoringRubric?: ScoringRubric[];
}

const Scoring: FC<ScoringProps> = ({ score, maxScore, scoringRubric }) => {
  const { t } = useTranslation();
  const [scoringRubricVisible, setScoringRubricVisible] = useState(false);

  const [scoringRubricItems, activeId] = useMemo(() => {
    if (!scoringRubric || scoringRubric.length === 0) {
      return [[], -1] as [ScoringRubric[], number];
    }

    const sortedItems = [...scoringRubric].sort((a, b) => a.low_score - b.low_score);
    const activeScoreId = sortedItems.filter((r) => r.low_score <= score && score <= r.high_score)[0]?.id ?? -1;

    return [sortedItems, activeScoreId];
  }, [scoringRubric, score]);

  return (
    <div className="enkrateia-scoring">
      <span className="score">
        {score}/{maxScore}
      </span>
      <button type="button" className="help" onClick={() => setScoringRubricVisible(!scoringRubricVisible)}>
        <img src={helpIcon} alt="Help" />
        {t('pages.projectOverview.sidePanel.scoringRubric')}
      </button>
      {scoringRubricVisible && (
        <span className="scoring-rubric">
          {scoringRubricItems?.map((scoringRubricRow) => (
            <ScoringRubricRow
              scoringRubric={scoringRubricRow}
              active={scoringRubricRow.id === activeId}
              key={scoringRubricRow.id}
            />
          ))}
        </span>
      )}
    </div>
  );
};

export default Scoring;
