class FormSubmitError<T = Error> extends Error {
  public static readonly TYPE = 'FormSubmitError';

  public readonly originalError: T | undefined;

  constructor(message: string, error?: T) {
    super();

    this.message = message;
    this.name = FormSubmitError.TYPE;
    this.originalError = error;
  }

  public static isInstance(e: unknown): boolean {
    return (e as Error).name === FormSubmitError.TYPE;
  }
}

export default FormSubmitError;
