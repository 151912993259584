import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { AttachmentIcon, TimesIcon } from 'components/SvgIcons';

import './Display.scss';

export interface DisplayProps {
  name: string;
  clearable?: boolean;
}

const Display = ({ name, clearable }: DisplayProps) => {
  const { getFieldMeta, getFieldHelpers } = useFormikContext();

  const { value, error, touched } = getFieldMeta<File | null>(name);
  const { setValue } = getFieldHelpers<File | null>(name);

  const shortName = useMemo(() => {
    if (!value) {
      return null;
    }

    if (value.name.length <= 32) {
      return value.name;
    }

    const firstPart = value.name.substring(0, 18);
    const lastPart = value.name.substring(value.name.length - 10, value.name.length);

    return `${firstPart}...${lastPart}`;
  }, [value]);

  const hasError = Boolean(error && error !== '' && touched);

  return (
    <div className="enkrateia-upload-attachment-display">
      {value && (
        <div className="attachment-entry">
          <AttachmentIcon size={24} color="#343892" />
          <a target="_blank" rel="noreferrer" href={URL.createObjectURL(value)}>
            {shortName}
          </a>
          {clearable && (
            <button onClick={() => setValue(null)}>
              <TimesIcon size={14} color="black" />
            </button>
          )}
        </div>
      )}
      {hasError && <span className="error-label">{error}</span>}
    </div>
  );
};

export default Display;
