import React, { FC, useMemo } from 'react';
import FormikSelectInput from 'components/forms/formik/FormikSelectInput/FormikSelectInput';

export interface SelectionQuestionProps {
  disabled?: boolean;
  questionValues: string;
  name: string;
}

const SelectionQuestion: FC<SelectionQuestionProps> = ({ disabled, questionValues, name }) => {
  const options = useMemo(() => questionValues.split(';').map((value) => ({ value, label: value })), [questionValues]);

  return <FormikSelectInput name={name} variant="filled" disabled={disabled} options={options} />;
};

export default SelectionQuestion;
