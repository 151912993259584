import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InputOption } from 'components/forms/raw/SelectInput';
import UserDto from 'types/api/UserDto';
import TicketDto from 'types/api/TicketDto';

import { userToInput } from '../../../../../../../hooks/useTicketAssignedUserOptions';

export interface ChangeTicketStatusForm {
  comment: string;
  assignedTo: InputOption<number, UserDto>;
}

const useInitialValues = (
  ticket: TicketDto,
  selectableUsers: InputOption<number, UserDto>[],
): ChangeTicketStatusForm => {
  const { t } = useTranslation();

  return useMemo(() => {
    return {
      comment: t(`pages.ticketDetails.requestTicketClose.comment`),
      assignedTo:
        selectableUsers.find((user) => user.meta.user_title === 'Support Team') ?? userToInput(ticket.assigned_user),
    };
  }, [t, ticket, selectableUsers]);
};

export default useInitialValues;
