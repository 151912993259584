import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'store';
import { closeModal } from 'store/modals';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import { DateTime } from 'luxon';

import './RegistrationSuccessModal.scss';
import { useNavigate } from 'react-router-dom';

export interface RegistrationSuccessModalProps {}

const RegistrationSuccessModal: FC<RegistrationSuccessModalProps> = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isOpen = useSelector((store) => store.modals.registrationSuccessModal?.open);
  const { email, name, organizationName, country } = useSelector(
    (store) => store.modals.registrationSuccessModal?.data ?? {},
  );

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal('registrationSuccessModal'));
    navigate('/login');
  }, [dispatch]);

  return (
    <Modal
      isOpen={isOpen}
      variant="kliparo"
      title={t('pages.register.submittedModal.title')}
      className="enkrateia-registration-success-modal"
    >
      <div className="details">
        <dl>
          <dt>{t('pages.register.submittedModal.projectName')}</dt>
          <dd>{organizationName ?? '-'}</dd>

          <dt>{t('pages.register.submittedModal.country')}</dt>
          <dd>{country?.label ?? '-'}</dd>

          <dt>{t('pages.register.submittedModal.emailAddress')}</dt>
          <dd>{email}</dd>

          <dt>{t('pages.register.submittedModal.contactName')}</dt>
          <dd>{name}</dd>

          <dt>{t('pages.projectOverview.submittedModal.date')}</dt>
          <dd>{DateTime.now().toFormat('M/d/y')}</dd>
        </dl>
      </div>
      <div className="summary">
        <span>{t('pages.register.submittedModal.summary')}</span>
        <Button variant="primary" onClick={handleCloseModal}>
          {t('common.ok')}
        </Button>
      </div>
    </Modal>
  );
};

export default RegistrationSuccessModal;
