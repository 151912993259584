import { useMemo } from 'react';

export interface TicketCommentFormState {
  ticketId: number;
  ticketComment: string;
  published: boolean;
  attachment: File | null;
}

const useInitialValues = (ticketId: number): TicketCommentFormState =>
  useMemo(
    () => ({
      ticketId,
      ticketComment: '',
      published: false,
      attachment: null,
    }),
    [ticketId],
  );

export default useInitialValues;
