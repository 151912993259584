import { DateTime } from 'luxon';

const useDaysBefore = (date: string | null): string => {
  const parsedDate = DateTime.fromISO(date ?? '');

  if (!parsedDate.isValid) {
    return '-';
  }

  return parsedDate.diffNow('days').toFormat('d');
};

export default useDaysBefore;
