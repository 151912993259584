import TraroElementResponse from 'types/api/TraroElementResponse';

import useUser from 'hooks/useUser';
import { useSelector } from 'store';
import { canUpdateResponseStatus } from 'services/permissions';

const useIsUpdateResponseStatusActionVisible = (rfpElementResponse: TraroElementResponse) => {
  const user = useUser();
  const rfpResponse = useSelector((state) => state.rfpResponse.data);

  return canUpdateResponseStatus(user, rfpResponse, rfpElementResponse);
};

export default useIsUpdateResponseStatusActionVisible;
