import { useQuery } from '@tanstack/react-query';

import FormSubmitError from 'errors/FormSubmitError';
import TicketConnectionDetailsDto from 'types/api/TicketConnectionDetailsDto';

import { fetchTicketsConnectedElements } from './useTickets';

export const fetchTicketConnectedElement = async (
  ticketId: number | string,
  hasConnectedElements?: boolean,
): Promise<TicketConnectionDetailsDto | null> => {
  try {
    if (!hasConnectedElements) {
      return null;
    }

    const ticketNumberId = Number(ticketId);
    return (await fetchTicketsConnectedElements([ticketNumberId]))[ticketNumberId];
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

const getQueryKey = (id?: number | string, hasConnectedElements?: boolean) => {
  const key: unknown[] = ['ticketConnectedElement'];

  if (id !== undefined) {
    key.push(String(id));
  }

  if (hasConnectedElements !== undefined) {
    key.push(hasConnectedElements);
  }

  return key;
};

const useTicketConnectedElement = (id: number | string, hasConnectedElements?: boolean) =>
  useQuery(getQueryKey(id, hasConnectedElements), () => fetchTicketConnectedElement(id, hasConnectedElements));

useTicketConnectedElement.getQueryKey = getQueryKey;
useTicketConnectedElement.queries = {
  fetchTicketConnectedElement,
} as const;

export default useTicketConnectedElement;
