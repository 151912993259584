import { useCallback } from 'react';
import { FormikHelpers } from 'formik';

import { submitRfpResponse } from 'store/rfpResponse';
import { useDispatch } from 'store';

import { SubmitAssessmentFormValues } from './useInitialValues';

const useOnSubmit = (
  setOpen: (isOpen: boolean) => void,
  setIsSuccessOpen: (isOpen: boolean) => void,
  onSubmitComplete?: () => void,
) => {
  const dispatch = useDispatch();

  return useCallback(
    async (unused: SubmitAssessmentFormValues, helpers: FormikHelpers<SubmitAssessmentFormValues>) => {
      try {
        await dispatch(submitRfpResponse()).unwrap();

        onSubmitComplete?.();
        setIsSuccessOpen(true);
        setOpen(false);
      } catch (e) {
        if ((e as Error).message) {
          helpers.setFieldError('iHaveReadTerms', (e as Error).message);
        }
      }
    },
    [dispatch, setOpen, onSubmitComplete],
  );
};

export default useOnSubmit;
