import FormSubmitError from 'errors/FormSubmitError';
import { useCallback } from 'react';
import API from 'services/api';

export const downloadResponsePdfExport = async (responseId: number | undefined) => {
  try {
    if (responseId === undefined) {
      throw new Error('Response ID is not defined');
    }

    window.open(
      API.getUri({
        url: '/rfp_response/export-rfp-response-to-pdf/' + responseId,
      }),
      '_blank',
    );
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

const getQueryKey = (id?: number | undefined) => {
  const keys: unknown[] = ['useDownloadResponsePdfExport'];

  if (id !== undefined) {
    keys.push(id);
  }

  return keys;
};

const useDownloadResponsePdfExport = (id: number | undefined) => useCallback(() => downloadResponsePdfExport(id), [id]);

useDownloadResponsePdfExport.getQueryKey = getQueryKey;
useDownloadResponsePdfExport.queries = {
  downloadResponsePdfExport,
} as const;

export default useDownloadResponsePdfExport;
