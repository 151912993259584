/* eslint-disable @typescript-eslint/naming-convention */

import { DateTime } from 'luxon';
import { useMemo } from 'react';

export const DEFAULT_DATE_FORMAT = 'dd-MMM-yyyy';
export const DEFAULT_DATE_TIME_FORMAT = 'dd-MMM-yyyy hh:mma';
export const DEFAULT_DATE_FORMAT_WITH_WEEKDAY = `EEEE, ${DEFAULT_DATE_FORMAT}`;

export const formatDate = (date: string, format: string = DEFAULT_DATE_FORMAT): string => {
  const parsedDate = DateTime.fromISO(date);

  if (!parsedDate.isValid) {
    return '-';
  }

  return parsedDate.toFormat(format);
};

const useFormattedDate = (date: string, format: string = DEFAULT_DATE_FORMAT): string =>
  useMemo(() => formatDate(date, format), [date, format]);

export default useFormattedDate;
