import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import RFPResponseStatus from 'types/RFPResponseStatus';

import './RFPResponseStatusIndicator.scss';

export interface RFPResponseStatusIndicatorProps {
  status: RFPResponseStatus;
}

const RFPResponseStatusIndicator: FC<RFPResponseStatusIndicatorProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <div className="enkrateia-rfp-response-status-indicator">
      <span className={status === RFPResponseStatus.OPEN ? 'active' : ''}>{t('common.rfpStatus.Open')}</span>
      <span className="divider">»</span>
      <span className={status === RFPResponseStatus.IN_REVIEW ? 'active' : ''}>{t('common.rfpStatus.In Review')}</span>
      <span className="divider">»</span>
      <span className={status === RFPResponseStatus.FINAL ? 'active' : ''}>{t('common.rfpStatus.Final')}</span>
    </div>
  );
};

export default RFPResponseStatusIndicator;
