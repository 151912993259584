import { useQuery } from '@tanstack/react-query';
import { isArray, sortBy } from 'lodash';

import API from 'services/api';
import FormSubmitError from 'errors/FormSubmitError';
import DashboardMessage from 'types/api/DashboardMessage';

export const fetchDashboardMessages = async (): Promise<DashboardMessage[]> => {
  try {
    const messages = (await API.get<DashboardMessage[]>('/dashboard-messages/')).data;

    if (!isArray(messages)) {
      throw new Error('Invalid response from server');
    }

    return sortBy(messages, 'priority');
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

const getQueryKey = () => ['notification/dashboardMessages'];

const useDashboardMessages = () => useQuery(getQueryKey(), () => fetchDashboardMessages(), { retry: 1 });

useDashboardMessages.getQueryKey = getQueryKey;
useDashboardMessages.queries = {
  fetchDashboardMessages,
} as const;

export default useDashboardMessages;
