import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { useQueryClient } from '@tanstack/react-query';

import { useDispatch } from 'store';
import { login } from 'store/auth';
import FormSubmitError from 'errors/FormSubmitError';

import { LoginFormData } from './useInitialValues';

const useLoginSubmit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useCallback(
    async (values: LoginFormData, helpers: FormikHelpers<LoginFormData>) => {
      try {
        await dispatch(login(values)).unwrap();

        queryClient.clear();

        navigate('/dashboard');
      } catch (e) {
        if (FormSubmitError.isInstance(e)) {
          helpers.setFieldError('password', (e as FormSubmitError).message);
        } else {
          console.error(e);
        }
      }
    },
    [navigate, dispatch, queryClient],
  );
};

export default useLoginSubmit;
