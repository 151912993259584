import React, { forwardRef } from 'react';

import useFormikConnection, { TouchTrigger, WithoutInjectedProps } from '../hooks/useFormikConnection';
import TextAreaInput, { TextAreaInputProps } from '../../raw/TextAreaInput';

export interface FormikTextInputProps extends WithoutInjectedProps<TextAreaInputProps, 'onChange'> {
  name: string;
  touchedTrigger?: TouchTrigger;
}

const FormikTextAreaInput = forwardRef<HTMLTextAreaElement, FormikTextInputProps>(({ name, ...props }, ref) => {
  const { setValue, ...other } = useFormikConnection<string, HTMLTextAreaElement>(name, props);

  return <TextAreaInput {...props} {...other} ref={ref} label={props.label} onChange={setValue} />;
});

export default FormikTextAreaInput;
