/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import { saveTicket } from 'services/ticket';
import TicketStatus from 'types/TicketStatus';
import TicketSeverityType from 'types/TicketSeverityType';
import TicketSourceType from 'types/TicketSourceType';

import {
  useAddTicketCommentMutation,
  useRecentTickets,
  useRfp,
  useRfpElementTickets,
  useRfpResponse,
  useRfpResponsesStats,
  useSingleRfpResponseStats,
  useTicketConnectedElement,
  useTickets,
  useUploadAttachmentMutation,
} from 'api';

import { TicketCreationForm } from './useInitialValues';

interface TicketCreationProps {
  values: TicketCreationForm;
  helpers: FormikHelpers<TicketCreationForm>;
}

const useTicketSubmit = () => {
  const [savedTicketId, setSavedTicketId] = useState(0);
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isSuccess } = useMutation(
    async ({ values }: TicketCreationProps) => {
      let attachmentId;
      if (values.attachment) {
        const { id } = await useUploadAttachmentMutation.queries.uploadAttachment(values.attachment);
        attachmentId = id;
      }

      const ticket = await saveTicket({
        assigned_user: values.assignedTo.value,
        short_description: values.ticketTitle,
        due_date: values.dueDate.toISO(),
        // @ts-ignore
        organization: {
          id: values.organization.value,
        },
        severity_rating: values.severity?.value ?? TicketSeverityType.NOT_ASSIGNED,
        ticket_status: TicketStatus.OPEN,
        source: values.category?.value ?? TicketSourceType.SOURCE_QUESTION_TYPE,
        // questionNumber.value is the ID of TraroElementResponse
        traro_element_response_id: values.questionNumber?.value ?? null,
      });

      const comment = await useAddTicketCommentMutation.queries.addTicketComment({
        ticketId: ticket.ticket_list[0].id,
        ticketComment: t('common.tickets.ticketCreatedComment'),
        attachmentId,
        meta: null,
        published: true,
      });

      return {
        ...ticket,
        ticket_list: [
          {
            ...ticket.ticket_list[0],
            ticket_comment_list: [comment],
          },
        ],
      };
    },
    {
      onSuccess: async (data) => {
        setSavedTicketId(data.ticket_list[0].id);

        for (const value of [
          useTicketConnectedElement.getQueryKey(),
          useRfpElementTickets.getQueryKey(),
          useRecentTickets.getQueryKey(),
          useTickets.getQueryKey(),
          useRfpResponsesStats.getQueryKey(),
          useSingleRfpResponseStats.getQueryKey(),
          useRfp.getQueryKey(),
          useRfpResponse.getQueryKey(),
        ]) {
          await queryClient.invalidateQueries({ queryKey: value });
          await queryClient.refetchQueries({ queryKey: value });
        }
      },
      onError: async (error: Error, variables) => {
        await variables.helpers.setFieldTouched('attachment');
        await variables.helpers.setFieldError('attachment', error.message);
      },
    },
  );
  const saveForm = useCallback(
    (values: TicketCreationForm, helpers: FormikHelpers<TicketCreationForm>) => mutateAsync({ values, helpers }),
    [mutateAsync],
  );

  return [saveForm, isLoading, isSuccess, savedTicketId] as const;
};

export default useTicketSubmit;
