import React, { FC, useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useAvailableRFPs } from 'api';

import FormikSelectInput from 'components/forms/formik/FormikSelectInput/FormikSelectInput';
import FormikTextInput from 'components/forms/formik/FormikTextInput/FormikTextInput';
import Button from 'components/Button/Button';

import { NewAssessmentForm } from '../../hooks/useInitialValues';
import CommonTransComponents from 'i18n/CommonTransComponents';

export interface DetailsFormStepProps {
  isSaving: boolean;
}

const DetailsFormStep: FC<DetailsFormStepProps> = ({ isSaving }) => {
  const { t } = useTranslation();
  const { data: rfps, isLoading: areRfpsLoading } = useAvailableRFPs();

  const { values, setFieldValue, isValid, setFieldTouched } = useFormikContext<NewAssessmentForm>();

  useEffect(() => {
    setFieldValue('terms', values.rfp?.meta.rfp_terms);
  }, [values.rfp, setFieldValue]);

  const onGoToNextPage = useCallback(() => {
    if (isValid) {
      setFieldValue('page', 'disclaimer');
    } else {
      setFieldTouched('rfp');
      setFieldTouched('programName');
    }
  }, [isValid, setFieldValue, setFieldTouched]);

  return (
    <div>
      <div className="form-elements">
        <label>{t('pages.dashboard.createAssessment.organization')}*</label>
        <FormikSelectInput required fullWidth name="organization" options={[]} disabled variant="filled" />
        <label>{t('pages.dashboard.createAssessment.assessmentType')}*</label>
        <FormikSelectInput
          required
          fullWidth
          disabled={isSaving || areRfpsLoading}
          name="rfp"
          options={rfps}
          variant="filled"
        />
        <label>{t('pages.dashboard.createAssessment.programTitle')}*</label>
        <FormikTextInput disabled={isSaving} noLabel required fullWidth name="programName" variant="filled" />
      </div>
      <div className="submit-container">
        <Button onClick={onGoToNextPage} variant="primary" disabled={isSaving}>
          {t('pages.dashboard.createAssessment.submit')}
        </Button>
      </div>
      <p className="info">
        <Trans
          i18nKey="pages.dashboard.createAssessment.info"
          components={CommonTransComponents}
          url={t('common.footer.links.contact.url')}
        >
          For questions Contact us.
        </Trans>
      </p>
    </div>
  );
};

export default DetailsFormStep;
