import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';
import QuestionStatus from 'types/QuestionStatus';

const useSchema = () => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      yup.object().shape({
        status: yup
          .string()
          .oneOf(
            [
              QuestionStatus.REVIEW_COMPLETE,
              QuestionStatus.UPDATE_REQUIRED,
              QuestionStatus.NOT_REQUIRED,
              QuestionStatus.REVIEW_ONLY,
            ],
            t('errors.pleaseSelectQuestionStatus'),
          )
          .required(t('errors.pleaseSelectQuestionStatus')),
        programEligibility: yup.object().nullable(),
      }),
    [t],
  );
};

export default useSchema;
