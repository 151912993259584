import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import QuestionStatus from 'types/QuestionStatus';
import Modal from 'components/Modal/Modal';
import { HelpIcon } from 'components/SvgIcons';
import Tooltip from 'components/Tooltip/Tooltip';

import './ResponseStatusHelp.scss';
import ResponseStatusHelpRow from './components/ResponseStatusHelpRow/ResponseStatusHelpRow';

const ResponseStatusHelp = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Tooltip tooltip={t('common.clickForDetails')}>
        <button onClick={() => setOpen(true)}>
          <HelpIcon size={24} color="#2D2C64" />
        </button>
      </Tooltip>
      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        variant="kliparo"
        title={t('responseStatusHelp.title')}
      >
        <div className="enkrateia-response-status-help">
          <ResponseStatusHelpRow status={QuestionStatus.BEGIN} />
          <ResponseStatusHelpRow status={QuestionStatus.IN_PROGRESS} />
          <ResponseStatusHelpRow status={QuestionStatus.DONE} />
          <ResponseStatusHelpRow status={QuestionStatus.REVIEW_ONLY} />
          <ResponseStatusHelpRow status={QuestionStatus.REVIEW_COMPLETE} />
          <ResponseStatusHelpRow status={QuestionStatus.UPDATE_REQUIRED} />
          <ResponseStatusHelpRow status={QuestionStatus.NOT_REQUIRED} />
        </div>
      </Modal>
    </div>
  );
};

export default ResponseStatusHelp;
