import React, { FC, useCallback, useMemo } from 'react';
import { OnChangeValue } from 'react-select';
import { Column } from '@tanstack/react-table';

import SelectInput, { InputOption } from 'components/forms/raw/SelectInput';
import TicketWithConnection from 'types/TicketWithConnection';
import TicketSeverityType from 'types/TicketSeverityType';

import './TicketSeverityRatingFilter.scss';

export interface TicketStatusFilterProps {
  column: Column<TicketWithConnection, TicketSeverityType>;
}

const TicketSeverityRatingFilter: FC<TicketStatusFilterProps> = ({ column }) => {
  const columnFilterValue = (column.getFilterValue() as TicketSeverityType | null) ?? null;

  const options: InputOption<TicketSeverityType>[] = useMemo(
    () => [
      { value: TicketSeverityType.HIGH, label: TicketSeverityType.HIGH },
      { value: TicketSeverityType.MEDIUM, label: TicketSeverityType.MEDIUM },
      { value: TicketSeverityType.LOW, label: TicketSeverityType.LOW },
      { value: TicketSeverityType.NOT_ASSIGNED, label: TicketSeverityType.NOT_ASSIGNED },
    ],
    [],
  );

  const onChange = useCallback(
    (newValue: OnChangeValue<InputOption<TicketSeverityType>, false>) => {
      column.setFilterValue(newValue?.value ?? null);
    },
    [column],
  );

  return (
    <SelectInput
      value={options.find((o) => o.value === columnFilterValue) ?? null}
      onChange={onChange as any}
      options={options}
      variant="filled"
      className="enkrateia-table-filter enkrateia-ticket-severity-rating-filter"
      isClearable
    />
  );
};

export default TicketSeverityRatingFilter;
