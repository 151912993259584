import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import './Loader.scss';

export interface LoaderProps {
  className?: string;
}

const Loader: FC<LoaderProps> = ({ className = '' }) => {
  const { t } = useTranslation();

  return (
    <div className={`enkrateia-loader ${className}`}>
      <div className="enkrateia-loader-inner">{t('common.loading')}</div>
    </div>
  );
};

export default Loader;
