import React from 'react';
import { flexRender, Row as TRow } from '@tanstack/react-table';
import { Link } from 'react-router-dom';

export interface RowProps<TData> {
  row: TRow<TData>;
  onRowClick?: (row: TRow<TData>) => void;
  getRowLink?: (row: TRow<TData>) => string | false;
  getRowClassName?: (row: TRow<TData>) => string;
}

const Row = <TData,>({ row, onRowClick, getRowLink, getRowClassName }: RowProps<TData>) => {
  const link = getRowLink ? getRowLink(row) : false;

  return (
    <tr key={row.id} onClick={() => onRowClick?.(row)} className={getRowClassName?.(row)}>
      {row.getVisibleCells().map((cell) => (
        <td key={cell.id} width={cell.column.getSize()}>
          {link ? (
            <Link className="td-inner-wrapper" to={link}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Link>
          ) : (
            <div className="td-inner-wrapper">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
          )}
        </td>
      ))}
    </tr>
  );
};

export default Row;
