import React, { FC, ReactNode, useState } from 'react';

import doubleChevronRight from 'assets/img/doubleChevronRight.svg';

import BrickPanel from './components/BrickPanel/BrickPanel';
import useBricks from './hooks/useBricks';

import './ArchBricksSidePanel.scss';

export interface ArchBricksSidePanelProps {
  title: string;
  interactive?: boolean;
  topBanner?: ReactNode;
}

const ArchBricksSidePanel: FC<ArchBricksSidePanelProps> = ({ title, interactive, topBanner }) => {
  const bricks = useBricks();
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className={`enkrateia-arch-bricks-side-panel ${open ? 'open' : ''} ${interactive ? 'interactive' : ''}`}>
        <button type="button" className="title" onClick={() => setOpen(!open)}>
          <h2>{title}</h2>
          <img src={doubleChevronRight} alt="open-close" />
        </button>
        {topBanner}
        <div className="content">
          {bricks.map((brick) => (
            <BrickPanel key={brick.arch_element_specific} brick={brick} interactive={interactive} />
          ))}
        </div>
      </div>
      {interactive && <div className={`enkrateia-arch-bricks-side-panel-interactive-backdrop ${open ? 'open' : ''}`} />}
    </>
  );
};

export default ArchBricksSidePanel;
