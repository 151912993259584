import React, { useCallback } from 'react';

import useFormikConnection, { TouchTrigger, WithoutInjectedProps } from '../hooks/useFormikConnection';
import RichTextAreaInput, { RichTextAreaInputProps } from '../../raw/RichTextAreaInput';

export interface FormikTextInputProps extends WithoutInjectedProps<RichTextAreaInputProps, 'onChange'> {
  name: string;
  touchedTrigger?: TouchTrigger;
}

const FormikRichTextAreaInput = ({ name, ...props }: FormikTextInputProps) => {
  // @ts-ignore
  const { setValue, ...other } = useFormikConnection<string, HTMLInputElement>(name, props);
  const handleChange = useCallback((value: string) => setValue(value), [setValue]);

  // @ts-ignore
  return <RichTextAreaInput {...props} {...other} label={props.label} onChange={handleChange} />;
};

export default FormikRichTextAreaInput;
