import Cookies from 'universal-cookie';
import { useSelector } from 'store';
import { useCallback, useEffect, useMemo, useState } from 'react';

const cookies = new Cookies();

/**
 * Returns a hash code from a string
 * @param  {String} string The string to hash.
 * @return {Number}    A 32bit integer
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
const hashCode = (string: string): string => {
  let hash = 0;

  for (let i = 0, len = string.length; i < len; i++) {
    let chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash.toString(16);
};

const useCookieBanner = () => {
  const isLoggedIn = useSelector((store) => store.auth.isLoggedIn);
  const email = useSelector((store) => store.auth.user.email);
  const [isVisible, setIsVisible] = useState(false);

  /**
   * Calculate cookie key for logged-in user
   */
  const cookieKey = useMemo(() => {
    return 'cookie-consent-' + hashCode(email);
  }, [email]);

  /**
   * Accept cookies - set it and change state to false
   */
  const onAccept = useCallback(() => {
    cookies.set(cookieKey, true);
    setIsVisible(false);
  }, [cookieKey]);

  useEffect(() => {
    if (isLoggedIn && cookies.get(cookieKey, { doNotParse: false }) !== true) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [isLoggedIn, cookieKey]);

  return {
    isVisible,
    onAccept,
  } as const;
};

export default useCookieBanner;
