import React, { FC } from 'react';
import ArchElementSpecific from 'types/ArchElementSpecific';
import ArchElementGeneric from 'types/ArchElementGeneric';
import { isCompleted } from 'store/rfpResponse/helpers';

import { useArchContextForElement } from '../../../../context/ArchContext';
import useElementSelection from '../../../../hooks/useElementSelection';

type VoussoirId =
  | ArchElementSpecific.VOUSSOIR_LEFT_1
  | ArchElementSpecific.VOUSSOIR_LEFT_2
  | ArchElementSpecific.VOUSSOIR_RIGHT_1
  | ArchElementSpecific.VOUSSOIR_RIGHT_2;

export interface VoussoirProps {
  x: number | string;
  y: number | string;
  voussoir: VoussoirId;
}

const paths: Record<VoussoirId, string> = {
  [ArchElementSpecific.VOUSSOIR_LEFT_1]:
    'M66.3128 4.77588L131.424 72.0796C134.792 75.5619 135.18 80.9585 132.344 84.8867L104.448 123.519C101.595 127.47 96.3101 128.789 91.9354 126.642L8.03646 85.4681C2.4774 82.74 0.70893 75.6667 4.32998 70.6433L51.0135 5.88132C54.656 0.828321 61.9818 0.298999 66.3128 4.77588Z',
  [ArchElementSpecific.VOUSSOIR_LEFT_2]:
    'M92.7266 9.05498L120.379 98.5232C121.809 103.152 119.716 108.141 115.41 110.363L73.0633 132.212C68.7326 134.446 63.4218 133.233 60.4901 129.341L4.26682 54.6865C0.541518 49.74 2.16169 42.6312 7.66248 39.7874L78.5802 3.12474C84.1135 0.264163 90.8873 3.10376 92.7266 9.05498Z',
  [ArchElementSpecific.VOUSSOIR_RIGHT_1]:
    'M70.2819 4.77588L5.17121 72.0796C1.8023 75.5619 1.41456 80.9585 4.25105 84.8867L32.1464 123.519C34.9993 127.47 40.2846 128.789 44.6594 126.642L128.558 85.4681C134.117 82.74 135.886 75.6667 132.265 70.6433L85.5812 5.88132C81.9388 0.828321 74.6129 0.298999 70.2819 4.77588Z',
  [ArchElementSpecific.VOUSSOIR_RIGHT_2]:
    'M29.8681 9.05498L2.21603 98.5232C0.785278 103.152 2.87902 108.141 7.18494 110.363L49.5314 132.212C53.8621 134.446 59.1729 133.233 62.1046 129.341L118.328 54.6865C122.053 49.74 120.433 42.6312 114.932 39.7874L44.0145 3.12474C38.4813 0.264163 31.7075 3.10376 29.8681 9.05498Z',
};

const Voussoir: FC<VoussoirProps> = ({ x, y, voussoir }) => {
  const { response } = useArchContextForElement(voussoir);
  const completed = isCompleted(response);
  const [isSelected, select] = useElementSelection(voussoir);

  return (
    <svg x={x} y={y}>
      <path
        className={`enkrateia-arch-voussoir ${ArchElementGeneric.VOUSSOIR} ${voussoir}`}
        d={paths[voussoir]}
        stroke="#9A8384"
        fill={completed ? '#9A8384' : 'transparent'}
        strokeWidth="3"
        opacity={isSelected ? 1 : 0.3}
        onClick={select}
        cursor="pointer"
      />
    </svg>
  );
};

export default Voussoir;
