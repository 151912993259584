import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'store';
import { closeModal } from 'store/modals';
import { useSingleRfpResponseStats } from 'api';
import SubmitAssessmentAction from 'components/SubmitAssessmentAction/SubmitAssessmentAction';
import Loader from 'components/Loader/Loader';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import QuestionStatus from 'types/QuestionStatus';
import { canSubmitAnswers } from 'services/permissions';
import useUser from 'hooks/useUser';

import './KliparoElementSubmittedModal.scss';

export interface TraroElementSubmittedModalProps {
  questionStatus: QuestionStatus;
}

const KliparoElementSubmittedModal: FC<TraroElementSubmittedModalProps> = ({ questionStatus }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const user = useUser();

  const isOpen = useSelector((store) => store.modals.traroElementSubmitModal.open);
  const rfpId = useSelector((store) => store.rfp.data.id);
  const rfpResponse = useSelector((store) => store.rfpResponse.data);
  const rfpResponseId = rfpResponse.id;

  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    data: stats = { percent_complete: 0, open_ticket_count: 0 },
    isFetching,
    refetch,
  } = useSingleRfpResponseStats(rfpResponseId);
  const isComplete = stats.percent_complete >= 100;

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal('traroElementSubmitModal'));
  }, [dispatch]);

  const handleGoToOverview = useCallback(() => {
    dispatch(closeModal('traroElementSubmitModal'));
    navigate(`/rfp/${rfpId}/rfp-response/${rfpResponseId}`);
  }, [dispatch, navigate, rfpId, rfpResponseId]);

  useEffect(() => {
    if (!canSubmitAnswers(user, rfpResponse)) {
      refetch();
    }
  }, [refetch, questionStatus, rfpResponse, user]);

  return (
    <Modal
      isOpen={isOpen}
      variant="kliparo"
      className="enkrateia-kliparo-element-submitted-modal"
      title={t('pages.traroElement.modalTitle.criterionSet', {
        status: t(`common.kliparoStatusMap.${questionStatus}`),
      })}
    >
      <div className="modal-content">
        <Button variant="primary" onClick={handleCloseModal}>
          {t('common.ok')}
        </Button>
        <Button variant="light-grey-filled" onClick={handleGoToOverview}>
          {t('pages.traroElement.goToOverview')}
        </Button>
      </div>
      {isComplete && !isFetching && (
        <div className="assessment-complete-info">
          <p>{t('pages.traroElement.modalTitle.assessmentComplete')}</p>
          <SubmitAssessmentAction isComplete isSubmitVisible onSubmitComplete={handleGoToOverview} />
        </div>
      )}
      {isFetching && <Loader />}
    </Modal>
  );
};

export default KliparoElementSubmittedModal;
