import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import arrowLeft from 'assets/img/arrowLeft.svg';

import './GoBackPanel.scss';

export interface GoBackPanelProps {
  target: string;
  label: string;
}

const GoBackPanel: FC<GoBackPanelProps> = ({ target, label }) => {
  return (
    <div className="enkrateia-go-back-panel">
      <Link to={target}>
        <img src={arrowLeft} alt="goBack" />
        {label}
      </Link>
    </div>
  );
};

export default GoBackPanel;
