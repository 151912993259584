import { FormikHelpers, useFormikContext } from 'formik';
import { useCallback, useState } from 'react';

import { useAddTicketCommentMutation } from 'api';

import { TicketFormState } from '../../../../../../../hooks/useInitialValues';

import { ChangeTicketStatusForm } from './useInitialValues';

const useSubmitChangeTicketStatusForm = (onRequestClose: () => void, ticketId: number) => {
  const { setFieldValue, submitForm } = useFormikContext<TicketFormState>();
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync } = useAddTicketCommentMutation<FormikHelpers<ChangeTicketStatusForm>>({
    onSuccess: async (data, variables) => {
      variables.meta.resetForm();
    },
  });

  const onSubmit = useCallback(
    async (values: ChangeTicketStatusForm, helpers: FormikHelpers<ChangeTicketStatusForm>) => {
      setFieldValue('assignedTo', values.assignedTo);

      try {
        setIsLoading(true);
        await submitForm();
        await mutateAsync({ ticketId, ticketComment: values.comment, published: true, meta: helpers });
      } finally {
        setIsLoading(false);
        onRequestClose();
      }
    },
    [setFieldValue, submitForm, onRequestClose, ticketId, mutateAsync],
  );

  return [onSubmit, isLoading] as const;
};

export default useSubmitChangeTicketStatusForm;
