import { useMemo } from 'react';
import { useSelector } from 'store';
import KliparoSection from 'types/api/KliparoSection';
import QuestionSection from 'types/api/QuestionSection';
import QuestionStatus from 'types/QuestionStatus';
import { getSectionStatus } from 'services/kliparoStatus/kliparoStatus';
import ScoringRubric from 'types/api/ScoringRubric';
import QuestionSectionWithResponse from 'types/QuestionSectionWithResponse';
import { canSeeCriterionReviewRating } from 'services/permissions';
import User from 'types/User';
import useUser from 'hooks/useUser';

export interface KliparoSectionWithResponse extends Omit<KliparoSection, 'question_section_list'> {
  questions: QuestionSectionWithResponse[];
  sectionStatus: QuestionStatus;
  sectionRating: number | null;
  sectionScoringRubric: ScoringRubric[] | null;
}

type ReduceSectionScoreAcc = [number | null, ScoringRubric[] | null];

const getSectionScore = (user: User, questionsWithResponses: QuestionSectionWithResponse[]) =>
  questionsWithResponses.reduce(
    (acc: ReduceSectionScoreAcc, question: QuestionSectionWithResponse): ReduceSectionScoreAcc => {
      const response = question.rfpElementResponse!;

      // Ignore Not Required
      if (response.question_status === QuestionStatus.NOT_REQUIRED) {
        return acc;
      }

      // Not scored, omit
      if (response.question_score === null) {
        return acc;
      }

      // Cannot see rating - omit
      if (!canSeeCriterionReviewRating(user, response)) {
        return acc;
      }

      // We have the lowest score, and it's lower than current question
      if (acc[0] !== null && acc[0] < response.question_score) {
        return acc;
      }

      return [response.question_score, question.traro_element.scoring_rubric];
    },
    [null, null] as ReduceSectionScoreAcc,
  );

const useKliparoSectionsWithResponses = (): KliparoSectionWithResponse[] => {
  const kliparoSections = useSelector((store) => store.rfp.data.kliparo_section_list);
  const responses = useSelector((store) => store.rfpResponse.data.traro_element_response_list);
  const user = useUser();

  return useMemo(() => {
    const mapQuestion = (questionSection: QuestionSection): QuestionSectionWithResponse => ({
      ...questionSection,
      rfpElementResponse: responses.find((response) => response.traro_element === questionSection.traro_element.id),
    });

    const mapSection = (kliparoSection: KliparoSection): KliparoSectionWithResponse => {
      const questions = kliparoSection.question_section_list.map(mapQuestion);
      const questionsWithResponses = questions.filter((question) => question.rfpElementResponse !== undefined);

      const questionStatuses = questions.map(
        (question) => question.rfpElementResponse?.question_status ?? QuestionStatus.BEGIN,
      );
      const [sectionRating, sectionScoringRubric] = getSectionScore(user, questionsWithResponses);

      return {
        ...kliparoSection,
        questions: kliparoSection.question_section_list
          .map(mapQuestion)
          .sort((q1, q2) => q1.question_number - q2.question_number),
        sectionStatus: getSectionStatus(questionStatuses),
        sectionRating,
        sectionScoringRubric,
      };
    };

    return kliparoSections.map(mapSection);
  }, [kliparoSections, responses, user]);
};

export default useKliparoSectionsWithResponses;
