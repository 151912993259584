/* eslint-disable @typescript-eslint/naming-convention */
import { createAsyncThunk } from '@reduxjs/toolkit';

import * as rfp from 'services/rfp';
import RFPElementHistoryEntry from 'types/api/RFPElementHistoryEntry';

import { RootState } from '../store';

export const loadElementResponseHistory = createAsyncThunk<
  RFPElementHistoryEntry[],
  number | undefined,
  { state: RootState }
>('elementHistory/loadElementResponseHistory', async (responseId) => {
  if (responseId === undefined) {
    return [];
  }

  const { data } = await rfp.loadRfpElementResponseHistory(responseId);

  return data;
});
