import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { ButtonVariant } from '../Button/Button';

import './LinkButton.scss';

export interface LinkButtonProps extends LinkProps {
  variant?: ButtonVariant;
  fullWidth?: boolean;
}

const LinkButton: FC<LinkButtonProps> = ({
  children,
  variant = 'default',
  fullWidth = false,
  className = '',
  ...props
}) => (
  <Link
    {...props}
    className={`enkrateia-button enkrateia-link-button variant-${variant} ${
      fullWidth ? 'full-width' : ''
    } ${className}`}
  >
    {children}
  </Link>
);

export default LinkButton;
