import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { CenterAlignedCell, ChevronCell, EllipsisTextCell } from 'components/Table';
import RFPResponseWithRFPAndStats from 'types/api/RFPResponseWithRFPAndStats';

import StatusCell from '../components/StatusCell/StatusCell';
import CompletionPercentageCell from '../components/CompletionPercentageCell/CompletionPercentageCell';

const useColumns = (isReviewer?: boolean) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const columnHelper = createColumnHelper<RFPResponseWithRFPAndStats>();

    const columns: ColumnDef<RFPResponseWithRFPAndStats, any>[] = [];

    if (isReviewer) {
      columns.push(
        columnHelper.accessor('organization.name', {
          cell: EllipsisTextCell,
          header: t('pages.dashboard.table.organization'),
        }),
      );
    }

    columns.push(
      columnHelper.accessor('project_name', {
        cell: EllipsisTextCell,
        header: t('pages.dashboard.table.programName'),
      }),
      columnHelper.accessor('open_ticket_count', {
        header: t('pages.dashboard.table.openTickets'),
        cell: CenterAlignedCell,
      }),
      columnHelper.accessor('percent_complete', {
        header: t('pages.dashboard.table.percentComplete'),
        cell: CompletionPercentageCell,
      }),
      columnHelper.accessor('rfp_response_status', {
        header: t('pages.dashboard.table.status'),
        cell: StatusCell,
      }),
      columnHelper.accessor('id', {
        header: '',
        size: 10,
        cell: ChevronCell,
      }),
    );

    return columns;
  }, [t, isReviewer]);
};

export default useColumns;
