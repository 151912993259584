import React, { FC } from 'react';
import ErrorOverlay from 'components/ErrorOverlay/ErrorOverlay';
import LoaderOverlay from 'components/LoaderOverlay/LoaderOverlay';
import ErrorModal from 'components/ErrorModal/ErrorModal';

import useDataLoader from './hooks/useDataLoader';

export interface DataLoaderProps {
  children: React.ReactNode;
}

const DataLoader: FC<DataLoaderProps> = ({ children }) => {
  const [loading, error, renderChildren] = useDataLoader();

  return (
    <>
      <ErrorModal />
      {renderChildren && children}
      <ErrorOverlay error={error as Error} />
      <LoaderOverlay open={loading} />
    </>
  );
};

export default DataLoader;
