import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import FormikTextInput from 'components/forms/formik/FormikTextInput/FormikTextInput';
import FormikTextAreaInput from 'components/forms/formik/FormikTextAreaInput/FormikTextAreaInput';
import FormikSelectInput from 'components/forms/formik/FormikSelectInput/FormikSelectInput';
import FormikCheckboxInput from 'components/forms/formik/FormikCheckboxInput/FormikCheckboxInput';
import FormikPhoneNumberInput from 'components/forms/formik/FormikPhoneNumberInput/FormikPhoneNumberInput';
import Button from 'components/Button/Button';
import Header from 'components/Header/Header';

import useRegisterForm, { RegisterFormData } from './hooks/useRegisterForm';
import RegistrationSuccessModal from './components/RegistrationSuccessModal/RegistrationSuccessModal';
import useCountries from './hooks/useCountriesList';

import './RegisterPage.scss';

export interface RegisterPageProps {}

const RegisterPage: FC<RegisterPageProps> = () => {
  const { t } = useTranslation();
  const { onSubmit, initialValues, schema } = useRegisterForm();
  const countries = useCountries();

  return (
    <div className="enkrateia-register">
      <div className="background"></div>
      <Header />
      <Formik<RegisterFormData> initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
        <Form>
          <main>
            <h1>{t('pages.register.title')}</h1>
            <section>
              <h2>{t('pages.register.organization.title')}</h2>
              <p>{t('pages.register.organization.description')}</p>
              <div className="row">
                <div className="col">
                  <FormikTextInput
                    hint={t('pages.register.help.organization.name')}
                    variant="filled"
                    name="organizationName"
                    required
                    label={t('pages.register.organization.name')}
                  />
                  <FormikTextInput
                    hint={t('pages.register.help.organization.url')}
                    variant="filled"
                    name="organizationUrl"
                    required
                    label={t('pages.register.organization.url')}
                  />
                  <FormikSelectInput
                    hint={t('pages.register.help.organization.country')}
                    variant="filled"
                    name="country"
                    required
                    options={countries}
                    label={t('pages.register.organization.country')}
                  />
                </div>
                <div className="col">
                  <FormikTextAreaInput
                    hint={t('pages.register.help.organization.address')}
                    variant="filled"
                    name="organizationAddress"
                    rows={5}
                    required
                    label={t('pages.register.organization.address')}
                  />
                </div>
              </div>
            </section>
            <section>
              <h2>{t('pages.register.contact.title')}</h2>
              <p>{t('pages.register.contact.description')}</p>
              <div className="row">
                <div className="col">
                  <FormikTextInput
                    hint={t('pages.register.help.contact.name')}
                    required
                    variant="filled"
                    name="name"
                    label={t('pages.register.contact.name')}
                  />
                  <FormikTextInput
                    hint={t('pages.register.help.contact.titleRole')}
                    required
                    variant="filled"
                    name="title"
                    label={t('pages.register.contact.titleRole')}
                  />
                </div>
                <div className="col">
                  <FormikTextInput
                    hint={t('pages.register.help.contact.email')}
                    required
                    variant="filled"
                    name="email"
                    type="email"
                    label={t('pages.register.contact.email')}
                  />
                  <div>
                    <FormikPhoneNumberInput
                      hint={t('pages.register.help.contact.phone')}
                      variant="filled"
                      name="phone"
                      label={t('pages.register.contact.phone')}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="terms">
              <FormikCheckboxInput
                name="terms"
                label={
                  <Trans t={t} i18nKey="pages.register.terms">
                    I agree to the
                    <a href={t('common.footer.links.terms.url')} target="_blank" rel="noreferrer">
                      Platform terms of Service
                    </a>
                  </Trans>
                }
              />
            </section>
            <section>
              <div className="row submit">
                <Button type="submit" variant="primary">
                  {t('pages.register.completeRegistration')}
                </Button>
                <a href={t('common.footer.links.contact.url')}>{t('pages.register.needHelp')}</a>
              </div>
            </section>
          </main>
        </Form>
      </Formik>
      <RegistrationSuccessModal />
    </div>
  );
};

export default RegisterPage;
