import React, { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useSelector } from 'store';
import useUserPersistedState from 'hooks/useUserPersistedState';
import { DoubleChevronRight } from 'components/SvgIcons';
import ReviewRatingBulb from 'components/ReviewRatingBulb/ReviewRatingBulb';
import KliparoResponseStatusIndicator from 'components/KliparoResponseStatusIndicator/KliparoResponseStatusIndicator';
import { canSeeCriterionReviewRating } from 'services/permissions';
import useUser from 'hooks/useUser';

import commentIcon from 'assets/img/commentIcon.png';

import { KliparoSectionWithResponse } from '../../hooks/useKliparoSectionsWithResponses';

import './KliparoSectionRow.scss';

export interface KliparoSectionRowProps {
  section: KliparoSectionWithResponse;
}

const KliparoSectionRow: FC<KliparoSectionRowProps> = ({ section }) => {
  const navigate = useNavigate();
  const user = useUser();
  const rfpId = useSelector((store) => store.rfp.data.id);
  const rfpResponseId = useSelector((store) => store.rfpResponse.data.id);

  const [expanded, setExpanded] = useUserPersistedState(
    `section-row:${rfpResponseId}:${section.section_number}`,
    false,
  );
  const ticketCount = section.questions.reduce((count, question) => {
    return count + (question.rfpElementResponse?.ticket_list?.length ?? 0);
  }, 0);

  return (
    <>
      <tr
        className={`enkrateia-kliparo-section-row ${expanded ? 'expanded' : ''}`}
        onClick={() => setExpanded((p) => !p)}
      >
        <td className="center id">
          <DoubleChevronRight color="#7D7D7D" size={15} />
        </td>
        <td className="section-name">{section.section_name}</td>
        <td className="review-rating">
          <div>
            <ReviewRatingBulb score={section.sectionRating} scoringRubrics={section.sectionScoringRubric} />
          </div>
        </td>
        <td className="tickets">
          <div>
            <Link onClick={(e) => e.stopPropagation()} to={`/tickets`}>
              {ticketCount} <img src={commentIcon} alt="comment" />
            </Link>
          </div>
        </td>
        <td className="response-status">
          <div>
            <KliparoResponseStatusIndicator status={section.sectionStatus} />
          </div>
        </td>
        <td />
      </tr>
      {section.questions.map((question) => (
        <tr
          key={`${section.section_number}.${question.question_number}`}
          className={`enkrateia-kliparo-section-row question ${expanded ? 'expanded' : ''}`}
          onClick={() =>
            navigate(
              `/rfp/${rfpId}/rfp-response/${rfpResponseId}/rfp-question-response/${section.section_number}/${question.question_number}`,
            )
          }
        >
          <td>
            <div />
          </td>
          <td>
            <div>{question.traro_element.element_name}</div>
          </td>
          <td className="review-rating">
            <div>
              <ReviewRatingBulb
                score={
                  canSeeCriterionReviewRating(user, question.rfpElementResponse)
                    ? question.rfpElementResponse?.question_score
                    : null
                }
                scoringRubrics={question.traro_element.scoring_rubric}
              />
            </div>
          </td>
          <td className="tickets">
            <div>
              <Link
                onClick={(e) => e.stopPropagation()}
                to={`/rfp/${rfpId}/rfp-response/${rfpResponseId}/rfp-question-response/${section.section_number}/${question.question_number}?initialTab=tickets`}
              >
                {question.rfpElementResponse?.ticket_list?.length ?? 0} <img src={commentIcon} alt="comment" />
              </Link>
            </div>
          </td>
          <td className="response-status">
            <div>
              <KliparoResponseStatusIndicator status={question.rfpElementResponse?.question_status} variant="small" />
            </div>
          </td>
          <td>
            <div />
          </td>
        </tr>
      ))}
    </>
  );
};

export default KliparoSectionRow;
