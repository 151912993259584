import { createAsyncThunk } from '@reduxjs/toolkit';

import * as auth from 'services/auth';
import FormSubmitError from 'errors/FormSubmitError';
import UserDto from 'types/api/UserDto';
import { setMode } from 'store/rfp';
import RfpMode from 'types/RfpMode';

interface Result {
  user: UserDto;
}

export const login = createAsyncThunk<Result, { email: string; password: string }>(
  'auth/login',
  async ({ email, password }, thunkAPI) => {
    const { data } = await auth.login(email, password);

    if (data.error || data.data === undefined) {
      throw new FormSubmitError(data.error ?? 'Unknown error');
    }

    const user = data.data;

    await thunkAPI.dispatch(setMode(RfpMode.ARCH));

    return {
      user,
    };
  },
);

export const logout = createAsyncThunk('auth/logout', async () => {
  await auth.logout();
});
