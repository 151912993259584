import { useMemo } from 'react';
import TicketSeverityType from 'types/TicketSeverityType';
import TicketSeverityRating from 'components/TicketSeverityRating/TicketSeverityRating';
import { InputOption } from 'components/forms/raw/SelectInput';

const useConnectedQuestionOptions = (): InputOption<TicketSeverityType>[] =>
  useMemo(
    () => [
      {
        value: TicketSeverityType.NOT_ASSIGNED,
        label: <TicketSeverityRating severity={TicketSeverityType.NOT_ASSIGNED} variant="small" />,
      },
      {
        value: TicketSeverityType.LOW,
        label: <TicketSeverityRating severity={TicketSeverityType.LOW} variant="small" />,
      },
      {
        value: TicketSeverityType.MEDIUM,
        label: <TicketSeverityRating severity={TicketSeverityType.MEDIUM} variant="small" />,
      },
      {
        value: TicketSeverityType.HIGH,
        label: <TicketSeverityRating severity={TicketSeverityType.HIGH} variant="small" />,
      },
    ],
    [],
  );

export default useConnectedQuestionOptions;
