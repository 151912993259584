import { useMemo } from 'react';

import { getQuestionSectionForResponse } from 'store/rfpResponse/helpers';
import TraroElementResponse from 'types/api/TraroElementResponse';
import QuestionSection from 'types/api/QuestionSection';
import RFPResponseWithRFP from 'types/api/RFPResponseWithRFP';

import { LinkedCriterionInputOption } from './useInitialValues';

const useConnectedQuestionOptions = (connectedRfpResponse: RFPResponseWithRFP | null | undefined) =>
  useMemo(() => {
    if (!connectedRfpResponse) {
      return [];
    }

    const sections = connectedRfpResponse.traro_rfp.kliparo_section_list;
    const responses = connectedRfpResponse.traro_element_response_list;

    const options = responses
      .map((response) => [response, getQuestionSectionForResponse(sections, response)])
      .filter(([, question]) => question !== null) as [TraroElementResponse, QuestionSection][];

    return options
      .reduce((acc, [response, question]) => {
        acc.push({
          label: question.traro_element.element_name,
          value: response.id as number,
          meta: {
            response,
            question,
          },
        });

        return acc;
      }, [] as LinkedCriterionInputOption[])
      .sort((a, b) => {
        if (typeof a.label === 'string' && typeof b.label === 'string') {
          return a.label.localeCompare(b.label);
        }

        return 0;
      });
  }, [connectedRfpResponse]);

export default useConnectedQuestionOptions;
