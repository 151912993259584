import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TicketDto from 'types/api/TicketDto';
import Button from 'components/Button/Button';
import { InputOption } from 'components/forms/raw/SelectInput';
import UserDto from 'types/api/UserDto';

import RequestTicketCloseModal from './components/RequestTicketCloseModal/RequestTicketCloseModal';

export interface ChangeTicketStatusButtonProps {
  ticket: TicketDto;
  disabled?: boolean;
  selectableUsers: InputOption<number, UserDto>[];
}

const RequestTicketCloseButton: FC<ChangeTicketStatusButtonProps> = ({ ticket, disabled, selectableUsers }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button variant="black-filled" onClick={() => setIsOpen(true)} disabled={disabled}>
        {t(`pages.ticketDetails.requestTicketClose.title`)}
      </Button>
      <RequestTicketCloseModal
        isOpen={isOpen}
        ticket={ticket}
        onClose={() => setIsOpen(false)}
        selectableUsers={selectableUsers}
      />
    </>
  );
};

export default RequestTicketCloseButton;
