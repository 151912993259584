import { useQuery } from '@tanstack/react-query';

import FormSubmitError from 'errors/FormSubmitError';
import TicketDto from 'types/api/TicketDto';
import API from 'services/api';

export const fetchTicket = async (ticketId: number | string): Promise<TicketDto> => {
  try {
    return (await API.get<TicketDto>(`/ticket/${ticketId}`)).data;
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

const getQueryKey = (id?: number | string) => {
  const key: unknown[] = ['ticket'];

  if (id !== undefined) {
    key.push(String(id));
  }

  return key;
};

const useTicket = (id: number | string) => useQuery(getQueryKey(id), () => fetchTicket(id));

useTicket.getQueryKey = getQueryKey;
useTicket.queries = {
  fetchTicket,
} as const;

export default useTicket;
