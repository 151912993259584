import { useCallback } from 'react';

import { updateKliparoRfpElementScore } from 'store/rfpResponse';
import { useDispatch, useSelector } from 'store';

import { UpdateResponseStatusFormValues } from './useInitialValues';
import { FormikHelpers } from 'formik';

const useOnSubmit = (elementId: number, setOpen: (isOpen: boolean) => void) => {
  const dispatch = useDispatch();
  const rfpId = useSelector((state) => state.rfp.data.id);
  const rfpResponseId = useSelector((store) => store.rfpResponse.data.id);

  return useCallback(
    async (
      { programEligibility, status }: UpdateResponseStatusFormValues,
      helpers: FormikHelpers<UpdateResponseStatusFormValues>,
    ) => {
      try {
        await dispatch(
          updateKliparoRfpElementScore({
            rfpId,
            rfpResponseId,
            score: programEligibility ? programEligibility.meta.low_score : null,
            status,
            elementId,
          }),
        ).unwrap();

        setOpen(false);
      } catch (e) {
        if ((e as Error).message) {
          helpers.setFieldError('status', (e as Error).message);
        }
      }
    },
    [dispatch, rfpId, rfpResponseId, setOpen, elementId],
  );
};

export default useOnSubmit;
