import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import SideBar from 'components/SideBar/SideBar';
import ProjectInfoPanel from 'components/ProjectInfoPanel/ProjectInfoPanel';
import KliparoResponseStatusIndicator from 'components/KliparoResponseStatusIndicator/KliparoResponseStatusIndicator';
import ReviewRatingBulb from 'components/ReviewRatingBulb/ReviewRatingBulb';
import KliparoSection from 'types/api/KliparoSection';
import TraroElement from 'types/api/TraroElement';
import TraroElementResponse from 'types/api/TraroElementResponse';
import useICContact from 'hooks/useICContact/useICContact';
import Loader from 'components/Loader/Loader';
import { canSeeCriterionReviewRating } from 'services/permissions';
import useUser from 'hooks/useUser';

import QuestionInfoPanel from './components/QuestionInfoPanel/QuestionInfoPanel';
import SectionInfoPanel from './components/SectionInfoPanel/SectionInfoPanel';

import './KliparoElementSideBar.scss';

export interface KliparoElementSideBarProps {
  section: KliparoSection;
  traroElement: TraroElement;
  rfpElementResponse: TraroElementResponse;
  questionNumber: number;
  ticketCount?: number;
}

const KliparoElementSideBar: FC<KliparoElementSideBarProps> = ({
  section,
  traroElement,
  rfpElementResponse,
  questionNumber,
  ticketCount,
}) => {
  const { t } = useTranslation();
  const [contactEmail, contactName] = useICContact(rfpElementResponse);
  const user = useUser();

  return (
    <SideBar variant="kliparo" collapsible className="enkrateia-kliparo-element-side-bar">
      <div className="side-bar-content">
        <SectionInfoPanel section={section} />
        <QuestionInfoPanel section={section} questionNumber={questionNumber} elementName={traroElement.element_name} />
        <ProjectInfoPanel
          label={t('pages.traroElement.sidePanel.responseStatus')}
          value={<KliparoResponseStatusIndicator status={rfpElementResponse.question_status} variant="large" />}
        />
        <ProjectInfoPanel label={t('pages.traroElement.sidePanel.lastUpdate')} value="28-Feb-2023" />
        {canSeeCriterionReviewRating(user, rfpElementResponse) && (
          <ProjectInfoPanel
            label={t('pages.traroElement.sidePanel.icReview')}
            value={
              <ReviewRatingBulb
                score={rfpElementResponse.question_score}
                scoringRubrics={traroElement.scoring_rubric}
                variant="with-label"
              />
            }
          />
        )}
        <div className="middle-panels">
          <ProjectInfoPanel
            withBorder
            label={t('pages.traroElement.sidePanel.openTickets')}
            value={ticketCount ?? <Loader />}
          />
        </div>
        <ProjectInfoPanel
          label={t('pages.traroElement.sidePanel.icContact')}
          value={<a href={`mailto:${contactEmail}`}>{contactName}</a>}
        />
      </div>
    </SideBar>
  );
};

export default KliparoElementSideBar;
