import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'store';
import { closeModal } from 'store/modals';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import useUser from 'hooks/useUser';

import './TraroElementSubmittedModal.scss';

export interface TraroElementSubmittedModalProps {
  springerArcTitle: string;
}

const TraroElementSubmittedModal: FC<TraroElementSubmittedModalProps> = ({ springerArcTitle }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const isOpen = useSelector((store) => store.modals.traroElementSubmitModal.open);

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal('traroElementSubmitModal'));
  }, [dispatch]);
  const { isReviewer } = useUser();

  return (
    <Modal isOpen={isOpen} className="enkrateia-element-submitted-modal">
      <div className="title">
        <h2>
          {isReviewer ? t('pages.traroElement.modalTitle.reviewer') : t('pages.traroElement.modalTitle.proponent')}
        </h2>
      </div>
      <div className="summary">
        <span>{springerArcTitle}</span>
        <Button variant="primary" onClick={handleCloseModal}>
          {t('common.ok')}
        </Button>
      </div>
    </Modal>
  );
};

export default TraroElementSubmittedModal;
