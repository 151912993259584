import React from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import SplashScreenTemplate from 'components/SplashScreenTemplate/SplashScreenTemplate';
import FormikTextInput from 'components/forms/formik/FormikTextInput/FormikTextInput';
import SubmitButton from 'components/SubmitButton/SubmitButton';

import useResetPasswordSubmit from './hooks/useResetPasswordSubmit';
import useInitialValues from './hooks/useInitialValues';
import useSchema from './hooks/useSchema';

import './ResetPasswordPage.scss';

const ResetPasswordPage = () => {
  const { t } = useTranslation();

  const initialValues = useInitialValues();
  const schema = useSchema();
  const [onSubmit, isLoading, isSuccess] = useResetPasswordSubmit();

  return (
    <SplashScreenTemplate className="enkrateia-reset-password-page">
      {!isSuccess && (
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
          <Form>
            <FormikTextInput
              label={t('pages.resetPassword.form.email')}
              name="email"
              type="email"
              fullWidth
              disabled={isLoading}
            />
            <SubmitButton variant="primary">{t('pages.resetPassword.form.submit')}</SubmitButton>
          </Form>
        </Formik>
      )}
      {isSuccess && (
        <div className="success">
          <h3>{t('pages.resetPassword.success.title')}</h3>
          <p>{t('pages.resetPassword.success.description')}</p>
          <Link to="/login">{t('pages.resetPassword.success.backToLogin')}</Link>
        </div>
      )}
    </SplashScreenTemplate>
  );
};

export default ResetPasswordPage;
