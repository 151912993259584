/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from '@reduxjs/toolkit';
import RFPElementHistoryEntry from 'types/api/RFPElementHistoryEntry';

import { loadElementResponseHistory } from './thunks';

interface Slice {
  data: RFPElementHistoryEntry[];
  loading: boolean;
  error: string | undefined;
}

export const elementHistorySlice = createSlice({
  name: 'elementHistory',
  initialState: {
    data: [],
    error: undefined,
    loading: false,
  } as Slice,
  reducers: {
    reset: (state) => {
      state.data = [];
      state.loading = false;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadElementResponseHistory.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(loadElementResponseHistory.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(loadElementResponseHistory.rejected, (state, action) => {
      state.data = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default elementHistorySlice.reducer;
