import React, { ComponentType, RefObject, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button/Button';
import LoaderOverlay from 'components/LoaderOverlay/LoaderOverlay';
import { PencilIcon } from 'components/SvgIcons';

import './EditableProjectInfoPanel.scss';

interface ComponentProps<T> {
  value: T;
  onChange: (value: T) => void;
  className?: string;
  onKeyDown?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  innerRef?: RefObject<any>;
}

export interface ProjectInfoPanelProps<T> {
  label: string | JSX.Element;
  value: T;
  className?: string;
  withBorder?: boolean;
  disabled?: boolean;
  onSubmit: (value: T) => Promise<void>;
  variant: 'traro' | 'kliparo';
  InputComponent: ComponentType<ComponentProps<T>>;
  areValuesEqual?: (a: T, b: T) => boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const DEFAULT_VALUE_COMPARATOR = (a: unknown, b: unknown) => a === b;

const EditableProjectInfoPanel = <T,>({
  label,
  value,
  className = '',
  withBorder = false,
  disabled = false,
  onSubmit,
  variant,
  InputComponent,
  areValuesEqual = DEFAULT_VALUE_COMPARATOR,
}: ProjectInfoPanelProps<T>) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [localValue, setLocalValue] = useState(value);
  const ref = useRef<HTMLElement | null>(null);

  const focus = useCallback(() => {
    if (!ref.current) {
      return;
    }

    ref.current?.focus();
  }, [ref]);

  const adjustTextAreaSize = useCallback(() => {
    if (!ref.current) {
      return;
    }

    ref.current.style.height = 'inherit';
    ref.current.style.height = `${ref.current.scrollHeight}px`;
  }, [ref]);

  useLayoutEffect(adjustTextAreaSize, [adjustTextAreaSize]);

  useEffect(() => {
    setLocalValue(value);
    setTimeout(() => {
      adjustTextAreaSize();
    }, 100);
    setTimeout(() => {
      adjustTextAreaSize();
    }, 300);
  }, [value, adjustTextAreaSize]);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);

      await onSubmit(localValue);
    } finally {
      setLoading(false);
    }
  }, [localValue, setLoading, onSubmit]);

  return (
    <div
      className={`enkrateia-editable-project-info-panel ${
        withBorder ? 'with-border' : ''
      } variant-${variant} ${className}`}
    >
      <div className="label">
        {!disabled && (
          <button onClick={focus}>
            <PencilIcon size={14} />
          </button>
        )}
        {label}
      </div>
      <InputComponent
        className="value"
        value={localValue}
        innerRef={ref}
        onChange={setLocalValue}
        onKeyDown={adjustTextAreaSize}
        onBlur={adjustTextAreaSize}
        disabled={disabled}
      />
      {!areValuesEqual(localValue, value) && (
        <div className="actions">
          <Button
            variant={variant === 'traro' ? 'primary' : 'secondary-filled'}
            className="submit"
            onClick={handleSubmit}
          >
            {t('common.save')}
          </Button>
          <Button variant="danger" className="clear" onClick={() => setLocalValue(value)}>
            {t('common.reset')}
          </Button>
        </div>
      )}
      <LoaderOverlay className="dark" open={loading} />
    </div>
  );
};

export default EditableProjectInfoPanel;
