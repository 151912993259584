import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import userMenu from 'assets/img/userMenu.png';
import { useDispatch } from 'store';
import { logout } from 'store/auth';
import Button from 'components/Button/Button';

import './UserDetails.scss';

export interface UserDetailsProps {}

const UserDetails: FC<UserDetailsProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);

  const onLogout = useCallback(async () => {
    setMenuOpen(false);

    await dispatch(logout()).unwrap();
  }, [dispatch, setMenuOpen]);

  return (
    <div className="enkrateia-header-user-details">
      <button type="button" className="nav-element" onClick={() => setMenuOpen(true)}>
        <img src={userMenu} alt="Menu" />
      </button>
      {menuOpen && <button type="button" className="menu-overlay" onClick={() => setMenuOpen(false)} />}
      <div className={menuOpen ? 'menu open' : 'menu closed'}>
        <Button onClick={onLogout}>{t('common.logout')}</Button>
      </div>
    </div>
  );
};

export default UserDetails;
