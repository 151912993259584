import React, { FC } from 'react';
import { CSSTransition } from 'react-transition-group';

import Loader from 'components/Loader/Loader';

import './LoaderOverlay.scss';

export interface LoaderOverlayProps {
  className?: string;
  open?: boolean;
}

const LoaderOverlay: FC<LoaderOverlayProps> = ({ className = '', open }) => (
  <CSSTransition in={open} mountOnEnter unmountOnExit classNames="fade" timeout={300}>
    <div className={`enkrateia-loader-overlay ${className}`}>
      <Loader />
    </div>
  </CSSTransition>
);
export default LoaderOverlay;
