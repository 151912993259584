import { useQuery } from '@tanstack/react-query';

import API from 'services/api';
import FormSubmitError from 'errors/FormSubmitError';
import UserDto from 'types/api/UserDto';
import OrganizationDto from 'types/api/OrganizationDto';

export const fetchCurrentOrganizationUsers = async (disabled?: boolean): Promise<UserDto[]> => {
  try {
    if (disabled) {
      return [];
    }

    return (await API.get<OrganizationDto & { user_list: UserDto[] }>('/organization-user-list/')).data.user_list;
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

const getQueryKey = () => ['currentOrganizationUsers'];

const useCurrentOrganizationUsers = (disabled?: boolean) =>
  useQuery(getQueryKey(), () => fetchCurrentOrganizationUsers(disabled));

useCurrentOrganizationUsers.getQueryKey = getQueryKey;
useCurrentOrganizationUsers.queries = {
  fetchCurrentOrganizationUsers,
} as const;

export default useCurrentOrganizationUsers;
