import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { PdfIcon, HelpIcon } from 'components/SvgIcons';
import SideBar from 'components/SideBar/SideBar';
import Button from 'components/Button/Button';
import ProjectInfoPanel from 'components/ProjectInfoPanel/ProjectInfoPanel';
import RFPResponseStatusIndicator from 'components/RFPResponseStatusIndicator/RFPResponseStatusIndicator';
import EditableProjectInfoPanel from 'components/EditableProjectInfoPanel/EditableProjectInfoPanel';
import Tooltip from 'components/Tooltip/Tooltip';
import Loader from 'components/Loader/Loader';
import useFormattedDate from 'hooks/useFormattedDate';
import useUser from 'hooks/useUser';
import UserDto from 'types/api/UserDto';
import ReviewRatingBulb from 'components/ReviewRatingBulb/ReviewRatingBulb';
import TextAreaInput from 'components/forms/raw/TextAreaInput';
import {
  canEditProjectName,
  canEditRfpResponseCloseDate,
  canEditRfpResponseICContact,
  canExportResponses,
} from 'services/permissions';
import { useDispatch, useSelector } from 'store';
import { updateRfpResponseDueDate, updateRfpResponseName, updateRfpResponsePointOfContact } from 'store/rfpResponse';
import { useDownloadResponsePdfExport, useSingleRfpResponseStats } from 'api';

import DaysLeft from './components/DaysLeft/DaysLeft';
import ICContact from './components/ICContact/ICContact';

import './KliparoSideBar.scss';

export interface KliparoSideBarProps {}

const KliparoSideBar: FC<KliparoSideBarProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: rfpId, response_close_date, scoring_rubric } = useSelector((store) => store.rfp.data);
  const rfpResponse = useSelector((store) => store.rfpResponse.data);
  const {
    id: rfpResponseId,
    project_name,
    rfp_response_status,
    last_update,
    organization,
    rfp_response_score,
    review_due_date,
  } = rfpResponse;

  const { data: stats } = useSingleRfpResponseStats(rfpResponseId);
  const lastUpdate = useFormattedDate(last_update);
  const user = useUser();

  const handleProjectNameChange = useCallback(
    async (newProjectName: string) => {
      await dispatch(updateRfpResponseName({ rfpId: rfpId, newProjectName })).unwrap();
    },
    [dispatch, rfpId],
  );

  const handleReviewDueDateChange = useCallback(
    async (newDueDate: DateTime | null) => {
      await dispatch(updateRfpResponseDueDate({ rfpId: rfpId, newDueDate: newDueDate?.toISO() ?? null })).unwrap();
    },
    [dispatch, rfpId],
  );

  const handlePointOfContactChange = useCallback(
    async (newPointOfContact: UserDto | null) => {
      await dispatch(updateRfpResponsePointOfContact({ rfpId: rfpId, newPointOfContact })).unwrap();
    },
    [dispatch, rfpId],
  );

  const exportResponses = useDownloadResponsePdfExport(rfpResponseId);

  return (
    <SideBar variant="kliparo" className="enkrateia-kliparo-side-bar" collapsible>
      <div className="side-bar-content">
        <ProjectInfoPanel
          variant="kliparo"
          label={t('pages.projectOverview.sidePanel.company')}
          value={organization.name}
        />
        <EditableProjectInfoPanel
          variant="kliparo"
          label={t('pages.projectOverview.sidePanel.programName')}
          value={project_name ?? t('pages.projectOverview.sidePanel.pleaseEnterProjectName')}
          className="span-2-column"
          disabled={!canEditProjectName(user, rfpResponse)}
          onSubmit={handleProjectNameChange}
          InputComponent={({ innerRef, ...props }) => (
            <TextAreaInput {...props} ref={innerRef} rows={1} variant="raw" />
          )}
          areValuesEqual={(a, b) => a.trim() === b.trim()}
        />
        <ProjectInfoPanel
          label={t('pages.projectOverview.sidePanel.assessmentStatus')}
          value={<RFPResponseStatusIndicator status={rfp_response_status} />}
        />
        <ProjectInfoPanel
          label={t('pages.projectOverview.sidePanel.programEligibility')}
          value={<ReviewRatingBulb variant="with-label" score={rfp_response_score} scoringRubrics={scoring_rubric} />}
        />
        <div className="middle-panels">
          <ProjectInfoPanel
            withBorder
            label={t('pages.projectOverview.sidePanel.assessmentCompletion')}
            value={stats !== undefined ? `${Math.round(stats.percent_complete)}%` : <Loader />}
          />
          <ProjectInfoPanel
            withBorder
            label={t('pages.projectOverview.sidePanel.openTickets')}
            value={stats !== undefined ? stats.open_ticket_count : <Loader />}
          />
        </div>
        <ProjectInfoPanel
          variant="kliparo"
          label={t('pages.projectOverview.sidePanel.lastUpdated')}
          value={lastUpdate}
        />
        {Boolean(response_close_date) && (
          <DaysLeft
            closeDate={response_close_date}
            label={t('pages.projectOverview.sidePanel.assessmentCloseDate')}
            disabled
          />
        )}
        {user.isReviewer && (
          <DaysLeft
            closeDate={review_due_date}
            label={t('pages.projectOverview.sidePanel.submissionDate')}
            disabled={!canEditRfpResponseCloseDate(user, rfpResponse)}
            onSubmit={handleReviewDueDateChange}
          />
        )}
        <ICContact
          onPointOfContactChange={handlePointOfContactChange}
          disabled={!canEditRfpResponseICContact(user, rfpResponse)}
        />
        <div className="entry-button-with-help">
          <Button
            variant="default"
            className="enkrateia-sidebar-button"
            onClick={exportResponses}
            disabled={!canExportResponses(user, rfpResponse)}
          >
            <PdfIcon color="white" size={22} />
            {t('pages.projectOverview.sidePanel.exportResponses')}
          </Button>
          <Tooltip tooltip={t('pages.projectOverview.sidePanel.exportResponsesHelp')} placement="right">
            <HelpIcon size={24} color="#fff" />
          </Tooltip>
        </div>
      </div>
    </SideBar>
  );
};

export default KliparoSideBar;
