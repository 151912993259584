import { createAsyncThunk } from '@reduxjs/toolkit';

import { useRfpResponsesWithStats } from 'api';
import RFPResponseWithRFP from 'types/api/RFPResponseWithRFP';

export const loadRfpResponsesList = createAsyncThunk<RFPResponseWithRFP[], void>(
  'rfpReviewerList/loadRfpResponsesList',
  async () => {
    const { data } = await useRfpResponsesWithStats.queries.fetchRfpResponsesList();

    return data;
  },
);
