import React, { FC } from 'react';
import { Row } from '@tanstack/react-table';

import RFPResponseWithRFPAndStats from 'types/api/RFPResponseWithRFPAndStats';

import './CompletionPercentageCell.scss';

export interface CompletionPercentageCellProps {
  getValue: () => number;
  row: Row<RFPResponseWithRFPAndStats>;
}

const CompletionPercentageCell: FC<CompletionPercentageCellProps> = ({ getValue, row }) => {
  const value = getValue();
  const percentage = `${Math.round(value)}%`;

  return (
    <div className={`enkrateia-rfp-response-completion-percentage-cell ${row.original.rfp_response_status}`}>
      <div className="bar">
        <div className="fill" style={{ width: percentage }} />
      </div>
      <div className="text">{percentage}</div>
    </div>
  );
};

export default CompletionPercentageCell;
