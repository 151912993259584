import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

import TraroElementResponse from 'types/api/TraroElementResponse';
import FormikTextAreaInput from 'components/forms/formik/FormikTextAreaInput/FormikTextAreaInput';
import Button from 'components/Button/Button';

import useTraroElementCommentForm from './hooks/useTraroElementCommentForm';

import './CommentEntry.scss';

export interface CommentEntryProps {
  traroElementResponse: TraroElementResponse;
}

const CommentEntry: FC<CommentEntryProps> = ({ traroElementResponse }) => {
  const { t } = useTranslation();
  const { initialValues, onSubmit, schema } = useTraroElementCommentForm(traroElementResponse);

  return (
    <div className="enkrateia-element-comment-entry">
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
        {({ isSubmitting, submitForm }) => (
          <Form>
            <FormikTextAreaInput name="comment" placeholder={t('pages.traroElement.comments.placeholder')} rows={5} />
            <Button type="button" variant="secondary" onClick={submitForm} disabled={isSubmitting}>
              {t('pages.traroElement.comments.send')}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CommentEntry;
