import React from 'react';

import './CenterAlignedCell.scss';

export interface CenterAlignedCellProps {
  getValue: () => string | number;
}

const CenterAlignedCell = ({ getValue }: CenterAlignedCellProps) => (
  <span className="enkrateia-center-aligned-cell">{getValue()}</span>
);

export default CenterAlignedCell;
