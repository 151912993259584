import React from 'react';

const CommonTransComponents: readonly React.ReactElement[] | { readonly [tagName: string]: React.ReactElement } = {
  // eslint-disable-next-line jsx-a11y/heading-has-content
  h1: <h1 />,
  // eslint-disable-next-line jsx-a11y/heading-has-content
  h2: <h2 />,
  // eslint-disable-next-line jsx-a11y/heading-has-content
  h3: <h3 />,
  // eslint-disable-next-line jsx-a11y/heading-has-content
  h4: <h4 />,
  // eslint-disable-next-line jsx-a11y/heading-has-content
  h5: <h5 />,
  ul: <ul />,
  li: <li />,
  b: <strong />,
  span: <span />,
  p: <p />,
  // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
  a: <a target="_blank" rel="noreferrer" />,
  br: <br />,
};

export default CommonTransComponents;
