import { useMutation } from '@tanstack/react-query';

import API from 'services/api';
import FormSubmitError from 'errors/FormSubmitError';
import AttachmentDto from 'types/api/AttachmentDto';
import { AxiosError } from 'axios';

const uploadAttachment = async (file: File) => {
  try {
    const body = new FormData();
    body.set('file', file);

    return (await API.post<AttachmentDto>(`/attachments/upload/`, body)).data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw new FormSubmitError(e.response?.data?.file?.[0] ?? e.message);
    } else {
      throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
    }
  }
};

interface UseAddTicketCommentMutationProps {
  onSuccess?: (data: AttachmentDto, variables: File) => Promise<void>;
  onError?: (error: Error, variables: File) => Promise<void>;
}

const useUploadAttachmentMutation = ({ onSuccess, onError }: UseAddTicketCommentMutationProps) =>
  useMutation<AttachmentDto, FormSubmitError, File>(uploadAttachment, {
    onSuccess: async (data, variables) => {
      if (onSuccess) {
        await onSuccess(data, variables);
      }
    },
    onError: async (error: Error, variables) => {
      if (onError) {
        await onError(error, variables);
      }
    },
  });

useUploadAttachmentMutation.queries = {
  uploadAttachment,
} as const;

export default useUploadAttachmentMutation;
