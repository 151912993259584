import React, { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import ScoringHistoryEntry from 'types/api/ScoringHistoryEntry';
import TraroElement from 'types/api/TraroElement';

import './ScoringEntry.scss';

export interface ScoringEntryProps {
  entry: ScoringHistoryEntry;
  traroElement: TraroElement;
}

const ScoringEntry: FC<ScoringEntryProps> = ({ entry, traroElement }) => {
  const { t } = useTranslation();

  const { name, score, scoreName, scoreColor } = useMemo(() => {
    const currentRubric = traroElement.scoring_rubric.find(
      (rubric) => rubric.low_score <= entry.question_score && entry.question_score <= rubric.high_score,
    );

    return {
      name: traroElement.element_name,
      score: `${entry.question_score}/${traroElement.max_score}`,
      scoreName: currentRubric?.description ?? '',
      scoreColor: currentRubric?.highlight_color ?? '',
    };
  }, [entry, traroElement]);

  return (
    <span className="enkrateia-scoring-entry">
      <Trans t={t} i18nKey="pages.traroElement.historyModal.scoring">
        {{ name }} was given a score of
        {/* @ts-ignore */}
        <em className={scoreColor}>
          {{ score }}, {{ scoreName }}
        </em>
      </Trans>
    </span>
  );
};

export default ScoringEntry;
