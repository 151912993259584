import { useQuery } from '@tanstack/react-query';

import API from 'services/api';
import FormSubmitError from 'errors/FormSubmitError';
import OrganizationDto from 'types/api/OrganizationDto';

export const fetchOrganizations = async (disabled?: boolean): Promise<OrganizationDto[]> => {
  try {
    if (disabled) {
      return [];
    }

    return (await API.get<OrganizationDto[]>(`/organization-list`)).data;
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

const getQueryKey = () => ['organization/list'];

const useOrganizations = (disabled?: boolean) => useQuery(getQueryKey(), () => fetchOrganizations(disabled));

useOrganizations.getQueryKey = getQueryKey;
useOrganizations.queries = {
  fetchOrganizations,
} as const;

export default useOrganizations;
