import { useQuery } from '@tanstack/react-query';

import API from 'services/api';
import RFP from 'types/api/RFP';
import FormSubmitError from 'errors/FormSubmitError';
import { sortKliparoQuestion } from '../../store/rfp/helpers';

export const fetchRfp = async (rfpId: number) => {
  try {
    const { data } = await API.get<RFP>('/rfp/' + rfpId);

    data.kliparo_section_list = data.kliparo_section_list.sort((a, b) => a.section_number - b.section_number);
    data.kliparo_section_list.forEach((section) => {
      section.question_section_list = section.question_section_list.sort(sortKliparoQuestion);
    });

    return data;
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

const getQueryKey = (id?: number) => {
  const keys: unknown[] = ['rfp'];

  if (id !== undefined) {
    keys.push(id);
  }

  return keys;
};

const useRfp = (id: number) => useQuery(getQueryKey(id), () => fetchRfp(id));

useRfp.getQueryKey = getQueryKey;
useRfp.queries = {
  fetchRfp,
} as const;

export default useRfp;
