import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store';

import KliparoSection from 'types/api/KliparoSection';
import PreviousNextNavigation from 'components/PreviousNextNavigation/PreviousNextNavigation';
import ProjectInfoPanel from 'components/ProjectInfoPanel/ProjectInfoPanel';

export interface QuestionNavigationProps {
  section: KliparoSection;
}

const SectionInfoPanel: FC<QuestionNavigationProps> = ({ section }) => {
  const { t } = useTranslation();
  const rfp = useSelector((store) => store.rfp.data);
  const rfpResponseId = useSelector((store) => store.rfpResponse.data.id);

  const [prevUrl, nextUrl] = useMemo(() => {
    let prev: string | undefined;
    let next: string | undefined;

    const rfpId = rfp.id;

    const sectionIndex = rfp.kliparo_section_list.findIndex((s) => s.section_number === section.section_number);

    const prevSection = rfp.kliparo_section_list[sectionIndex - 1];
    const nextSection = rfp.kliparo_section_list[sectionIndex + 1];

    if (prevSection !== undefined) {
      prev = `/rfp/${rfpId}/rfp-response/${rfpResponseId}/rfp-question-response/${prevSection.section_number}/${prevSection.question_section_list[0].question_number}`;
    }

    if (nextSection !== undefined) {
      next = `/rfp/${rfpId}/rfp-response/${rfpResponseId}/rfp-question-response/${nextSection.section_number}/${nextSection.question_section_list[0].question_number}`;
    }

    return [prev, next] as const;
  }, [rfp, section.section_number, rfpResponseId]);

  return (
    <ProjectInfoPanel
      label={t('pages.traroElement.sidePanel.section')}
      value={
        <PreviousNextNavigation prevUrl={prevUrl} nextUrl={nextUrl}>
          {section.section_name}
        </PreviousNextNavigation>
      }
    />
  );
};

export default SectionInfoPanel;
