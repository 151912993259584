enum QuestionType {
  TEXT = 'Text',
  LONG_TEXT = 'Long Text',
  CHECKBOX = 'Checkbox',
  SELECTION = 'Selection',
  URL = 'URL',
  DISPLAY_ONLY = 'Display Only',
}

export default QuestionType;
