import React, { FC, useCallback, useMemo, useState } from 'react';

import './Announcement.scss';

type DismissableAnnouncementProps = {
  dismissible: true;
};

type ExternalDismissableAnnouncementProps = {
  dismissible?: false;
  open?: boolean;
  onRequestClose?: () => void;
};

export type AnnouncementProps = {
  children: React.ReactNode;
  onDismissed?: () => void;
} & (DismissableAnnouncementProps | ExternalDismissableAnnouncementProps);

const Announcement: FC<AnnouncementProps> = ({ children, onDismissed, ...props }) => {
  const [internalOpen, setInternalOpen] = useState(true);

  const onRequestClose = useMemo(
    () => (!props.dismissible ? props.onRequestClose : () => setInternalOpen(false)),
    [props],
  );

  const onClose = useCallback(() => {
    onRequestClose?.();
    onDismissed?.();
  }, [onRequestClose, onDismissed]);

  const open = !props.dismissible ? props.open : internalOpen;

  if (!open) {
    return null;
  }

  return (
    <div className="enkrateia-announcement">
      <div className="data">{children}</div>
      <button className="close" onClick={onClose}>
        ×
      </button>
    </div>
  );
};

export default Announcement;
