import useRfpResponsesStats from './useRfpResponsesStats';

import RFPResponseStats from 'types/api/RFPResponseStats';
import FormSubmitError from 'errors/FormSubmitError';
import { useQuery } from '@tanstack/react-query';

const fetchSingleRfpResponseStats = async (rfpResponsesId: number): Promise<RFPResponseStats> => {
  try {
    return (await useRfpResponsesStats.queries.fetchRfpResponseStats([rfpResponsesId]))[rfpResponsesId];
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

const getQueryKey = (responseId?: number) =>
  responseId === undefined ? ['singleRfpResponseStats'] : ['singleRfpResponseStats', responseId];

const useSingleRfpResponseStats = (responseId: number) =>
  useQuery(getQueryKey(responseId), () => fetchSingleRfpResponseStats(responseId));

useSingleRfpResponseStats.getQueryKey = getQueryKey;
useSingleRfpResponseStats.queries = {
  fetchSingleRfpResponseStats,
} as const;

export default useSingleRfpResponseStats;
