import React, { forwardRef, SVGProps } from 'react';

export interface CheckedCircleProps extends SVGProps<SVGSVGElement> {}

const CheckedCircle = forwardRef<SVGSVGElement, CheckedCircleProps>((props, ref) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="transparent"
    className="enkrateia-arch-checked-circle"
    ref={ref}
    {...props}
  >
    <path
      d="M8.03418 14.4961L11.6539 17.8561L16.9628 8.03467"
      stroke="#80F15E"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="12.5" cy="12.5" r="11" stroke="#80F15E" strokeWidth="3" />
  </svg>
));

export default CheckedCircle;
