import React, { forwardRef, useCallback } from 'react';

import useFormikConnection, { TouchTrigger, WithoutInjectedProps } from '../hooks/useFormikConnection';
import PhoneNumberInput, { PhoneNumberEntry, PhoneNumberInputProps } from '../../raw/PhoneNumberInput';

export interface FormikTextInputProps extends WithoutInjectedProps<PhoneNumberInputProps, 'onChange'> {
  name: string;
  touchedTrigger?: TouchTrigger;
}

const FormikPhoneNumberInput = forwardRef<HTMLInputElement, FormikTextInputProps>(({ name, ...props }, ref) => {
  const { setValue, ...other } = useFormikConnection<PhoneNumberEntry, HTMLInputElement>(name, props);
  const handleChange = useCallback((value: PhoneNumberEntry) => setValue(value), [setValue]);

  return <PhoneNumberInput {...props} {...other} name={name} ref={ref} label={props.label} onChange={handleChange} />;
});

export default FormikPhoneNumberInput;
