import React, { FC, useLayoutEffect, useRef } from 'react';

export interface TextFitProps {
  children: React.ReactNode;
}

const TextFit: FC<TextFitProps> = ({ children }) => {
  const ref = useRef<HTMLSpanElement>(null);

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const originalContent = ref.current.innerHTML;
    const originalWidth = ref.current.offsetWidth;
    const originalFontSize = window.getComputedStyle(ref.current).fontSize;

    ref.current.innerHTML = '';

    const minWidth = ref.current.offsetWidth;

    ref.current.innerHTML = originalContent;
    ref.current.style.fontSize = `${Math.min(minWidth / originalWidth, 1) * parseFloat(originalFontSize)}px`;
  });

  return (
    <span ref={ref} style={{ width: '100%' }}>
      {children}
    </span>
  );
};

export default TextFit;
