import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SkeletonTheme } from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

import store, { persistor } from 'store/store';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import SvgIconProvider from 'components/SvgIcons/SvgIconProvider';

import Routing from './views/Routing';

import './i18n';

window.version = process.env.REACT_APP_VERSION;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => (
  <ErrorBoundary>
    <Suspense fallback="Loading...">
      <QueryClientProvider client={queryClient}>
        <SkeletonTheme highlightColor="rgba(172, 172, 172, 0.2)" baseColor="rgba(172, 172, 172, 0.1)">
          <SvgIconProvider>
            <ReduxProvider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                  <Routing />
                </BrowserRouter>
              </PersistGate>
            </ReduxProvider>
          </SvgIconProvider>
        </SkeletonTheme>
      </QueryClientProvider>
    </Suspense>
  </ErrorBoundary>
);

export default App;
