import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import useUpdatedRef from '@restart/hooks/useUpdatedRef';

import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import { useDebouncedAsyncCallback } from 'hooks/useDebouncedAsyncCallback';
import AutoSaveContext from './AutoSaveWatcher.context';

export interface AutoSaveWatcherProps<V> {
  delay?: number;
  manualSubmit?: (values: V) => Promise<void>;
  children: ReactNode;
  allowInvalidSubmission?: boolean;
}

const AutoSaveWatcher = <V,>({
  delay = 750,
  manualSubmit,
  children,
  allowInvalidSubmission,
}: AutoSaveWatcherProps<V>) => {
  const { submitForm, isValid, values, initialValues, isSubmitting } = useFormikContext<V>();

  const [loading, setLoading] = useState(false);
  const handleSubmit = useCallback(
    async (v: V) => {
      const submit = manualSubmit ?? submitForm;

      try {
        setLoading(true);
        await submit(v);
      } finally {
        setLoading(false);
      }
    },
    [manualSubmit, submitForm],
  );
  const debouncedSubmit = useDebouncedAsyncCallback(handleSubmit, delay);

  const previousValues = useRef(initialValues);
  const previousInitialValues = useRef(initialValues);

  const isSubmittingRef = useUpdatedRef(isSubmitting);
  const refIsValid = useUpdatedRef(isValid || allowInvalidSubmission);
  const refDebouncedSubmit = useUpdatedRef(debouncedSubmit);

  useEffect(() => {
    if (isSubmittingRef.current) {
      return;
    }

    // If initial values do not equal previous initial values, it means form-reinitialize happened
    if (!isEqual(initialValues, previousInitialValues.current)) {
      previousValues.current = initialValues;
      previousInitialValues.current = initialValues;

      return;
    }

    if (refIsValid.current && !isEqual(previousValues.current, values)) {
      refDebouncedSubmit.current(values).then(() => {
        previousValues.current = values;
      });
    }
  }, [refIsValid, refDebouncedSubmit, values]);

  return <AutoSaveContext.Provider value={{ loading }}>{children}</AutoSaveContext.Provider>;
};

export default AutoSaveWatcher;
