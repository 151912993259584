import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useDispatch } from 'store';
import { register } from 'services/auth';
import { FormikHelpers } from 'formik';
import FormSubmitError from 'errors/FormSubmitError';
import { openModal } from 'store/modals';
import useGReCaptcha from 'hooks/useGReCaptcha';
import { PhoneNumberEntry } from 'components/forms/raw/PhoneNumberInput';

export interface RegisterFormData {
  organizationName: string;
  organizationAddress: string;
  organizationUrl: string;
  country: {
    label: string;
    value: number | string;
  } | null;
  name: string;
  email: string;
  title: string;
  phone: PhoneNumberEntry;
  terms: boolean;
}

const useRegisterForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const runCaptchaChallenge = useGReCaptcha();

  const schema = useMemo(
    () =>
      yup.object().shape({
        organizationName: yup.string().required(t('common.form.validation.required')),
        organizationAddress: yup.string().required(t('common.form.validation.required')),
        organizationUrl: yup
          .string()
          .min(1, t('errors.minLength', { count: 1 }))
          .when({
            is: 'N/A',
            then: yup.string().nullable(),
            otherwise: yup
              .string()
              .matches(/^(((http)s?\:)?\/\/)?([a-z0-9][\w\.\-]+\.)+([a-zA-Z0-9]){2,}(\/.*)?$/, t('errors.urlOrNa'))
              .required(t('errors.minLength', { count: 1 })),
          }),
        country: yup.object().nullable().required(t('common.form.validation.required')),
        email: yup.string().email(t('common.form.validation.email')).required(t('common.form.validation.required')),
        name: yup.string().required(t('common.form.validation.required')),
        title: yup.string().required(t('common.form.validation.required')),
        terms: yup
          .boolean()
          .isTrue(t('common.form.validation.required'))
          .required(t('common.form.validation.required')),
      }),
    [t],
  );

  const initialValues = useMemo(
    (): RegisterFormData => ({
      organizationName: '',
      organizationAddress: '',
      organizationUrl: '',
      country: null,
      name: '',
      email: '',
      title: '',
      phone: {
        prefix: '',
        number: '',
      },
      terms: false,
    }),
    [],
  );

  const onSubmit = useCallback(
    async (values: RegisterFormData, helpers: FormikHelpers<RegisterFormData>) => {
      try {
        const token = await runCaptchaChallenge();
        const { data } = await register({
          ...values,
          phone: values.phone.number !== '' ? values.phone.prefix + ' ' + values.phone.number : '',
          country: values.country?.value ?? null,
          token,
        });

        if (data.error) {
          helpers.setFieldError('terms', data.error_message);
          return;
        }

        dispatch(
          openModal({
            modal: 'registrationSuccessModal',
            data: { ...values },
          }),
        );
      } catch (e) {
        if (FormSubmitError.isInstance(e)) {
          helpers.setFieldError('terms', (e as FormSubmitError).message);
        } else {
          console.error(e);
        }
      }
    },
    [dispatch],
  );

  return {
    schema,
    initialValues,
    onSubmit,
  } as const;
};

export default useRegisterForm;
