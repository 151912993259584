import React, { FC } from 'react';
import FormikCheckboxInput from 'components/forms/formik/FormikCheckboxInput/FormikCheckboxInput';

export interface SelectionQuestionProps {
  disabled?: boolean;
  questionValues: string;
  name: string;
}

const CheckboxQuestion: FC<SelectionQuestionProps> = ({ disabled, questionValues, name }) => (
  <>
    {questionValues.split(';').map((value: string) => (
      <FormikCheckboxInput name={`${name}.${window.btoa(value)}`} label={value} disabled={disabled} key={value} />
    ))}
  </>
);

export default CheckboxQuestion;
