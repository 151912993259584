import React, { FC } from 'react';

import TraroElement from 'types/api/TraroElement';
import RFPElementHistoryEntry from 'types/api/RFPElementHistoryEntry';

import ScoringEntry from './components/ScoringEntry/ScoringEntry';
import SubmissionEntry from './components/SubmissionEntry/SubmissionEntry';
import CommentEntry from './components/CommentEntry/CommentEntry';

export interface HistoryEntryProps {
  entry: RFPElementHistoryEntry;
  traroElement: TraroElement;
}

const HistoryEntry: FC<HistoryEntryProps> = ({ entry, traroElement }) => {
  switch (entry.type) {
    case 'scoring':
      return <ScoringEntry entry={entry} traroElement={traroElement} />;
    case 'submission':
      return <SubmissionEntry entry={entry} />;
    case 'comment':
      return <CommentEntry entry={entry} />;
    default:
      return <>Unknown</>;
  }
};

export default HistoryEntry;
