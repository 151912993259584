import React, { FC, useCallback, useState } from 'react';

import BuiltOnEnkrateia from 'components/BuiltOnEnkrateia/BuiltOnEnkrateia';
import PoweredBy from 'components/PoweredBy/PoweredBy';
import Footer from 'components/Footer/Footer';

import './SideBar.scss';
import { DoubleChevronRight } from '../SvgIcons';

export interface SideBarProps {
  children: React.ReactNode;
  variant?: 'traro' | 'kliparo';
  collapsible?: boolean;
  className?: string;
}

const SideBar: FC<SideBarProps> = ({ children, variant = 'kliparo', collapsible = false, className = '' }) => {
  const [open, setOpen] = useState(true);
  const toggleOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return (
    <aside className={`enkrateia-sidebar variant-${variant} ${className} ${open ? 'open' : 'closed'}`}>
      {open && (
        <>
          {children}
          <div className="flex-grower" />
          <div className="footnote">
            <BuiltOnEnkrateia />
            <PoweredBy />
            <Footer />
          </div>
        </>
      )}
      {collapsible && (
        <button onClick={toggleOpen} type="button" className="enkrateia-sidebar-collapse-button">
          <DoubleChevronRight color="white" />
        </button>
      )}
    </aside>
  );
};

export default SideBar;
