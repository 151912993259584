import { useCallback, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'store';
import { updateRfpTraroBrick } from 'store/rfpResponse';
import { isCompleted, traroResponseListToObject } from 'store/rfpResponse/helpers';

import QuestionStatus from 'types/QuestionStatus';
import ArchElementSpecific from 'types/ArchElementSpecific';

const useBrickControl = (brick: ArchElementSpecific) => {
  const dispatch = useDispatch();

  const rfpId = useSelector((state) => state.rfp.data.id);
  const traroElementResponseList = useSelector((store) => store.rfpResponse.data.traro_element_response_list);
  const rfpResponseId = useSelector((store) => store.rfpResponse.data.id);

  const [brickResponse, completed] = useMemo(() => {
    const response = traroResponseListToObject(traroElementResponseList)[brick];

    return [response, isCompleted(response)];
  }, [traroElementResponseList, brick]);

  const [loading, setLoading] = useState(false);

  const toggleBrickStatus = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(
        updateRfpTraroBrick({
          archElementSpecific: brick,
          questionStatus: completed ? QuestionStatus.BEGIN : QuestionStatus.DONE,
          rfpResponseId,
          rfpId,
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [rfpId, rfpResponseId, completed, brick, dispatch]);

  return [brickResponse, toggleBrickStatus, loading, completed] as const;
};

export default useBrickControl;
