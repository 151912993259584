import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import FocusTrap, { Props as FocusTrapProps } from 'focus-trap-react';
import * as PopperJS from '@popperjs/core';

export interface PopperWithFocusTrapProps {
  children: React.ReactNode;
  referenceElement: Element | PopperJS.VirtualElement | null;
  open: boolean;
  popperOptions?: Parameters<typeof usePopper>[2];
  focusTrapOptions?: FocusTrapProps['focusTrapOptions'];
  zIndex?: number;
  popperElementClassName?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const DEFAULT_POPPER_OPTIONS: Parameters<typeof usePopper>[2] = {
  placement: 'bottom-start',
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const DEFAULT_FOCUS_TRAP_OPTIONS: FocusTrapProps['focusTrapOptions'] = {
  initialFocus: false,
};

const PopperWithFocusTrap = ({
  children,
  referenceElement,
  open,
  popperOptions = DEFAULT_POPPER_OPTIONS,
  focusTrapOptions = DEFAULT_FOCUS_TRAP_OPTIONS,
  zIndex = 1000,
  popperElementClassName = '',
}: PopperWithFocusTrapProps) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const popper = usePopper(referenceElement, popperElement, popperOptions);

  return (
    <FocusTrap active={open} focusTrapOptions={focusTrapOptions}>
      <div>
        {open && (
          <div
            tabIndex={-1}
            style={{
              ...popper.styles.popper,
              zIndex,
            }}
            {...popper.attributes.popper}
            className={popperElementClassName}
            ref={setPopperElement}
            role="dialog"
          >
            {children}
          </div>
        )}
      </div>
    </FocusTrap>
  );
};

export default PopperWithFocusTrap;
