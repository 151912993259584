import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal/Modal';
import TraroElement from 'types/api/TraroElement';
import historyIcon from 'assets/img/historyIcon.svg';

import './History.scss';
import HistoryModal from './components/HistoryModal/HistoryModal';

export interface HistoryProps {
  elementId?: number;
  traroElement?: TraroElement;
}

const History: FC<HistoryProps> = ({ elementId, traroElement }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  if (!elementId || !traroElement) {
    return null;
  }

  return (
    <div className="enkrateia-element-history">
      <button className="history-button" type="button" onClick={() => setOpen(true)}>
        <img src={historyIcon} alt="history" />
        {t('pages.traroElement.history')}
      </button>
      <Modal isOpen={open} onRequestClose={() => setOpen(false)} className="enkrateia-element-history-modal">
        <HistoryModal elementId={elementId} traroElement={traroElement} />
      </Modal>
    </div>
  );
};

export default History;
