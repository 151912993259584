import React, { FC } from 'react';
import TraroElement from 'types/api/TraroElement';

import './BrickPanel.scss';

import emptyCircleIconLight from 'assets/img/emptyCircleIconLight.svg';
import checkmarkCircleIcon from 'assets/img/checkmarkCircleIcon.svg';

import useBrickControl from 'hooks/useBrickControl/useBrickControl';
import Loader from 'components/Loader/Loader';

export interface BrickPanelProps {
  brick: TraroElement;
  interactive?: boolean;
}

const BrickPanel: FC<BrickPanelProps> = ({ brick, interactive }) => {
  const [, toggleBrickStatus, loading, completed] = useBrickControl(brick.arch_element_specific);

  return (
    <button
      className={`enkrateia-brick-panel ${interactive ? 'interactive' : ''}`}
      onClick={interactive ? toggleBrickStatus : () => null}
    >
      {interactive && (
        <>
          {!loading && <img src={completed ? checkmarkCircleIcon : emptyCircleIconLight} alt="status" />}
          {loading && <Loader />}
        </>
      )}
      <span className="content">
        <span className="number">{brick.arch_element_specific.substring(5, 7).padStart(2, '0')}</span>
        <span className="description">{brick.element_name}</span>
      </span>
    </button>
  );
};

export default BrickPanel;
