import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { ExternalLinkIcon } from '../SvgIcons';

import './LinkWithIcon.scss';

export interface LinkWithIconProps extends LinkProps {
  color: string;
}

const LinkWithIcon: FC<LinkWithIconProps> = ({ children, color, className = '', ...props }) => (
  <Link {...props} className={`enkrateia-link-with-icon ${className}`}>
    <ExternalLinkIcon color={color} />
    <span style={{ color }}>{children}</span>
  </Link>
);

export default LinkWithIcon;
