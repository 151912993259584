import {
  ChangeEvent,
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  RefObject,
  useCallback,
  useLayoutEffect,
  useRef,
} from 'react';

import useRequiredId from 'hooks/useRequiredId';
import Tooltip from 'components/Tooltip/Tooltip';
import { HelpIcon } from 'components/SvgIcons';

import './TextAreaInput.scss';

type Variant = 'standard' | 'filled' | 'raw';

export interface TextAreaInputProps
  extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>, 'onChange'> {
  label?: string;
  error?: string | ReactNode;
  hint?: string | ReactNode;
  touched?: boolean;
  fullWidth?: boolean;
  rows?: number;
  variant?: Variant;
  required?: boolean;
  value: string;
  onChange: (value: string, event: ChangeEvent<HTMLTextAreaElement>) => void;
  autoHeight?: boolean;
  maxHeight?: string;
}

const TextAreaInput = forwardRef<HTMLTextAreaElement, TextAreaInputProps>(
  (
    {
      id,
      label,
      error,
      touched = false,
      required = false,
      fullWidth = false,
      className = '',
      hint,
      variant = 'standard',
      onChange,
      maxHeight,
      autoHeight,
      ...props
    },
    ref,
  ) => {
    const ensuredId = useRequiredId(id);
    const errorId = `${ensuredId}-error`;
    const internalRef = useRef<HTMLTextAreaElement>(null);

    const ensuredRef = (ref || internalRef) as RefObject<HTMLTextAreaElement>;

    const hasError = Boolean(error && error !== '' && touched);
    const internalOnChange = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        onChange(event.target.value, event);
      },
      [onChange],
    );

    const onInput = useCallback(() => {
      if (autoHeight && ensuredRef.current) {
        ensuredRef.current.style.height = 'auto';
        ensuredRef.current.style.height = `${ensuredRef.current.scrollHeight + 2}px`;
      }
    }, [ensuredRef, autoHeight]);

    useLayoutEffect(() => {
      onInput();
    }, []);

    return (
      <div className={`enkrateia-textarea-input ${className} variant-${variant} ${fullWidth ? 'full-width' : ''}`}>
        {label && (
          <label htmlFor={ensuredId}>
            {label}
            {required && '*'}
            {hint && (
              <Tooltip tooltip={hint}>
                <HelpIcon color="#2D2C64" size={25} />
              </Tooltip>
            )}
          </label>
        )}
        <div>
          <textarea
            {...props}
            onChange={internalOnChange}
            onInput={onInput}
            aria-invalid={hasError}
            aria-errormessage={errorId}
            id={ensuredId}
            ref={ensuredRef}
            style={{ maxHeight }}
          />
          {hasError && (
            <span id={errorId} className="error-label">
              {error}
            </span>
          )}
        </div>
      </div>
    );
  },
);

export default TextAreaInput;
