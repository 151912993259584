import React, { FC } from 'react';

import emptyCircleIcon from 'assets/img/emptyCircleIcon.svg';
import checkmarkCircleIcon from 'assets/img/checkmarkCircleIcon.svg';

import ArchElementSpecific from 'types/ArchElementSpecific';
import Loader from 'components/Loader/Loader';
import useBrickControl from 'hooks/useBrickControl/useBrickControl';

import { useArchContextForElement } from '../../../../context/ArchContext';

import './BestPracticeBrick.scss';

export interface BestPracticeBrickProps {
  brick: ArchElementSpecific;
}

const BestPracticeBrick: FC<BestPracticeBrickProps> = ({ brick }) => {
  const [, toggleBrickStatus, loading, completed] = useBrickControl(brick);

  const { element } = useArchContextForElement(brick);

  if (element.id === undefined || element.id === null) {
    return null;
  }

  return (
    <button type="button" className="enkrateia-best-practice-brick" onClick={toggleBrickStatus} disabled={loading}>
      {!loading && <img src={completed ? checkmarkCircleIcon : emptyCircleIcon} alt="status" />}
      {loading && <Loader />}
      <span className="brick-number">{brick.substring(5, 7).padStart(2, '0')}</span>
      <span className="element-name">{element.element_name}</span>
    </button>
  );
};

export default BestPracticeBrick;
