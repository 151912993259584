import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import SubmitButton from 'components/SubmitButton/SubmitButton';

import { TicketCommentFormState } from '../../hooks/useInitialValues';

const AddTicketButton = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<TicketCommentFormState>();

  const variant = values.published ? 'black-filled' : 'primary';
  const status = values.published ? 'enabled' : 'disabled';

  return (
    <SubmitButton variant={variant}>{t(`pages.ticketDetails.publishedComment.enterComment.${status}`)}</SubmitButton>
  );
};

export default AddTicketButton;
