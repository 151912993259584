import React, { FC } from 'react';
import { useSelector } from 'store';
import { useTranslation } from 'react-i18next';

import useUser from 'hooks/useUser';
import SideBar from 'components/SideBar/SideBar';
import GoBackPanel from 'components/GoBackPanel/GoBackPanel';
import Scoring from 'components/Scoring/Scoring';

import ProjectInfo from './components/ProjectInfo/ProjectInfo';
import CompleteReview from './components/CompleteReview/CompleteReview';
import SubmitForReview from './components/SubmitForReview/SubmitForReview';

export interface TraroSideBarProps {}

const TraroSideBar: FC<TraroSideBarProps> = () => {
  const { t } = useTranslation();
  const { isReviewer } = useUser();

  const scoringRubric = useSelector((store) => store.rfp.data.scoring_rubric);
  const maxScore = useSelector((store) => store.rfp.data.traro_element_list.reduce((acc, te) => acc + te.max_score, 0));
  const score = useSelector((store) =>
    store.rfpResponse.data.traro_element_response_list.reduce((acc, ter) => acc + (ter.question_score ?? 0), 0),
  );

  return (
    <SideBar variant="traro">
      {isReviewer && <GoBackPanel target="/dashboard" label={t('pages.projectOverview.sidePanel.goBackLabel')} />}
      <ProjectInfo />
      <Scoring score={score} maxScore={maxScore} scoringRubric={scoringRubric} />
      {isReviewer ? <CompleteReview /> : <SubmitForReview />}
    </SideBar>
  );
};

export default TraroSideBar;
