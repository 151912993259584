import React, { FC, ReactNode } from 'react';

import flagIcon from 'assets/img/flagIcon.svg';

import './Note.scss';

export interface NoteProps {
  children: ReactNode | ReactNode[];
  variant?: 'traro' | 'kliparo';
}

const Note: FC<NoteProps> = ({ children, variant = 'traro' }) => {
  const [firstChild, ...restOfChildren] = Array.isArray(children) ? children : [children];

  return (
    <div className={`enkrateia-note variant-${variant}`}>
      <div>
        <img src={flagIcon} alt="note" />
        {firstChild}
      </div>
      {restOfChildren}
    </div>
  );
};

export default Note;
