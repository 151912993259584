import { useMemo } from 'react';
import TicketDto from 'types/api/TicketDto';
import { DateTime } from 'luxon';

import useUser from 'hooks/useUser';
import TicketStatus from 'types/TicketStatus';

import { Variant } from '../components/TicketSummary/TicketSummary';

const useSummary = (data: TicketDto[] | undefined) => {
  const { id } = useUser();

  return useMemo(() => {
    const ticketSummary: Record<Variant, number> = {
      open: 0,
      assigned: 0,
      closed: 0,
      past: 0,
    };

    if (data === undefined) {
      return ticketSummary;
    }

    const now = DateTime.now();

    for (const ticket of data) {
      if (ticket.ticket_status === TicketStatus.OPEN) {
        ++ticketSummary.open;

        // @ts-ignore
        if (ticket.assigned_user === id) {
          ++ticketSummary.assigned;
        }

        if (now > DateTime.fromISO(ticket.due_date)) {
          ++ticketSummary.past;
        }
      } else {
        ++ticketSummary.closed;
      }
    }

    return ticketSummary;
  }, [id, data]);
};

export default useSummary;
