import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const useSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    ticketComment: yup.string().required(t('common.form.validation.required')),
  });
};

export default useSchema;
