import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal/Modal';
import FormikSelectInput from 'components/forms/formik/FormikSelectInput/FormikSelectInput';
import LoaderOverlay from 'components/LoaderOverlay/LoaderOverlay';
import useEligibilityOptions from 'hooks/useEligibilityOptions';

import { useSelector } from 'store';
import { Form, Formik } from 'formik';

import useInitialValues, { ChangeAssessmentStateModalFormValues } from './hooks/useInitialValues';
import useSchema from './hooks/useSchema';

import './ChangeAssessmentStateModal.scss';

interface ChangeAssessmentStateModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: (values: ChangeAssessmentStateModalFormValues) => void;
  submitButton: React.ReactNode;
  title: string;
  isEligibilityRequired?: boolean;
}

const ChangeAssessmentStateModal = ({
  isOpen,
  onRequestClose,
  onSubmit,
  submitButton,
  title,
  isEligibilityRequired,
}: ChangeAssessmentStateModalProps) => {
  const { t } = useTranslation();

  const isLoading = useSelector((store) => store.rfpResponse.updating);
  const organization = useSelector((store) => store.rfpResponse.data.organization.name);
  const programTitle = useSelector((store) => store.rfpResponse.data.project_name);
  const assessmentType = useSelector((store) => store.rfp.data.name);

  const options = useEligibilityOptions();
  const initialValues = useInitialValues(options);
  const schema = useSchema(isEligibilityRequired);

  return (
    <Modal variant="kliparo" title={title} isOpen={isOpen} onRequestClose={onRequestClose}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
        <Form className="enkrateia-change-assessment-state-modal">
          <div className="elements">
            <span>{t('pages.projectOverview.mainPanel.changeAssessmentStatusModal.organization')}</span>
            <span>{organization}</span>
            <span>{t('pages.projectOverview.mainPanel.changeAssessmentStatusModal.programTitle')}</span>
            <span>{programTitle}</span>
            <span>{t('pages.projectOverview.mainPanel.changeAssessmentStatusModal.assessmentType')}</span>
            <span>{assessmentType}</span>
            <label>{t('pages.projectOverview.mainPanel.changeAssessmentStatusModal.programEligibility')}</label>
            <FormikSelectInput name="programEligibility" options={options} variant="filled" fullWidth />
          </div>
          {submitButton}
          <LoaderOverlay open={isLoading} />
        </Form>
      </Formik>
    </Modal>
  );
};

export default ChangeAssessmentStateModal;
