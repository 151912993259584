import { useQuery } from '@tanstack/react-query';

import FormSubmitError from 'errors/FormSubmitError';
import API from 'services/api';
import RFPResponseWithRFP from 'types/api/RFPResponseWithRFP';

export const fetchRfpResponse = async (responseId: number | undefined) => {
  try {
    if (responseId === undefined) {
      return null;
    }

    return (await API.get<RFPResponseWithRFP>('/rfp-response/' + responseId)).data;
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

const getQueryKey = (id?: number | undefined) => {
  const keys: unknown[] = ['rfpResponse'];

  if (id !== undefined) {
    keys.push(id);
  }

  return keys;
};

const useRfpResponse = (id: number | undefined) => useQuery(getQueryKey(id), () => fetchRfpResponse(id));

useRfpResponse.getQueryKey = getQueryKey;
useRfpResponse.queries = {
  fetchRfpResponse,
} as const;

export default useRfpResponse;
