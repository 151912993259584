import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentOrganizationUsers } from 'api';
import { Table } from 'components/Table';

import useColumns from './hooks/useColumns';

import './UserDetails.scss';

const UserDetails = () => {
  const { data } = useCurrentOrganizationUsers();
  const columns = useColumns();
  const { t } = useTranslation();

  return (
    <section className="enkrateia-organization-page-user-details">
      <h2>{t('pages.organizationProfile.users.title')}</h2>
      <Table data={data} columns={columns} />
    </section>
  );
};

export default UserDetails;
