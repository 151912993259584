import { useParams } from 'react-router-dom';
import { useSelector } from 'store';
import { getDefaultResponseElement } from 'store/rfpResponse/helpers';
import TraroElementResponse from 'types/api/TraroElementResponse';
import ArchElementSpecific from 'types/ArchElementSpecific';

const getTraroElement = (elements: TraroElementResponse[], traroElementSpecific: ArchElementSpecific) => {
  const traroElementResponse = elements.find((element) => element.arch_element_specific === traroElementSpecific);

  if (traroElementResponse !== undefined) {
    return traroElementResponse;
  }

  return getDefaultResponseElement(null, traroElementSpecific);
};

const useTraroElement = () => {
  const { traroElementSpecific } = useParams<{ traroElementSpecific: string }>();

  const { id: rfpId, short_name: shortName } = useSelector((store) => store.rfp.data);

  const traroElementList = useSelector((store) => store.rfp.data.traro_element_list);
  const { traro_element_response_list: traroElementResponseList, id: rfpResponseId } = useSelector(
    (store) => store.rfpResponse.data,
  );

  const traroElement = traroElementList.find((element) => element.arch_element_specific === traroElementSpecific);
  const traroElementResponse = getTraroElement(traroElementResponseList, traroElementSpecific as ArchElementSpecific);

  if (traroElement === undefined || rfpId === undefined) {
    throw new Error('No data found for traro element: ' + traroElementSpecific);
  }

  return {
    rfpId,
    rfpResponseId,
    traroElementSpecific: traroElement.arch_element_specific,
    traroElement,
    traroElementResponse,
    shortName,
  } as const;
};

export default useTraroElement;
