import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

import TraroElementResponse from 'types/api/TraroElementResponse';
import QuestionStatus from 'types/QuestionStatus';
import { useSelector } from 'store';
import useEligibilityOptions from 'hooks/useEligibilityOptions';

import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import FormikSelectInput from 'components/forms/formik/FormikSelectInput/FormikSelectInput';
import LoaderOverlay from 'components/LoaderOverlay/LoaderOverlay';
import TraroElement from 'types/api/TraroElement';

import useInitialValues from './hooks/useInitialValues';
import useSchema from './hooks/useSchema';
import useOnSubmit from './hooks/useOnSubmit';
import QuestionStatusSwitchButton from './components/QuestionStatusSwitchButton/QuestionStatusSwitchButton';
import QuestionStatusSwitchError from './components/QuestionStatusSwitchError/QuestionStatusSwitchError';

import './UpdateResponseStatusAction.scss';

export interface UpdateResponseStatusActionProps {
  rfpElementResponse: TraroElementResponse;
  traroElement: TraroElement;
}

const UpdateResponseStatusAction = ({ rfpElementResponse, traroElement }: UpdateResponseStatusActionProps) => {
  if (rfpElementResponse.id === undefined) {
    throw new Error('Cannot update response status for an element without an id');
  }

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const isLoading = useSelector((state) => state.rfpResponse.updating);

  const options = useEligibilityOptions(traroElement.id);
  const initialValues = useInitialValues(options, rfpElementResponse);
  const schema = useSchema();
  const onSubmit = useOnSubmit(rfpElementResponse.id, setIsOpen);

  return (
    <>
      <Button variant="tertiary" type="button" onClick={() => setIsOpen(true)}>
        {t('pages.traroElement.updateResponseStatus.button')}
      </Button>
      <Modal
        variant="kliparo"
        title={t('pages.traroElement.updateResponseStatus.modalTitle')}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
      >
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
          <Form className="enkrateia-update-response-status-action-modal">
            <div className="form-element-container">
              <FormikSelectInput isClearable name="programEligibility" options={options} variant="filled" fullWidth />
            </div>
            <div className="modal-title">
              <h2>{t('pages.traroElement.updateResponseStatus.secondPartTitle')}</h2>
            </div>
            <div className="form-element-container">
              <QuestionStatusSwitchButton status={QuestionStatus.REVIEW_COMPLETE} />
              <QuestionStatusSwitchButton status={QuestionStatus.UPDATE_REQUIRED} />
              <QuestionStatusSwitchButton status={QuestionStatus.NOT_REQUIRED} />
              <QuestionStatusSwitchButton status={QuestionStatus.REVIEW_ONLY} />
            </div>
            <QuestionStatusSwitchError />
            <Button variant="primary" type="submit" className="confirm">
              {t('pages.traroElement.updateResponseStatus.confirm')}
            </Button>
            <LoaderOverlay open={isLoading} />
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default UpdateResponseStatusAction;
