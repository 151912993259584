import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import UserDto from 'types/api/UserDto';
import TicketDto from 'types/api/TicketDto';
import TicketStatus from 'types/TicketStatus';
import useUser from 'hooks/useUser';
import Button from 'components/Button/Button';
import { InputOption } from 'components/forms/raw/SelectInput';

import { TicketFormState } from '../../hooks/useInitialValues';

import ChangeTicketStatusButton from './components/ChangeTicketStatusButton/ChangeTicketStatusButton';
import RequestTicketCloseButton from './components/RequestTicketCloseButton/RequestTicketCloseButton';

import './TicketActions.scss';

export interface TicketActionsProps {
  selectableUsers: InputOption<number, UserDto>[];
  ticket: TicketDto;
  disabled?: boolean;
  isLoading: boolean;
}

const TicketActions = ({ selectableUsers, ticket, disabled, isLoading }: TicketActionsProps) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext<TicketFormState>();
  const { isReviewer } = useUser();

  const {
    assignToICTeam,
    assignToPreviousCommenter,
    assignToTicketCreator,
    assignToAssuranceOfficer,
    assignToProgramAdmin,
  } = useMemo(
    () => ({
      assignToICTeam: () => {
        const ICTeamUser = selectableUsers.find((user) => user.meta.user_title === 'Support Team');

        setFieldValue('assignedTo', ICTeamUser);
      },
      assignToPreviousCommenter: () => {
        const previousCommenterUser = ticket.ticket_comment_list[ticket.ticket_comment_list.length - 1].commenter;
        const previousCommenter = selectableUsers.find((user) => user.value === previousCommenterUser.id);

        setFieldValue('assignedTo', previousCommenter);
      },
      assignToTicketCreator: () => {
        const firstCommenterUser = ticket.ticket_comment_list[0].commenter;
        const firstCommenter = selectableUsers.find((user) => user.value === firstCommenterUser.id);

        setFieldValue('assignedTo', firstCommenter);
      },
      assignToAssuranceOfficer: () => {
        const assuranceOffice = selectableUsers.find((user) => user.meta.user_role === 'Assurance Officer');

        setFieldValue('assignedTo', assuranceOffice);
      },
      assignToProgramAdmin: () => {
        const programAdmin = selectableUsers.find((user) => user.meta.user_role === 'Organization Admin');

        setFieldValue('assignedTo', programAdmin);
      },
    }),
    [selectableUsers, setFieldValue, ticket],
  );

  return (
    <div className="enkrateia-ticket-actions">
      <h2>{t('common.tickets.actions')}</h2>
      <section>
        <Button variant="tertiary-filled" onClick={assignToICTeam} disabled={disabled}>
          {isReviewer ? t('common.tickets.assignToExecSec') : t('common.tickets.assignToICTeam')}
        </Button>
        <Button variant="black" onClick={assignToPreviousCommenter} disabled={disabled}>
          {t('common.tickets.assignToPreviousCommenter')}
        </Button>
        <Button variant="black" onClick={assignToTicketCreator} disabled={disabled}>
          {t('common.tickets.assignToTicketCreator')}
        </Button>
      </section>
      <section>
        {isReviewer && (
          <Button variant="black" onClick={assignToAssuranceOfficer} disabled={disabled}>
            {t('common.tickets.assignToAssuranceOfficer')}
          </Button>
        )}
        {isReviewer && (
          <Button variant="black" onClick={assignToProgramAdmin} disabled={disabled}>
            {t('common.tickets.assignToProgramAdmin')}
          </Button>
        )}
        {(isReviewer || ticket.ticket_status === TicketStatus.CLOSED) && (
          <ChangeTicketStatusButton ticket={ticket} disabled={isLoading} />
        )}
        {!isReviewer && ticket.ticket_status === TicketStatus.OPEN && (
          <RequestTicketCloseButton ticket={ticket} disabled={isLoading} selectableUsers={selectableUsers} />
        )}
      </section>
    </div>
  );
};

export default TicketActions;
