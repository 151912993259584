import { useEffect, useMemo, useState } from 'react';
import useUser from '../useUser';

const useUserPersistedState = <T>(id: string, defaultValue: T) => {
  const user = useUser();
  const key = useMemo(() => `enkrateia.${user?.id}:${id}`, [user?.id, id]);

  const rawStoredItem = localStorage.getItem(key);
  const initialValue = rawStoredItem !== null ? JSON.parse(rawStoredItem) : defaultValue;

  const [state, setState] = useState<T>(initialValue);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);

  return [state, setState] as const;
};

export default useUserPersistedState;
