import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useSchema = () => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      yup.object().shape({
        checkbox: yup.boolean().required(t('errors.required')).isTrue(t('errors.required')),
        iHaveReadTerms: yup.boolean().required(t('errors.required')).isTrue(t('errors.required')),
      }),
    [t],
  );
};

export default useSchema;
