import QuestionType from 'types/QuestionType';

import CheckboxQuestion from './CheckboxQuestion/CheckboxQuestion';
import SelectionQuestion from './SelectionQuestion/SelectionQuestion';
import TextAreaQuestion from './TextAreaQuestion/TextAreaQuestion';
import TextQuestion from './TextQuestion/TextQuestion';
import UrlQuestion from './UrlQuestion/UrlQuestion';

const getComponent = (questionType: QuestionType) => {
  switch (questionType) {
    case QuestionType.TEXT:
      return TextQuestion;
    case QuestionType.LONG_TEXT:
      return TextAreaQuestion;
    case QuestionType.CHECKBOX:
      return CheckboxQuestion;
    case QuestionType.SELECTION:
      return SelectionQuestion;
    case QuestionType.URL:
      return UrlQuestion;
    case QuestionType.DISPLAY_ONLY:
      return () => null;
    default:
      throw new Error('Invalid question type: ' + questionType);
  }
};

export default getComponent;
