import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from 'components/Loader/Loader';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import { useSelector } from 'store';
import useUser from 'hooks/useUser';
import QuestionStatus from 'types/QuestionStatus';
import RFPResponseStatus from 'types/RFPResponseStatus';

import './Submit.scss';

export interface SubmitProps {
  questionStatus: QuestionStatus;
}

const Submit: FC<SubmitProps> = ({ questionStatus }) => {
  const { t } = useTranslation();
  const { isReviewer } = useUser();

  const updating = useSelector((store) => store.rfpResponse.updating);
  const { rfp_response_status } = useSelector((store) => store.rfpResponse.data);

  const label = isReviewer ? t('pages.traroElement.saveScore') : t('pages.traroElement.saveResponse');

  const disabled = useMemo(() => {
    if (updating) {
      return true;
    }

    if (isReviewer) {
      return rfp_response_status !== RFPResponseStatus.IN_REVIEW;
    }

    return questionStatus === QuestionStatus.REVIEW_ONLY;
  }, [isReviewer, rfp_response_status, questionStatus, updating]);

  return (
    <div className="enkrateia-submit">
      <SubmitButton disabled={disabled} variant="primary">
        {updating ? <Loader /> : label}
      </SubmitButton>
    </div>
  );
};

export default Submit;
