import { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import TraroElement from 'types/api/TraroElement';
import TraroElementResponse from 'types/api/TraroElementResponse';
import { useDispatch, useSelector } from 'store';
import { updateRfpElementResponse, updateRfpElementScore } from 'store/rfpResponse';
import { openModal } from 'store/modals';
import useUser from 'hooks/useUser';

export interface TraroElementForm {
  response: string;
  score: number;
}

const useTraroElementForm = (traroElement: TraroElement, traroElementResponse: TraroElementResponse) => {
  const rfpId = useSelector((state) => state.rfp.data.id);
  const rfpResponseId = useSelector((state) => state.rfpResponse.data.id);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isReviewer } = useUser();

  const initialValues = useMemo(
    () => ({ response: traroElementResponse.response_text ?? '', score: traroElementResponse.question_score ?? 0 }),
    [traroElementResponse],
  );

  const schema = useMemo(() => {
    let shape: Record<string, yup.AnySchema> = {};

    if (isReviewer) {
      shape.score = yup
        .number()
        .min(0, t('errors.minNumber', { count: 0 }))
        .max(traroElement.max_score, t('errors.maxNumber', { count: traroElement.max_score }))
        .required(t('errors.minNumber', { count: 0 }));
    } else {
      shape.response = yup
        .string()
        .min(1, t('errors.minLength', { count: 1 }))
        .max(1000, t('errors.maxLength', { count: 1000 }))
        .required(t('errors.minLength', { count: 1 }));
    }

    return yup.object().shape(shape);
  }, [t, isReviewer, traroElement.max_score]);

  const onSubmit = useCallback(
    async (values: TraroElementForm) => {
      try {
        const payload = {
          responseText: values.response,
          score: values.score,
          archElementSpecific: traroElementResponse.arch_element_specific,
          rfpId,
          rfpResponseId,
          elementId: traroElement.id as number,
        };

        if (isReviewer) {
          await dispatch(updateRfpElementScore(payload)).unwrap();
        } else {
          await dispatch(updateRfpElementResponse(payload)).unwrap();
        }

        dispatch(openModal({ modal: 'traroElementSubmitModal', data: undefined }));
      } catch {
        dispatch(
          openModal({
            modal: 'errorModal',
            data: {
              message: t('errors.errorSavingResponse'),
            },
          }),
        );
      }
    },
    [traroElementResponse.arch_element_specific, rfpResponseId, rfpId, traroElement.id, isReviewer, dispatch, t],
  );

  return {
    initialValues,
    schema,
    onSubmit,
  } as const;
};

export default useTraroElementForm;
