import React, { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import Button from 'components/Button/Button';
import Tooltip from 'components/Tooltip/Tooltip';
import { HelpIcon, UploadIcon } from 'components/SvgIcons';

import Display from './components/Display/Display';

import './FormikUploadAttachment.scss';
import CommonTransComponents from '../../../../i18n/CommonTransComponents';

interface UploadAttachmentProps {
  name: string;
}

const FormikUploadAttachment = ({ name }: UploadAttachmentProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  const { getFieldHelpers } = useFormikContext();

  const { setValue } = getFieldHelpers<File | null>(name);

  return (
    <div className="enkrateia-upload-attachment">
      <input
        type="file"
        name={name}
        hidden
        ref={ref}
        onInput={(event) => setValue(event.currentTarget.files?.item(0) ?? null)}
      />
      <Button variant="tertiary-thin" onClick={() => ref.current?.click()}>
        <UploadIcon size={25} color="#343892" />
        {t('pages.ticketDetails.attachments.button')}
      </Button>
      <Tooltip
        tooltip={
          <Trans i18nKey="pages.ticketDetails.attachments.help" components={CommonTransComponents}>
            pages.ticketDetails.attachments.help
          </Trans>
        }
      >
        <HelpIcon color="#2D2C64" size={25} />
      </Tooltip>
    </div>
  );
};

FormikUploadAttachment.Display = Display;

export default FormikUploadAttachment;
