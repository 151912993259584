import React from 'react';

import './EllipsisTextCell.scss';

export interface EllipsisTextCellProps {
  getValue: () => string;
  noFullText?: boolean;
}

const EllipsisTextCell = ({ getValue, noFullText }: EllipsisTextCellProps) => {
  const text = getValue();

  return (
    <span className={`enkrateia-ellipsis-text-cell ${noFullText ? 'no-full-text' : 'full-text'}`}>
      {text}
      {!noFullText && <span className="full-width">{text}</span>}
    </span>
  );
};

export default EllipsisTextCell;
