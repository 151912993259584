import { InputOption } from 'components/forms/raw/SelectInput';
import useUser from 'hooks/useUser';
import { useMemo } from 'react';
import RFP from 'types/api/RFP';

export interface NewAssessmentForm {
  organization: InputOption<number>;
  rfp: InputOption<number, RFP> | null;
  programName: string;
  page: 'details' | 'disclaimer' | 'success';
}

const useInitialValues = (): NewAssessmentForm => {
  const user = useUser();

  return useMemo(
    () => ({
      organization: {
        value: user.organization.id,
        label: user.organization.name,
      },
      rfp: null,
      programName: '',
      page: 'details',
    }),
    [user],
  );
};

export default useInitialValues;
