import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import IC from 'assets/img/IC.png';
import helpIcon from 'assets/img/helpIcon.svg';
import bellIcon from 'assets/img/bellIcon.svg';
import { GlassesIcon, HomeIcon } from 'components/SvgIcons';
import { useSelector } from 'store';

import UserDetails from './components/UserDetails/UserDetails';

import './Header.scss';

export interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  const { t } = useTranslation();
  const isLoggedIn = useSelector((store) => store.auth.isLoggedIn);

  return (
    <header className="enkrateia-header">
      <div className="logo">
        <img src={IC} alt={t('branding.icLogo')} />
        <span className="title"> {t('common.header.title')}</span>
      </div>
      <nav>
        {isLoggedIn && (
          <>
            <NavLink className="nav-element" to="/dashboard">
              <HomeIcon size={24} color="#2C2B63" />
              {t('common.header.home')}
            </NavLink>
            <NavLink className="nav-element" to="/tickets">
              <GlassesIcon size={28} color="#2C2B63" />
              {t('common.header.tickets')}
            </NavLink>
          </>
        )}
        <a
          className="nav-element"
          href={
            process.env.PUBLIC_URL + '/enkrateia_static/webapp/pdf/IC%20Assessment%20Platform%20-%20User%20Guide.pdf'
          }
          target="_blank"
          rel="noreferrer"
        >
          <img src={helpIcon} alt="Helps" />
          {t('common.header.userGuide')}
        </a>
        {isLoggedIn && (
          <>
            <div className="nav-element">
              <img src={bellIcon} alt="Bell" />
            </div>
            <UserDetails />
          </>
        )}
      </nav>
    </header>
  );
};

export default Header;
