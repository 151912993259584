import { useMemo } from 'react';

import { EligibilityOption } from 'hooks/useEligibilityOptions';
import TraroElementResponse from 'types/api/TraroElementResponse';
import QuestionStatus from 'types/QuestionStatus';

export interface UpdateResponseStatusFormValues {
  programEligibility: EligibilityOption | null;
  status: QuestionStatus;
}

const useInitialValues = (
  options: EligibilityOption[],
  rfpElementResponse: TraroElementResponse,
): UpdateResponseStatusFormValues => {
  return useMemo(() => {
    const score = rfpElementResponse.question_score;
    const status = rfpElementResponse.question_status;

    if (!score) {
      return { programEligibility: null, status };
    }

    return {
      programEligibility:
        options.find((option) => option.meta.low_score <= score && score <= option.meta.high_score) ?? null,
      status,
    };
  }, [rfpElementResponse, options]);
};

export default useInitialValues;
