import { useQuery } from '@tanstack/react-query';

import API from 'services/api';
import RFPResponseWithRFP from 'types/api/RFPResponseWithRFP';
import RFPResponseWithRFPAndStats from 'types/api/RFPResponseWithRFPAndStats';
import FormSubmitError from 'errors/FormSubmitError';

import useRfpResponsesStats from './useRfpResponsesStats';

const fetchRfpResponsesList = async () => {
  try {
    return await API.get<RFPResponseWithRFP[]>('/rfp-responses-list/');
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

const fetchRfpResponsesListWithStats = async (): Promise<RFPResponseWithRFPAndStats[]> => {
  const responses = await fetchRfpResponsesList();
  const stats = await useRfpResponsesStats.queries.fetchRfpResponseStats(responses.data.map((response) => response.id));

  return responses.data.map(
    (response) =>
      ({
        ...response,
        ...(stats[response.id] ?? { open_ticket_count: 0, percent_complete: 0 }),
      } as RFPResponseWithRFPAndStats),
  );
};

const getQueryKey = () => ['rfpResponsesWithStats'];

const useRfpResponsesWithStats = () => useQuery(getQueryKey(), fetchRfpResponsesListWithStats);

useRfpResponsesWithStats.getQueryKey = getQueryKey;
useRfpResponsesWithStats.queries = {
  fetchRfpResponsesList,
  fetchRfpResponsesListWithStats,
  fetchRfpResponseStats: useRfpResponsesStats.queries.fetchRfpResponseStats,
} as const;

export default useRfpResponsesWithStats;
