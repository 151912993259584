/* eslint-disable @typescript-eslint/naming-convention */
import FormSubmitError from 'errors/FormSubmitError';
import LoginResponse from 'types/api/LoginResponse';

import API, { ApiResponse } from './api';
import { AxiosError } from 'axios';

export const login = async (email: string, password: string) => {
  try {
    return await API.post<LoginResponse>(
      '/login/',
      {
        email: email,
        password: password,
      },
      {
        errorInterceptorIgnoredCodes: [401],
      },
    );
  } catch (e) {
    const response = (e as AxiosError<{ error_message: string }>).response;

    if (response?.status === 401 && response?.data?.error_message !== undefined) {
      throw new FormSubmitError(response.data.error_message);
    }

    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

export const logout = async () => {
  try {
    return await API.get('/logout/');
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

interface RegisterFormData {
  organizationName: string;
  organizationAddress: string;
  organizationUrl: string;
  country: string | number | null;
  name: string;
  email: string;
  title: string;
  phone: string;
  terms: boolean;
}

interface RegisterResponse extends ApiResponse {
  registration_number: string;
}

export const register = async (formData: RegisterFormData & { token: string }) => {
  try {
    return await API.post<RegisterResponse>('/register/', {
      organization_name: formData.organizationName,
      organization_address: formData.organizationAddress,
      organization_url: formData.organizationUrl,
      country: formData.country,
      name: formData.name,
      email: formData.email,
      title: formData.title,
      phone: formData.phone,
      terms: formData.terms,
      token: formData.token,
    });
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};
