/* eslint-disable @typescript-eslint/naming-convention */
import { AxiosError, AxiosResponse } from 'axios';

import FormSubmitError from 'errors/FormSubmitError';
import RFPResponse from 'types/api/RFPResponse';
import TraroElementResponse from 'types/api/TraroElementResponse';
import RFPElementHistoryEntry from 'types/api/RFPElementHistoryEntry';
import RFPResponseWithRFP from 'types/api/RFPResponseWithRFP';

import API, { assertSuccessResponse } from './api';
import ErrorResponse from '../types/api/ErrorResponse';

export const createRfpResponse = async (rfpId: number, projectName: string) => {
  try {
    return await API.post<RFPResponseWithRFP>('/rfp-response/', { project_name: projectName, traro_rfp_id: rfpId });
  } catch (e) {
    if (e instanceof AxiosError && e.response?.data.error_message) {
      throw new FormSubmitError(e.response.data.error_message);
    }

    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

export const saveRfpResponse = async (
  responseId: number,
  response: RFPResponse,
): Promise<AxiosResponse<RFPResponse>> => {
  try {
    return await API.post('/rfp-response/' + responseId, response);
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

export const saveRfpTraroElement = async (
  rfpId: number,
  traroElement: TraroElementResponse,
): Promise<AxiosResponse<TraroElementResponse>> => {
  let result: AxiosResponse<TraroElementResponse> | AxiosResponse<ErrorResponse>;

  try {
    result = await API.post('/rfp-response-traro-element/' + rfpId, traroElement);
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }

  assertSuccessResponse<TraroElementResponse>(result);

  return result;
};

export const addRfpTraroElementComment = async (
  responseId: number,
  comment: { response_comment: string },
): Promise<AxiosResponse<TraroElementResponse>> => {
  try {
    return await API.post('/rfp-response-traro-element-comment/' + responseId, comment);
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

export const loadRfpElementResponseHistory = async (
  elementId: number,
): Promise<AxiosResponse<RFPElementHistoryEntry[]>> => {
  try {
    return await API.get<RFPElementHistoryEntry[]>('/rfp-element-response-history/' + elementId);
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};
