import React, { FC } from 'react';
import TicketDto from 'types/api/TicketDto';
import Table from 'components/Table/Table';

import useColumns from './hooks/useColumns';

export interface TicketsProps {
  tickets: TicketDto[] | undefined;
}

const Tickets: FC<TicketsProps> = ({ tickets }) => {
  const columns = useColumns();

  return (
    <div className="enkrateia-response-tickets">
      <Table data={tickets} columns={columns} />
    </div>
  );
};

export default Tickets;
