import { useCallback } from 'react';
import { FormikHelpers } from 'formik';

import { useAddTicketCommentMutation } from 'api';

import { TicketCommentFormState } from './useInitialValues';

const useTicketCommentSubmit = () => {
  const { mutateAsync, isLoading, isSuccess } = useAddTicketCommentMutation<FormikHelpers<TicketCommentFormState>>({
    onSuccess: async (data, variables) => {
      variables.meta.resetForm();
    },
    onError: async (error, variables) => {
      variables.meta.setFieldError('attachment', error.message);
    },
  });

  const submit = useCallback(
    (values: TicketCommentFormState, helpers: FormikHelpers<TicketCommentFormState>) =>
      mutateAsync({ ...values, meta: helpers }),
    [mutateAsync],
  );

  return [submit, isLoading, isSuccess] as const;
};

export default useTicketCommentSubmit;
