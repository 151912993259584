import React, { FC } from 'react';
import { Trans } from 'react-i18next';

export interface BuiltOnEnkrateiaProps {}

const BuiltOnEnkrateia: FC<BuiltOnEnkrateiaProps> = ({}) => (
  <div className="enkrateia-built-on-enkrateia">
    <Trans i18nKey="pages.login.partOf">
      Built on the <b>Enkrateia Platform®</b>
    </Trans>
  </div>
);

export default BuiltOnEnkrateia;
