import React, { FC } from 'react';

export interface SvgIconProviderProps {
  children: React.ReactNode;
}

interface GradientProps {
  id: string;
  from: string;
  to: string;
  x1: string;
  x2: string;
  y1: string;
  y2: string;
}

const Gradient = ({ id, from, to, x1, x2, y1, y2 }: GradientProps) => (
  <linearGradient id={id} x1={x1} x2={x2} y1={y1} y2={y2}>
    <stop offset="0%" stopColor={from} />
    <stop offset="100%" stopColor={to} />
  </linearGradient>
);

const SvgIconProvider: FC<SvgIconProviderProps> = ({ children }) => (
  <>
    {children}
    <svg style={{ width: 0, height: 0, overflow: 'hidden', position: 'absolute' }}>
      <defs>
        <Gradient id="pinkOrangeVertical" from="#E94488" to="#EE6B56" x1="0" x2="0" y1="0" y2="1" />
        <Gradient id="pinkOrangeHorizontal" from="#E94488" to="#EE6B56" x1="0" x2="1" y1="0" y2="0" />
        <Gradient id="orangePinkVertical" from="#EE6B56" to="#E94488" x1="0" x2="0" y1="0" y2="1" />
        <Gradient id="orangePinkHorizontal" from="#EE6B56" to="#E94488" x1="0" x2="1" y1="0" y2="0" />
      </defs>
    </svg>
  </>
);
export default SvgIconProvider;
