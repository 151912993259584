/* eslint-disable @typescript-eslint/naming-convention */
import { MutableRefObject, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'store';
import { updateRfpElementResponse, setRfpElementResponse } from 'store/rfpResponse';
import { openModal } from 'store/modals';
import QuestionType from 'types/QuestionType';
import QuestionStatus from 'types/QuestionStatus';

import { QuestionSectionWithResponseAndContext } from './useKliparoElement';
import useInitialValues, { FormValues, QuestionFormValues } from './useInitialValues';
import useSchema from './useSchema';
import { FormikProps } from 'formik/dist/types';
import TraroElementResponse from '../../../types/api/TraroElementResponse';

export type { FormValues } from './useInitialValues';

const getResponse = (form: QuestionFormValues): string => {
  switch (form.type) {
    case QuestionType.TEXT:
    case QuestionType.LONG_TEXT:
    case QuestionType.URL:
    case QuestionType.DISPLAY_ONLY:
      return form.value;
    case QuestionType.CHECKBOX:
      return Object.keys(form.value)
        .filter((key) => form.value[key])
        .map(window.atob)
        .join(';');
    case QuestionType.SELECTION:
      return form.value?.value ?? '';
    default:
      // @ts-ignore
      throw new Error(`Invalid question type: ${form.type}`);
  }
};

const getKliparoResponses = (form: FormValues) => {
  return Object.getOwnPropertyNames(form.questions).map((key) => {
    const questionId = key as never as keyof typeof form.questions;

    const question = form.questions[questionId];

    return {
      id: question.id,
      questionId,
      responseText: getResponse(question),
    };
  });
};

const useKliparoElementForm = (
  { traro_element: traroElement, rfpElementResponse: traroElementResponse }: QuestionSectionWithResponseAndContext,
  formRef: MutableRefObject<FormikProps<FormValues> | null>,
) => {
  const rfpId = useSelector((state) => state.rfp.data.id);
  const rfpResponseId = useSelector((state) => state.rfpResponse.data.id);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialValues = useInitialValues(traroElement, traroElementResponse);
  const schema = useSchema(traroElement);

  const initialValuesRef = useRef(initialValues);
  initialValuesRef.current = initialValues;

  const reconcileState = useCallback(
    (data: TraroElementResponse): TraroElementResponse => {
      const form = formRef.current;
      if (!form) {
        return data;
      }

      return {
        ...data,
        kliparo_response_list: data.kliparo_response_list.map((response) => {
          const question = form.values.questions[response.kliparo_question!.id];

          if (!question || question.id !== response.id) {
            return response;
          }

          const responseText = getResponse(question);

          if (responseText === response.kliparo_response) {
            return response;
          }

          // There was a change on the frontend that is newer than what was returned from the backend
          return {
            ...response,
            kliparo_response: responseText,
          };
        }),
      };
    },
    [formRef],
  );

  const saveForm = useCallback(
    async (values: FormValues, noStoreSync = false) => {
      const payload = {
        elementResponseId: values.id,
        responseText: '-',
        score: values.score,
        elementId: traroElement.id as number,
        rfpId,
        rfpResponseId,
        kliparoResponses: getKliparoResponses(values),
        questionStatus: values.questionStatus,
        noStoreSync,
      };

      return (await dispatch(updateRfpElementResponse(payload)).unwrap()).data;
    },
    [traroElement, rfpId, rfpResponseId, dispatch],
  );

  const onAutoSave = useCallback(
    async (values: FormValues) => {
      try {
        if (
          Object.getOwnPropertyNames(values.questions).every(
            (key) =>
              values.questions[key as unknown as keyof typeof values.questions].value ===
              initialValuesRef.current.questions[key as unknown as keyof typeof values.questions].value,
          )
        ) {
          // If current initial values are the same as once triggered, it means
          // that there was a form update and we can bail out
          return;
        }

        const data = await saveForm(values, true);

        // Reconcile state
        dispatch(setRfpElementResponse(reconcileState(data)));

        if (
          values.questionStatus !== data.question_status &&
          // ENK-222 Do not show status change on going from begin to in progress
          values.questionStatus !== QuestionStatus.BEGIN &&
          data.question_status !== QuestionStatus.IN_PROGRESS
        ) {
          dispatch(openModal({ modal: 'traroElementSubmitModal', data: undefined }));
        }
      } catch (e) {
        console.error(e);
        dispatch(
          openModal({
            modal: 'errorModal',
            data: {
              message: t('errors.errorSavingResponse'),
            },
          }),
        );
      }
    },
    [t, saveForm, dispatch, reconcileState],
  );

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await saveForm(values);

        dispatch(openModal({ modal: 'traroElementSubmitModal', data: undefined }));
      } catch {
        dispatch(
          openModal({
            modal: 'errorModal',
            data: {
              message: t('errors.errorSavingResponse'),
            },
          }),
        );
      }
    },
    [saveForm, dispatch, t],
  );

  return {
    initialValues,
    schema,
    onSubmit,
    onAutoSave,
  } as const;
};

export default useKliparoElementForm;
