/* eslint-disable @typescript-eslint/naming-convention */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ScoringRubric from 'types/api/ScoringRubric';

import './ReviewRatingBulb.scss';
import { TFunction } from 'i18next';

export interface ReviewRatingBulbProps {
  scoringRubrics?: ScoringRubric[] | null;
  score?: number | undefined | null;
  variant?: 'bulb' | 'with-label';
}

export interface RawReviewRatingBulbProps {
  variant: 'bulb' | 'with-label';
  scoringRubric: Pick<ScoringRubric, 'description' | 'highlight_color'>;
}

const RawBulb = ({ variant, scoringRubric }: RawReviewRatingBulbProps) => (
  <div className="enkrateia-review-rating-bulb">
    <div className={scoringRubric.highlight_color}></div>
    <div className={variant}>{scoringRubric.description}</div>
  </div>
);

const getEmptyScoringRubric = (t: TFunction): ScoringRubric => ({
  id: -1,
  description: t('rating.notReviewed'),
  high_score: 0,
  low_score: 0,
  highlight_color: 'Grey',
});

const ReviewRatingBulb = ({ scoringRubrics, score, variant = 'bulb' }: ReviewRatingBulbProps) => {
  const { t } = useTranslation();

  const scoringRubric = useMemo((): ScoringRubric => {
    if (!scoringRubrics || scoringRubrics.length === 0 || score === undefined || score === null) {
      return getEmptyScoringRubric(t);
    }

    const rubric = [...scoringRubrics]
      .sort((a, b) => a.low_score - b.low_score)
      .find((r) => r.low_score <= score && score <= r.high_score);

    if (!rubric) {
      return getEmptyScoringRubric(t);
    }

    return rubric;
  }, [t, scoringRubrics, score]);

  return <RawBulb variant={variant} scoringRubric={scoringRubric} />;
};

ReviewRatingBulb.RawBulb = RawBulb;

export default ReviewRatingBulb;
