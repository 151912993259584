import {
  ChangeEvent,
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

import TextInput from '../TextInput';

import './PhoneNumberInput.scss';

type Variant = 'standard' | 'filled';

export interface PhoneNumberEntry {
  prefix: string;
  number: string;
}

export interface PhoneNumberInputProps
  extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'value' | 'onChange'> {
  label?: string;
  error?: string | ReactNode;
  hint?: string | ReactNode;
  touched?: boolean;
  fullWidth?: boolean;
  variant?: Variant;
  required?: boolean;
  noLabel?: boolean;
  value: PhoneNumberEntry;
  onChange: (value: PhoneNumberEntry) => void;
}

const PhoneNumberInput = forwardRef<HTMLInputElement, PhoneNumberInputProps>(({ value, onChange, ...props }, ref) => {
  const localRef = useRef<HTMLInputElement>(null);
  const ensuredRef = (ref ?? localRef) as RefObject<HTMLInputElement>;
  const telInputRef = useRef<intlTelInput.Plugin | null>(null);
  const valueRef = useRef(value);

  valueRef.current = value;

  const internalOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange({
        number: event.target.value,
        prefix: '+' + telInputRef.current?.getSelectedCountryData().dialCode ?? '',
      });
    },
    [onChange],
  );

  useLayoutEffect(() => {
    if (ensuredRef.current && !telInputRef.current) {
      telInputRef.current = intlTelInput(ensuredRef.current, {
        separateDialCode: true,
        utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js',
      });

      onChange({
        number: valueRef.current.number ?? '',
        prefix: '+' + telInputRef.current?.getSelectedCountryData().dialCode ?? '',
      });

      ensuredRef.current.addEventListener('countrychange', () => {
        onChange({
          number: valueRef.current.number,
          prefix: '+' + telInputRef.current?.getSelectedCountryData().dialCode ?? '',
        });
      });
    }
  }, [ensuredRef, valueRef]);

  useEffect(() => {}, []);

  return (
    <>
      <TextInput {...props} ref={ensuredRef} value={value.number} onChange={internalOnChange} />
    </>
  );
});

export default PhoneNumberInput;
