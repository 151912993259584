import { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import TraroElementResponse from 'types/api/TraroElementResponse';
import { useDispatch, useSelector } from 'store';
import { addElementComment } from 'store/rfpResponse';
import { openModal } from 'store/modals';
import ArchElementSpecific from '../../../../../../../types/ArchElementSpecific';

export interface TraroElementCommentForm {
  comment: string;
}

const useTraroElementForm = (traroElementResponse: TraroElementResponse) => {
  const rfpId = useSelector((state) => state.rfp.data.id);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialValues = useMemo(() => ({ comment: '' }), []);

  const schema = useMemo(
    () =>
      yup.object().shape({
        comment: yup
          .string()
          .min(1, t('errors.minLength', { count: 1 }))
          .max(1000, t('errors.maxLength', { count: 1000 }))
          .required(t('errors.minLength', { count: 1 })),
      }),
    [t],
  );

  const onSubmit = useCallback(
    async (values: TraroElementCommentForm) => {
      try {
        await dispatch(
          addElementComment({
            comment: values.comment,
            archElementSpecific: traroElementResponse.arch_element_specific as ArchElementSpecific,
            rfpId,
          }),
        ).unwrap();
      } catch {
        dispatch(
          openModal({
            modal: 'errorModal',
            data: {
              message: t('errors.errorSavingComment'),
            },
          }),
        );
      }
    },
    [traroElementResponse, rfpId, dispatch, t],
  );

  return {
    initialValues,
    schema,
    onSubmit,
  } as const;
};

export default useTraroElementForm;
