import React, { FC, useCallback } from 'react';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loader from 'components/Loader/Loader';
import Button from 'components/Button/Button';
import { useDispatch, useSelector } from 'store';
import { openModal } from 'store/modals';
import { saveRfpResponse } from 'store/rfpResponse';
import RFPResponseStatus from 'types/RFPResponseStatus';

import './CompleteReview.scss';

export interface SubmitProps {}

const CompleteReview: FC<SubmitProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const updating = useSelector((store) => store.rfpResponse.updating);
  const responseStatus = useSelector((store) => store.rfpResponse.data.rfp_response_status);
  const rfpId = useSelector((store) => store.rfp.data.id);

  const handleSubmit = useCallback(async () => {
    try {
      await dispatch(saveRfpResponse({ rfpId: rfpId })).unwrap();
      dispatch(openModal({ modal: 'rfpSubmitModal', data: undefined }));
    } catch {
      // Shh!
    }
  }, [dispatch, rfpId]);

  return (
    <div className="enkrateia-complete-review">
      <Button
        disabled={responseStatus !== RFPResponseStatus.IN_REVIEW || updating}
        variant="primary"
        onClick={handleSubmit}
      >
        {updating ? <Loader /> : t('pages.projectOverview.sidePanel.completeReview')}
      </Button>
      {/*<Link to="/history">{t('pages.projectOverview.sidePanel.history')}</Link>*/}
    </div>
  );
};

export default CompleteReview;
