import { useMemo } from 'react';
import { InputOption } from 'components/forms/raw/SelectInput';
import TraroElementResponse from 'types/api/TraroElementResponse';

const useQuestionNumberInitialValue = (
  questionNumberOptions: InputOption<number | null>[],
  traroElementResponse: TraroElementResponse | undefined,
) =>
  useMemo(
    () =>
      questionNumberOptions.find((option) => option.value === traroElementResponse?.id) ??
      questionNumberOptions.find((option) => option.value === null) ??
      null,
    [questionNumberOptions, traroElementResponse],
  );

export default useQuestionNumberInitialValue;
