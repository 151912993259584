import React, { FC, ReactNode } from 'react';

export interface TabNameProps {
  active: boolean;
  label: string | ReactNode;
  onClick: () => void;
}

const TabName: FC<TabNameProps> = ({ active, label, onClick }) => (
  <button type="button" className={`enkrateia-tab-name ${active ? 'active' : ''}`} onClick={onClick}>
    <span>{label}</span>
  </button>
);

export default TabName;
