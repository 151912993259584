import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ArchElementSpecific from 'types/ArchElementSpecific';

import './ProblemSpringerArc.scss';

export interface ProblemSpringerArcProps {
  activeElement: ArchElementSpecific;
  title: string;
}

const ProblemSpringerArc: FC<ProblemSpringerArcProps> = ({ activeElement, title }) => {
  const { t } = useTranslation();

  return (
    <div className="enkrateia-problem-springer-arc">
      <svg width="228" height="127" viewBox="0 0 228 127" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          className={activeElement === ArchElementSpecific.SPRINGER_LEFT ? 'active' : ''}
          d="M16.2717 87.278L45.7296 97.9784C49.3614 99.2976 51.5312 103.024 50.886 106.834L48.5991 120.336C47.9473 124.184 44.6142 127 40.7114 127H9.46118C4.51175 127 0.749853 122.551 1.57245 117.67L5.65166 93.4677C6.48206 88.5408 11.5755 85.5722 16.2717 87.278Z"
        />
        <path
          className={activeElement === ArchElementSpecific.VOUSSOIR_LEFT_1 ? 'active' : ''}
          d="M42.5765 47.8903L64.3754 70.4234C67.0705 73.2093 67.3807 77.5266 65.1115 80.6691L57.2195 91.5987C54.9372 94.7594 50.709 95.8147 47.2092 94.0972L19.1182 80.3114C14.6709 78.1289 13.2562 72.4703 16.153 68.4516L30.3371 48.7747C33.251 44.7323 39.1117 44.3088 42.5765 47.8903Z"
        />
        <path
          className={activeElement === ArchElementSpecific.VOUSSOIR_LEFT_2 ? 'active' : ''}
          d="M85.2051 23.6048L94.463 53.5585C95.6076 57.2618 93.9326 61.253 90.4878 63.0303L78.5074 69.2116C75.0428 70.9991 70.7942 70.029 68.4488 66.9148L49.6242 41.9192C46.6439 37.962 47.9401 32.275 52.3407 30L73.888 18.8606C78.3146 16.5721 83.7336 18.8438 85.2051 23.6048Z"
        />
        <path
          className={activeElement === ArchElementSpecific.VOUSSOIR_RIGHT_1 ? 'active' : ''}
          d="M184.935 47.8903L163.136 70.4234C160.441 73.2093 160.131 77.5266 162.4 80.6691L170.292 91.5987C172.574 94.7594 176.803 95.8147 180.303 94.0972L208.394 80.3114C212.841 78.1289 214.256 72.4703 211.359 68.4516L197.175 48.7747C194.261 44.7323 188.4 44.3088 184.935 47.8903Z"
        />
        <path
          className={activeElement === ArchElementSpecific.VOUSSOIR_RIGHT_2 ? 'active' : ''}
          d="M142.307 23.6048L133.049 53.5585C131.904 57.2618 133.579 61.253 137.024 63.0303L149.004 69.2116C152.469 70.9991 156.718 70.029 159.063 66.9148L177.888 41.9192C180.868 37.962 179.572 32.275 175.171 30L153.624 18.8606C149.197 16.5721 143.778 18.8438 142.307 23.6048Z"
        />
        <path
          className={activeElement === ArchElementSpecific.SPRINGER_RIGHT ? 'active' : ''}
          d="M211.653 87.1932L181.343 97.9954C177.677 99.3019 175.484 103.057 176.146 106.892L178.471 120.361C179.133 124.197 182.461 127 186.354 127H218.509C223.468 127 227.233 122.534 226.393 117.646L222.224 93.3745C221.383 88.4797 216.332 85.5259 211.653 87.1932Z"
        />
        <path
          className={activeElement === ArchElementSpecific.KEYSTONE ? 'active' : ''}
          d="M99.4274 51.5851L90.9375 9.58506C89.9359 4.63019 93.7238 0 98.7789 0H129.335C134.352 0 138.131 4.56585 137.194 9.49518L129.203 51.4952C128.485 55.2692 125.186 58 121.344 58H107.269C103.462 58 100.182 55.3168 99.4274 51.5851Z"
        />
      </svg>
      <span className="title">{title}</span>
      <span className="hint">{t('common.problemSpringer.hint')}</span>
    </div>
  );
};

export default ProblemSpringerArc;
