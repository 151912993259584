import React, { useMemo } from 'react';
import { useSelector } from 'store';

import ReviewRatingBulb from 'components/ReviewRatingBulb/ReviewRatingBulb';
import { InputOption } from 'components/forms/raw/SelectInput';
import ScoringRubric from 'types/api/ScoringRubric';

export type EligibilityOption = InputOption<number, ScoringRubric>;

const useEligibilityOptions = (traroElementId?: number | null): EligibilityOption[] => {
  const rfpScoringRubric = useSelector((store) => store.rfp.data.scoring_rubric);
  const elementScoringRubric = useSelector(
    (store) => store.rfp.data.traro_element_list.find((e) => e.id === traroElementId)?.scoring_rubric,
  );

  const scoringRubric = elementScoringRubric ?? rfpScoringRubric;

  return useMemo(
    () =>
      scoringRubric.map((rubric) => ({
        value: rubric.id,
        label: <ReviewRatingBulb variant="with-label" scoringRubrics={scoringRubric} score={rubric.low_score} />,
        meta: rubric,
      })),
    [scoringRubric],
  );
};

export default useEligibilityOptions;
