import React, { FC } from 'react';

export interface TimelineEntryPlaceholderProps {
  dateWidth: number;
  contentWidth: number;
  contentHeight: number;
}

const TimelineEntryPlaceholder: FC<TimelineEntryPlaceholderProps> = ({ dateWidth, contentHeight, contentWidth }) => (
  <>
    <div className="date placeholder" style={{ width: dateWidth }}></div>
    <div className="point">
      <div className="dot"></div>
      <div className="line"></div>
    </div>
    <div className="content placeholder" style={{ width: contentWidth, height: contentHeight }}></div>
  </>
);

export default TimelineEntryPlaceholder;
