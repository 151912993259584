import { useCallback } from 'react';
import { FormikHelpers } from 'formik';

import { useResetPasswordMutation } from 'api';
import useGReCaptcha from 'hooks/useGReCaptcha';

import { ResetPasswordForm } from './useInitialValues';

const useResetPasswordSubmit = () => {
  const runCaptchaChallange = useGReCaptcha();

  const { mutateAsync, isLoading, isSuccess } = useResetPasswordMutation({
    onSuccess: async (data, variables) => {
      variables.meta.resetForm();
    },
    onError: async (error, variables) => {
      variables.meta.setFieldError('email', error.message);
    },
  });

  const submit = useCallback(
    async (values: ResetPasswordForm, helpers: FormikHelpers<ResetPasswordForm>) => {
      const token = await runCaptchaChallange();
      return mutateAsync({ ...values, token, meta: helpers });
    },
    [mutateAsync, runCaptchaChallange],
  );

  return [submit, isLoading, isSuccess] as const;
};

export default useResetPasswordSubmit;
