import React, { FC } from 'react';
import { useSelector } from 'store';
import Header from 'components/Header/Header';

import TraroProjectOverview from './components/TraroProjectOverview/TraroProjectOverview';
import ProjectSubmittedModal from './components/ProjectSubmittedModal/ProjectSubmittedModal';
import QuestionStatusesHelpModal from './components/QuestionStatusesHelpModal/QuestionStatusesHelpModal';
import TraroSideBar from './components/TraroSideBar/TraroSideBar';
import KliparoSideBar from './components/KliparoSideBar/KliparoSideBar';

import './ProjectOverviewPage.scss';
import KliparoProjectOverview from './components/KliparoProjectOverview/KliparoProjectOverview';

export interface DashboardPageProps {}

const ProjectOverviewPage: FC<DashboardPageProps> = () => {
  const rfpType = useSelector((store) => store.rfp.data.rfp_type);

  return (
    <div className="enkrateia-project-overview-page">
      <Header />
      <div className="content">
        {rfpType === 'traro' && <TraroSideBar />}
        {rfpType === 'kliparo' && <KliparoSideBar />}
        <main>
          {rfpType === 'traro' && <TraroProjectOverview />}
          {rfpType === 'kliparo' && <KliparoProjectOverview />}
        </main>
        <ProjectSubmittedModal />
        <QuestionStatusesHelpModal />
      </div>
    </div>
  );
};

export default ProjectOverviewPage;
