import { AxiosError, AxiosResponse } from 'axios';

import { handle401 } from './error-handlers';

export const responseInterceptor = (response: AxiosResponse) => response;

export const responseErrorInterceptor = (error: AxiosError) => {
  if (
    error.response?.status !== undefined &&
    (error.config?.errorInterceptorIgnoredCodes ?? []).includes(error.response?.status)
  ) {
    throw error;
  }

  switch (error.response?.status) {
    case 401:
      return handle401();
    default:
      throw error;
  }
};
