import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ArchElementSpecific from 'types/ArchElementSpecific';

interface Slice {
  archElement: ArchElementSpecific | null;
}

export const archSlice = createSlice({
  name: 'arch',
  initialState: {
    archElement: null,
  } as Slice,
  reducers: {
    selectElement: (state, { payload }: PayloadAction<ArchElementSpecific>) => {
      state.archElement = payload;
    },
    reset: (state) => {
      state.archElement = null;
    },
  },
});

export default archSlice.reducer;
