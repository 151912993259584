import React, { FC } from 'react';
import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';

import EditableProjectInfoPanel from 'components/EditableProjectInfoPanel/EditableProjectInfoPanel';
import DatePickerInput from 'components/forms/raw/DatePickerInput';

export interface DaysLeftProps {
  closeDate: string | null;
  label: string;
  disabled?: boolean;
  onSubmit?: (value: DateTime | null) => Promise<void>;
}

const DaysLeft: FC<DaysLeftProps> = ({ closeDate, disabled, label, onSubmit = () => Promise.resolve() }) => (
  <div className="days-left">
    <EditableProjectInfoPanel
      onSubmit={onSubmit}
      variant="kliparo"
      className="close-date"
      label={label}
      value={isEmpty(closeDate) || closeDate === null ? null : DateTime.fromISO(closeDate)}
      InputComponent={(props) => <DatePickerInput {...props} clearable variant="raw" />}
      areValuesEqual={(a, b) => a?.toISO() === b?.toISO()}
      disabled={disabled}
    />
  </div>
);

export default DaysLeft;
