import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'store';
import Button from 'components/Button/Button';
import RFPResponseStatus from 'types/RFPResponseStatus';

import RevertToOpen from './components/RevertToOpen/RevertToOpen';
import MarkAsFinal from './components/MarkAsFinal/MarkAsFinal';
import RevertToInReview from './components/RevertToInReview/RevertToInReview';
import CreateReviewTickets from './components/CreateReviewTickets/CreateReviewTickets';

import './ReviewerActions.scss';

const ReviewerActions = () => {
  const { t } = useTranslation();
  const { rfp_response_status, id } = useSelector((store) => store.rfpResponse.data);

  return (
    <div className="enkrateia-reviewer-actions">
      {rfp_response_status === RFPResponseStatus.IN_REVIEW && <CreateReviewTickets responseId={id} />}
      {rfp_response_status === RFPResponseStatus.IN_REVIEW && <RevertToOpen />}
      {rfp_response_status === RFPResponseStatus.IN_REVIEW && <MarkAsFinal />}
      {rfp_response_status === RFPResponseStatus.FINAL && <RevertToInReview />}
      <Button variant="primary" disabled>
        {t('pages.projectOverview.mainPanel.copyAssessment')}
      </Button>
    </div>
  );
};

export default ReviewerActions;
