import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import TextInput, { TextInputProps } from '../TextInput';
import useDebouncedCallback from '@restart/hooks/useDebouncedCallback';

export interface DebouncedTextInputProps extends TextInputProps {
  debounce?: number;
}

const DebouncedTextInput = forwardRef<HTMLInputElement, DebouncedTextInputProps>(
  ({ value, onChange, debounce = 500, ...props }, ref) => {
    const [localValue, setLocalValue] = useState(value);
    // @ts-ignore
    const debouncedOnChange = useDebouncedCallback(onChange, debounce);
    const localOnChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        setLocalValue(event.target.value);
        debouncedOnChange(event);
      },
      [debouncedOnChange],
    );

    // Sync outer change into local state
    const lastOuterChangeValue = useRef(value);
    useEffect(() => {
      if (value !== lastOuterChangeValue.current) {
        setLocalValue(value);
        lastOuterChangeValue.current = value;
      }
    }, [value, lastOuterChangeValue]);

    return <TextInput {...props} ref={ref} value={localValue} onChange={localOnChange} />;
  },
);

export default DebouncedTextInput;
