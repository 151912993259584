import React, { FC } from 'react';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Background from 'components/Background/Background';
import LoaderOverlay from 'components/LoaderOverlay/LoaderOverlay';

import OrganizationDetails from './components/OrganizationDetails/OrganizationDetails';
import UserDetails from './components/UserDetails/UserDetails';

import './OrganizationProfilePage.scss';

export interface OrganizationProfilePageProps {}

const OrganizationProfilePage: FC<OrganizationProfilePageProps> = () => {
  return (
    <div className="enkrateia-organization-profile-page">
      <Header />
      <LoaderOverlay />
      <main>
        <OrganizationDetails />
        <UserDetails />
      </main>
      <Footer />
      <Background />
    </div>
  );
};

export default OrganizationProfilePage;
