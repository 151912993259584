import React, { FC, ReactNode } from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';

import timesIcon from 'assets/img/timesIcon.svg';

import KliparoModal from './components/KliparoModal/KliparoModal';

import './Modal.scss';

export type ModalProps = ReactModalProps &
  (
    | {
        variant?: 'traro';
        title?: void;
      }
    | {
        variant?: 'kliparo';
        title: ReactNode;
      }
  );

const Modal: FC<ModalProps> = ({
  variant = 'traro',
  children,
  onRequestClose,
  overlayClassName = '',
  className = '',
  title,
  ...props
}) => (
  <ReactModal
    {...props}
    onRequestClose={onRequestClose}
    overlayClassName={`enkrateia-modal-overlay ${overlayClassName}`}
    className={`enkrateia-modal ${className} ${variant}`}
    appElement={document.getElementById('root') as HTMLElement}
  >
    {variant === 'traro' && (
      <>
        {children}
        {onRequestClose && (
          <button type="button" className="enkrateia-modal-close-button" onClick={onRequestClose}>
            <img src={timesIcon} alt="Close" />
          </button>
        )}
      </>
    )}
    {variant === 'kliparo' && (
      <KliparoModal onClose={onRequestClose} title={title as ReactNode}>
        {children}
      </KliparoModal>
    )}
  </ReactModal>
);

export default Modal;
