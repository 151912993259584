import React, { useCallback } from 'react';
import { OnChangeValue } from 'react-select';

import useFormikConnection, { TouchTrigger, WithoutInjectedProps } from '../hooks/useFormikConnection';
import SelectInput, { InputOption, SelectInputProps } from '../../raw/SelectInput';

export interface FormikSelectInputProps<Option extends InputOption, IsMulti extends boolean>
  extends WithoutInjectedProps<SelectInputProps<Option, IsMulti>, 'onChange'> {
  name: string;
  touchedTrigger?: TouchTrigger;
}

const FormikSelectInput = <Option extends InputOption, IsMulti extends boolean>({
  name,
  ...props
}: FormikSelectInputProps<Option, IsMulti>) => {
  const { setValue, ...other } = useFormikConnection<OnChangeValue<Option, IsMulti>, HTMLInputElement>(name, props);
  const handleChange: SelectInputProps<Option, IsMulti>['onChange'] = useCallback(
    (newValue) => setValue(newValue),
    [setValue],
  );

  return <SelectInput {...props} {...other} label={props.label} onChange={handleChange} />;
};

export default FormikSelectInput;
