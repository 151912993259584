/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import RFP from 'types/api/RFP';
import RfpMode from 'types/RfpMode';
import User from 'types/User';

interface Slice {
  data: RFP;
  mode: RfpMode;
  loading: boolean;
  error: string | undefined;
  requestedId: number | undefined;
}

const INITIAL_RFP_DATA: Slice['data'] = {
  id: -1,
  name: '',
  close_date: '',
  response_close_date: '',
  RFP_URL: '',
  scoring_rubric: [],
  short_name: '',
  traro_element_list: [],
  kliparo_section_list: [],
  rfp_description: '',
  rfp_type: 'traro',
  rfp_terms: '',
  contact_email: '',
  point_of_contact: {} as User,
};

export const rfpSlice = createSlice({
  name: 'rfp',
  initialState: {
    data: { ...INITIAL_RFP_DATA },
    mode: RfpMode.ARCH,
    error: undefined,
    loading: false,
    requestedId: undefined,
  } as Slice,
  reducers: {
    reset: (state) => {
      state.data = { ...INITIAL_RFP_DATA };
      state.loading = false;
      state.error = undefined;
    },
    setMode: (state, { payload }: PayloadAction<RfpMode>) => {
      state.mode = payload;
    },
    setRfpData: (state, { payload }: PayloadAction<RFP>) => {
      state.data = payload;
    },
  },
});

export default rfpSlice.reducer;
