import TicketConnectionDetailsDto from '../../types/api/TicketConnectionDetailsDto';

const useLinkedCriterionUrl = (details: TicketConnectionDetailsDto | undefined | null) => {
  if (!details) {
    return '';
  }

  return `/rfp/${details.rfp_id}/rfp-response/${details.rfp_response_id}/rfp-question-response/${details.section_number}/${details.question_number}`;
};

export default useLinkedCriterionUrl;
