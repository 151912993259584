/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from '@reduxjs/toolkit';
import { login, logout } from './thunks';
import User, { isProponent, isReviewer } from 'types/User';

interface Slice {
  user: User;
  isLoggedIn: boolean;
  loading: boolean;
  error: string | undefined;
}

const INITIAL_USER: User = {
  id: 0,
  email: '',
  first_name: '',
  last_name: '',
  company: '',
  user_type_name: '',
  user_type: 'PRT',
  isReviewer: false,
  isProponent: true,
  phone_number: '',
  user_title: '',
  user_role: '',
  organization: {
    id: -1,
    name: '',
    address: '',
    country: '',
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: { ...INITIAL_USER },
    isLoggedIn: false,
    loading: false,
  } as Slice,
  reducers: {
    setLoggedOut: (state) => {
      state.user = { ...INITIAL_USER };
      state.isLoggedIn = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = {
        ...action.payload.user,
        isReviewer: isReviewer(action.payload.user),
        isProponent: isProponent(action.payload.user),
      };
      state.isLoggedIn = true;
      state.loading = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.user = { ...INITIAL_USER };
      state.loading = false;
      state.isLoggedIn = false;
      state.error = action.error.message;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.user = { ...INITIAL_USER };
      state.loading = false;
      state.isLoggedIn = false;
    });
  },
});

export default authSlice.reducer;
