import React, { FC } from 'react';
import TicketStatus from 'types/TicketStatus';
import { useTranslation } from 'react-i18next';

import './TicketStatusIndicator.scss';

export interface TicketStatusIndicatorProps {
  status: TicketStatus;
}

const TicketStatusIndicator: FC<TicketStatusIndicatorProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <div className={`enkrateia-rfp-response-status-cell ${status}`}>
      <div className="dot"></div>
      <div className="text">{t(`pages.ticketDetails.status.${status}`)}</div>
    </div>
  );
};

export default TicketStatusIndicator;
