import { MouseEvent, useCallback } from 'react';

import { useDispatch, useSelector } from 'store';
import { selectElement } from 'store/arch';

import ArchElementsMap from 'types/ArchElementsMap';
import ArchElementGeneric from 'types/ArchElementGeneric';
import ArchElementSpecific from 'types/ArchElementSpecific';

/**
 * For given arch_element_specific it returns if it is selected and a handler to select one.
 *
 * Optionally pass generic one for selection comparison (for selecting all elements of generic type, like all bricks)
 *
 * @param specificElement Specific element to select and check selection
 * @param genericElement Optional generic element to check (if passed)
 */
const useElementSelection = (specificElement: ArchElementSpecific, genericElement?: ArchElementGeneric) => {
  const dispatch = useDispatch();
  const selectedArchElement = useSelector((store) => store.arch.archElement);

  let isSelected: boolean;

  if (!genericElement) {
    isSelected = selectedArchElement === specificElement;
  } else {
    isSelected = selectedArchElement !== null && ArchElementsMap[selectedArchElement] === genericElement;
  }

  const select = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      dispatch(selectElement(specificElement));
    },
    [dispatch, specificElement],
  );

  return [isSelected, select] as const;
};

export default useElementSelection;
