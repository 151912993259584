import React from 'react';
import remarkGfm from 'remark-gfm';
import ReactMarkdown, { Options } from 'react-markdown';

export interface MarkdownProps extends Options {}

const remarkPlugins: Options['remarkPlugins'] = [[remarkGfm, { singleTilde: false }]];

const Markdown = ({ children, ...props }: MarkdownProps) => (
  <ReactMarkdown {...props} remarkPlugins={remarkPlugins}>
    {children}
  </ReactMarkdown>
);

export default Markdown;
