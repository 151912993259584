import React, { ComponentType, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TFuncKey } from 'i18next';

import QuestionStatus from 'types/QuestionStatus';
import {
  CheckInCircleIcon,
  ExclamationInCircleIcon,
  IconProps,
  InProgressBars,
  InReviewIcon,
  NaTextIcon,
  UnderscoreIcon,
} from 'components/SvgIcons';
import Tooltip from 'components/Tooltip/Tooltip';

import './KliparoResponseStatusIndicator.scss';

export interface KliparoResponseStatusIndicatorProps {
  status: QuestionStatus | undefined | null;
  variant?: 'large' | 'small';
  onClick?: () => void;
  className?: string;
}

interface KliparoResponseStatusConfig {
  Icon: ComponentType<IconProps>;
  label: TFuncKey<'en'>;
  className: string;
  textColor: string;
  borderColor: string;
  backgroundColor: string;
}

const getConfig = (status: QuestionStatus | undefined | null): KliparoResponseStatusConfig => {
  switch (status) {
    case QuestionStatus.DONE:
      return {
        Icon: CheckInCircleIcon,
        label: 'common.kliparoStatus.complete',
        className: 'complete',
        textColor: '#ffffff',
        borderColor: '#343892',
        backgroundColor: '#343892',
      };
    case QuestionStatus.IN_PROGRESS:
      return {
        Icon: InProgressBars,
        label: 'common.kliparoStatus.inProgress',
        className: 'in-progress',
        textColor: '#000000',
        borderColor: '#F8C925',
        backgroundColor: '#F8C925',
      };
    case QuestionStatus.REVIEW_ONLY:
      return {
        Icon: InReviewIcon,
        label: 'common.kliparoStatus.inReview',
        className: 'in-review',
        textColor: '#ffffff',
        borderColor: '#339D09',
        backgroundColor: '#339D09',
      };
    case QuestionStatus.UPDATE_REQUIRED:
      return {
        Icon: ExclamationInCircleIcon,
        label: 'common.kliparoStatus.updateRequired',
        className: 'in-update-required',
        textColor: '#ffffff',
        borderColor: '#B63333',
        backgroundColor: '#B63333',
      };
    case QuestionStatus.REVIEW_COMPLETE:
      return {
        Icon: CheckInCircleIcon,
        label: 'common.kliparoStatus.reviewComplete',
        className: 'review-complete',
        textColor: '#000000',
        borderColor: '#ADCFDE',
        backgroundColor: '#ADCFDE',
      };
    case QuestionStatus.NOT_REQUIRED:
      return {
        Icon: NaTextIcon,
        label: 'common.kliparoStatus.notRequired',
        className: 'not-required',
        textColor: '#7D8596',
        borderColor: '#D8DADF',
        backgroundColor: '#D8DADF',
      };
    case QuestionStatus.BEGIN:
    default:
      return {
        Icon: UnderscoreIcon,
        label: 'common.kliparoStatus.notStarted',
        className: 'not-started',
        textColor: '#000000',
        borderColor: '#343892',
        backgroundColor: '#ffffff',
      };
  }
};

const KliparoResponseStatusIndicator: FC<KliparoResponseStatusIndicatorProps> = ({
  status,
  variant = 'large',
  onClick,
  className: buttonClassName = '',
}) => {
  const { t } = useTranslation();
  const { Icon, label, className, textColor, borderColor, backgroundColor } = getConfig(status);

  return (
    <button
      className={`enkrateia-kliparo-response-status ${variant} ${buttonClassName}`}
      onClick={onClick}
      type="button"
      style={{
        backgroundColor,
        border: `1px solid ${borderColor}`,
        color: textColor,
        cursor: onClick ? 'pointer' : 'default',
      }}
    >
      <Tooltip tooltip={t(label) as string} InnerComponent="div" disabled={variant === 'large'}>
        <div className={`inner-status-content ${className}`}>
          {variant === 'large' && <span>{t(label) as string}</span>}
          {variant === 'small' && <Icon color={textColor} size={25} />}
        </div>
      </Tooltip>
    </button>
  );
};

export default KliparoResponseStatusIndicator;
