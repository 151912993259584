import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { useDispatch, useSelector } from 'store';
import { closeModal } from 'store/modals';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import useUser from 'hooks/useUser';

import './ProjectSubmittedModal.scss';

export interface ProjectSubmittedModalProps {}

const ProjectSubmittedModal: FC<ProjectSubmittedModalProps> = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const isOpen = useSelector((store) => store.modals.rfpSubmitModal.open);
  const projectName = useSelector((store) => store.rfpResponse.data.project_name);
  const { email, first_name, last_name, isReviewer } = useUser();

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal('rfpSubmitModal'));
  }, [dispatch]);

  return (
    <Modal isOpen={isOpen} className="enkrateia-project-submitted-modal">
      <div className="title">
        <h2>
          {isReviewer
            ? t('pages.projectOverview.submittedModal.title.reviewer')
            : t('pages.projectOverview.submittedModal.title.proponent')}
        </h2>
      </div>
      <div className="details">
        <dl>
          <dt>{t('pages.projectOverview.submittedModal.projectName')}</dt>
          <dd>{projectName}</dd>

          <dt>{t('pages.projectOverview.submittedModal.emailAddress')}</dt>
          <dd>{email}</dd>

          <dt>{t('pages.projectOverview.submittedModal.contactName')}</dt>
          <dd>
            {first_name} {last_name}
          </dd>

          <dt>{t('pages.projectOverview.submittedModal.date')}</dt>
          <dd>{DateTime.now().toFormat('M/d/y')}</dd>
        </dl>
      </div>
      <div className="summary">
        <span>
          {isReviewer
            ? t('pages.projectOverview.submittedModal.notificationSent')
            : t('pages.projectOverview.submittedModal.reviewTimeNotice', { count: 3 })}
        </span>
        <Button variant="primary" onClick={handleCloseModal}>
          {t('common.ok')}
        </Button>
      </div>
    </Modal>
  );
};

export default ProjectSubmittedModal;
