import React from 'react';
import { useTranslation } from 'react-i18next';

import TicketSeverityType from 'types/TicketSeverityType';

import './TicketSeverityRating.scss';

export interface RawReviewRatingBulbProps {
  severity: TicketSeverityType;
  variant?: 'normal' | 'small';
}

const TicketSeverityRating = ({ severity, variant = 'normal' }: RawReviewRatingBulbProps) => {
  const { t } = useTranslation();

  return (
    <div className={`enkrateia-ticket-severity-rating variant-${variant}`}>
      <div className={`bulb ${severity}`}></div>
      <div className="text">{t(`common.tickets.severities.${severity}`)}</div>
    </div>
  );
};

export default TicketSeverityRating;
