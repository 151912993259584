import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'store';
import KliparoSection from 'types/api/KliparoSection';
import PreviousNextNavigation from 'components/PreviousNextNavigation/PreviousNextNavigation';
import ProjectInfoPanel from 'components/ProjectInfoPanel/ProjectInfoPanel';

export interface QuestionNavigationProps {
  section: KliparoSection;
  questionNumber: number;
  elementName: string;
}

const QuestionInfoPanel: FC<QuestionNavigationProps> = ({ section, questionNumber, elementName }) => {
  const { t } = useTranslation();
  const rfpId = useSelector((store) => store.rfp.data.id);
  const rfpResponseId = useSelector((store) => store.rfpResponse.data.id);

  const [prevUrl, nextUrl] = useMemo(() => {
    let prev: string | undefined;
    let next: string | undefined;

    const questionIndex = section.question_section_list.findIndex(
      (question) => question.question_number === questionNumber,
    );
    const prevQuestionId = section.question_section_list[questionIndex - 1]?.question_number;
    const nextQuestionId = section.question_section_list[questionIndex + 1]?.question_number;

    if (prevQuestionId !== undefined) {
      prev = `/rfp/${rfpId}/rfp-response/${rfpResponseId}/rfp-question-response/${section.section_number}/${prevQuestionId}`;
    }

    if (nextQuestionId !== undefined) {
      next = `/rfp/${rfpId}/rfp-response/${rfpResponseId}/rfp-question-response/${section.section_number}/${nextQuestionId}`;
    }

    return [prev, next] as const;
  }, [section, questionNumber, rfpId, rfpResponseId]);

  return (
    <ProjectInfoPanel
      label={t('pages.traroElement.sidePanel.criterion')}
      value={
        <PreviousNextNavigation prevUrl={prevUrl} nextUrl={nextUrl}>
          {elementName}
        </PreviousNextNavigation>
      }
    />
  );
};

export default QuestionInfoPanel;
