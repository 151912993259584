import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Timeline from 'components/Timeline/Timeline';
import LoaderOverlay from 'components/LoaderOverlay/LoaderOverlay';
import TraroElement from 'types/api/TraroElement';

import useElementHistory from './hooks/useElementHistory';
import HistoryEntry from './components/HistoryEntry/HistoryEntry';
import LoadingPlaceholder from './components/LoadingPlaceholder/LoadingPlaceholder';

import './HistoryModal.scss';

export interface HistoryModalProps {
  elementId: number;
  traroElement: TraroElement;
}

const HistoryModal: FC<HistoryModalProps> = ({ elementId, traroElement }) => {
  const { t } = useTranslation();
  const [data, loading] = useElementHistory(elementId);

  return (
    <div className="enkrateia-history-modal">
      <div className="title">
        <h2>{t('pages.traroElement.historyModal.title', { elementName: traroElement.element_name })}</h2>
      </div>
      <div className="details">
        <LoaderOverlay open={loading} />
        {loading ? (
          <LoadingPlaceholder />
        ) : data.length === 0 ? (
          <div className="no-history">
            <h3>{t('pages.traroElement.historyModal.noHistory')}</h3>
          </div>
        ) : (
          <Timeline>
            {data.map((entry) => (
              <Timeline.Entry key={entry.id} date={entry.timestamp}>
                <HistoryEntry entry={entry} traroElement={traroElement} />
              </Timeline.Entry>
            ))}
          </Timeline>
        )}
      </div>
    </div>
  );
};

export default HistoryModal;
