import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button/Button';
import useOnSubmit from './hooks/useOnSubmit';

import ChangeAssessmentStateModal from '../ChangeAssessmentStateModal/ChangeAssessmentStateModal';

const RevertToInReview = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = useOnSubmit(setIsOpen);

  return (
    <>
      <Button className="revert-to-in-review" variant="green-filled" onClick={() => setIsOpen(true)}>
        {t('pages.projectOverview.mainPanel.revertToInReview.button')}
      </Button>
      <ChangeAssessmentStateModal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        onSubmit={onSubmit}
        title={t('pages.projectOverview.mainPanel.revertToInReview.modalTitle')}
        submitButton={
          <Button className="revert-to-in-review" variant="green-filled" type="submit">
            {t('pages.projectOverview.mainPanel.revertToInReview.button')}
          </Button>
        }
      />
    </>
  );
};

export default RevertToInReview;
