import { AxiosResponse } from 'axios';
import ErrorResponse from 'types/api/ErrorResponse';
import FormSubmitError from 'errors/FormSubmitError';

export function assertSuccessResponse<T>(
  result: AxiosResponse<T> | AxiosResponse<ErrorResponse>,
): asserts result is AxiosResponse<T> {
  if ((result as AxiosResponse<ErrorResponse>).data.error) {
    throw new FormSubmitError((result as AxiosResponse<ErrorResponse>).data.error_message);
  }
}
