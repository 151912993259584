import React, { FC } from 'react';

import './ProjectInfoPanel.scss';

export interface ProjectInfoPanelProps {
  label: string | JSX.Element;
  value: string | number | JSX.Element;
  className?: string;
  withBorder?: boolean;
  variant?: 'traro' | 'kliparo';
}

const ProjectInfoPanel: FC<ProjectInfoPanelProps> = ({
  label,
  value,
  className = '',
  withBorder = false,
  variant = 'kliparo',
}) => (
  <div className={`enkrateia-project-info-panel variant-${variant} ${withBorder ? 'with-border' : ''} ${className}`}>
    <div className="label">{label}</div>
    <div className="value">{value}</div>
  </div>
);

export default ProjectInfoPanel;
