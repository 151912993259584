import React, { FC } from 'react';

import KliparoDashboard from './components/KliparoDashboard/KliparoDashboard';

export interface DashboardProps {}

const Dashboard: FC<DashboardProps> = () => {
  // We may have Kliparo and Traro dashboards here in the future
  return <KliparoDashboard />;
};

export default Dashboard;
