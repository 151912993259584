import { useQuery } from '@tanstack/react-query';

import API from 'services/api';
import FormSubmitError from 'errors/FormSubmitError';
import TicketConnectionDetailsDto from 'types/api/TicketConnectionDetailsDto';
import TicketWithConnection from 'types/TicketWithConnection';
import TicketDto from 'types/api/TicketDto';

interface TicketConnectedElementsResponse {
  error: boolean;
  error_message: string;
  connected_tickets: Record<number, TicketConnectionDetailsDto>;
}

export const fetchTicketsConnectedElements = async (ticketIds: number[]) => {
  try {
    if (!ticketIds || ticketIds.length === 0) {
      return [];
    }

    return (await API.get<TicketConnectedElementsResponse>(`/tickets-connected-elements/${ticketIds.join(',')}`)).data
      .connected_tickets;
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

interface RfpResponseTicketsResponse {
  error: boolean;
  error_message: string;
  ticket_list: TicketDto[];
}

export const fetchTickets = async () => {
  try {
    return (await API.get<RfpResponseTicketsResponse>(`/ticket-list/`)).data.ticket_list;
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

export const fetchTicketsWithConnections = async (): Promise<TicketWithConnection[]> => {
  const tickets = await fetchTickets();

  const ticketIdsWithConnections = tickets
    .filter((ticket) => ticket.traro_element_response_id)
    .map((ticket) => ticket.id);

  const ticketConnections = await fetchTicketsConnectedElements(ticketIdsWithConnections);

  return tickets.map((ticket) => ({
    ...ticket,
    connection: ticketConnections[ticket.id],
  }));
};

const getQueryKey = () => ['tickets'];

const useTickets = () => useQuery(getQueryKey(), fetchTicketsWithConnections);

useTickets.getQueryKey = getQueryKey;
useTickets.queries = {
  fetchTickets,
  fetchTicketsConnectedElements,
  fetchTicketsWithConnections,
} as const;

export default useTickets;
