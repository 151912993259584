import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TraroElementComment from 'types/api/TraroElementComment';

import useFormattedDateTime from './hooks/useFormattedDateTime';

import './Comment.scss';

export interface CommentProps {
  comment: TraroElementComment;
}

const Comment: FC<CommentProps> = ({ comment }) => {
  const { t } = useTranslation();
  const dateTime = useFormattedDateTime(comment.comment_time);
  const avatarInitials = useMemo(() => {
    const name = comment.commenter_name ?? t('pages.traroElement.comments.defaultCommenterName');
    const [first, second] = name.split(' ');

    if (second === undefined) {
      return first[0].toUpperCase();
    }

    return `${first[0]}${second[0]}`.toUpperCase();
  }, [t, comment]);

  return (
    <div className="enkrateia-comment">
      <div className={`avatar ${comment.commenter_user_type}`}>{avatarInitials}</div>
      <div className="comment">
        <span className="name">{comment.commenter_name ?? t('pages.traroElement.comments.defaultCommenterName')}</span>
        <span className="time">{dateTime}</span>
        <span className="content">{comment.response_comment}</span>
      </div>
    </div>
  );
};

export default Comment;
