import React, { FC, useId } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAvailableRFPs, useOrganizations } from 'api';
import { Form, Formik } from 'formik';

import TraroElementResponse from 'types/api/TraroElementResponse';
import KliparoModal from 'components/Modal/components/KliparoModal/KliparoModal';
import LoaderOverlay from 'components/LoaderOverlay/LoaderOverlay';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import LinkWithIcon from 'components/LinkWithIcon/LinkWithIcon';
import FormikSelectInput from 'components/forms/formik/FormikSelectInput/FormikSelectInput';
import FormikTextAreaInput from 'components/forms/formik/FormikTextAreaInput/FormikTextAreaInput';
import FormikDatePickerInput from 'components/forms/formik/FormikDatePickerInput/FormikDatePickerInput';
import FormikUploadAttachment from 'components/forms/formik/FormikUploadAttachment/FormikUploadAttachment';
import Button from 'components/Button/Button';
import Tooltip from 'components/Tooltip/Tooltip';
import { HelpIcon } from 'components/SvgIcons';
import useSeverityOptions from 'hooks/useSeverityOptions';
import useCategoryOptions from 'hooks/useCategoryOptions';
import useUser from 'hooks/useUser';

import useQuestionNumberOptions from './hooks/useQuestionNumberOptions';
import useInitialValues from './hooks/useInitialValues';
import useSchema from './hooks/useSchema';
import useTicketSubmit from './hooks/useTicketSubmit';
import AssignedUserSelector from './components/AssignedUserSelector/AssignedUserSelector';

import './CreateTicketModal.scss';
import CommonTransComponents from '../../../../i18n/CommonTransComponents';

export interface CreateTicketModalProps {
  onClose: () => void;
  traroElementResponse?: TraroElementResponse;
  rfpRelated?: boolean;
}

const CreateTicketModal: FC<CreateTicketModalProps> = ({ onClose, traroElementResponse, rfpRelated }) => {
  const { t } = useTranslation();
  const { isProponent, isReviewer } = useUser();
  const { data: organizations = [] } = useOrganizations(!isReviewer);
  const { data: availableRFPs } = useAvailableRFPs(undefined);
  const selectableSeverityOptions = useSeverityOptions();
  const selectableCategoryOptions = useCategoryOptions();

  const questionNumberOptions = useQuestionNumberOptions(rfpRelated);

  const initialValues = useInitialValues(
    questionNumberOptions,
    traroElementResponse,
    availableRFPs,
    selectableSeverityOptions,
    selectableCategoryOptions,
    rfpRelated,
  );
  const schema = useSchema();
  const [onSubmit, loading, isSuccess, createdTicketId] = useTicketSubmit();

  const disabled = loading || isSuccess;

  const id = useId();

  return (
    <KliparoModal
      className="enkrateia-create-ticket-modal"
      onClose={onClose}
      title={<>{!isSuccess && t('common.tickets.title')}</>}
    >
      {isSuccess && (
        <div className="success-content">
          <div>
            {t('common.tickets.titleCreated')}
            <LinkWithIcon color="#2c2b63" to={`/ticket/${createdTicketId}`}>
              {createdTicketId.toString().padStart(6, '0')}
            </LinkWithIcon>
          </div>
          <Button variant="primary" onClick={onClose}>
            {t('common.ok')}
          </Button>
        </div>
      )}
      {!isSuccess && (
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
          key={JSON.stringify(availableRFPs).substring(0, 20)}
        >
          <Form>
            <div className="ticket-details">
              <label htmlFor={`${id}-organization`}>{t('common.tickets.organization')}</label>
              <FormikSelectInput
                fullWidth
                id={`${id}-organization`}
                required
                name="organization"
                options={organizations.map((organization) => ({
                  value: organization.id,
                  label: organization.name,
                  meta: organization,
                }))}
                disabled={isProponent || disabled}
                variant="filled"
              />
              <label htmlFor={`${id}-questionNumber`}>{t('common.tickets.linkedCriterion')}</label>
              <FormikSelectInput
                fullWidth
                id={`${id}-questionNumber`}
                name="questionNumber"
                options={questionNumberOptions}
                variant="filled"
                disabled={disabled}
              />
              <label htmlFor={`${id}-ticketTitle`}>
                {t('common.tickets.ticketDescription')}*
                <Tooltip
                  tooltip={
                    <Trans i18nKey="common.tickets.ticketDescriptionHelp" components={CommonTransComponents}>
                      common.tickets.ticketDescriptionHelp
                    </Trans>
                  }
                >
                  <HelpIcon color="#2D2C64" size={25} />
                </Tooltip>
              </label>
              <FormikTextAreaInput
                id={`${id}-ticketTitle`}
                name="ticketTitle"
                variant="filled"
                disabled={disabled}
                autoHeight
                maxHeight="30vh"
              />
              <label htmlFor={`${id}-assignedTo`}>{t('common.tickets.assignedTo')}</label>
              <AssignedUserSelector
                id={`${id}-assignedTo`}
                availableRfps={availableRFPs}
                disabled={disabled}
                responseElementId={traroElementResponse?.id}
              />
              <label htmlFor={`${id}-dueDate`}>{t('common.tickets.dueDate')}</label>
              <FormikDatePickerInput
                id={`${id}-dueDate`}
                name="dueDate"
                variant="filled"
                disabled={disabled || !isReviewer}
              />
              {isReviewer && (
                <>
                  <label htmlFor={`${id}-severity`}>{t('common.tickets.severity')}:</label>
                  <FormikSelectInput
                    id={`${id}-severity`}
                    variant="filled"
                    name="severity"
                    fullWidth
                    options={selectableSeverityOptions}
                    disabled={disabled}
                  />
                  <label htmlFor={`${id}-category`}>{t('common.tickets.category')}:</label>
                  <FormikSelectInput
                    id={`${id}-category`}
                    variant="filled"
                    name="category"
                    placeholder={t('common.tickets.categoryPlaceholder')}
                    fullWidth
                    options={selectableCategoryOptions}
                    disabled={disabled || !isReviewer}
                  />
                </>
              )}
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>&nbsp;</label>
              <FormikUploadAttachment.Display name="attachment" clearable />
            </div>
            <div className="ticket-summary">
              {!isSuccess && (
                <div>
                  <FormikUploadAttachment name="attachment" />
                  <SubmitButton variant="primary">{t('common.tickets.submit')}</SubmitButton>
                </div>
              )}
              {isSuccess && (
                <Button variant="primary" type="button" onClick={onClose}>
                  {t('common.ok')}
                </Button>
              )}
            </div>
            <LoaderOverlay open={availableRFPs === undefined} />
          </Form>
        </Formik>
      )}
    </KliparoModal>
  );
};

export default CreateTicketModal;
