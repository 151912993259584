import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useSchema = (isEligibilityRequired = false) => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      yup.object().shape({
        programEligibility: isEligibilityRequired
          ? yup.object().nullable().required(t('errors.required'))
          : yup.object().nullable(),
      }),
    [t, isEligibilityRequired],
  );
};

export default useSchema;
