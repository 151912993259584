import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch as useRawDispatch, useSelector as useRawSelector } from 'react-redux';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import auth from './auth/slice';
import rfp from './rfp/slice';
import rfpResponse from './rfpResponse/slice';
import modals from './modals/slice';
import arch from './arch/slice';
import elementHistory from './elementHistory/slice';
import rfpReviewerList from './rfpReviewerList/slice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['modals', 'rfp', 'rfpResponse', 'arch', 'elementHistory'],
};

const rfpPersistConfig = {
  key: 'rfp',
  version: 1,
  storage,
  blacklist: ['data', 'loading', 'error'],
};

const combinedReducer = combineReducers({
  auth,
  rfp: persistReducer(rfpPersistConfig, rfp),
  rfpResponse,
  modals,
  arch,
  elementHistory,
  rfpReviewerList,
});

const rootReducer: typeof combinedReducer = (state, action) => {
  if (action.type === 'auth/logout/fulfilled') {
    state = undefined;
  }

  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useDispatch: () => AppDispatch = useRawDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = useRawSelector;

export default store;
