import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

import Header from 'components/Header/Header';
import SideBar from 'components/SideBar/SideBar';
import GoBackPanel from 'components/GoBackPanel/GoBackPanel';
import ProblemSpringerArc from 'components/ProblemSpringerArc/ProblemSpringerArc';
import Scoring from 'components/Scoring/Scoring';
import Tabs, { TabsRef } from 'components/Tabs/Tabs';
import ArchBricksSidePanel from 'components/ArchBricksSidePanel/ArchBricksSidePanel';

import './TraroElementPage.scss';

import Submit from './components/Submit/Submit';
import useTraroElement from './hooks/useTraroElement';
import ElementContext from './components/ElementContext/ElementContext';
import QuestionResponse from './components/QuestionResponse/QuestionResponse';
import TraroElementSubmittedModal from './components/TraroElementSubmittedModal/TraroElementSubmittedModal';
import Comments from './components/Comments/Comments';
import History from './components/History/History';
import useTraroElementForm from './hooks/useTraroElementForm';

export interface TraroElementPageProps {}

const TraroElementPage: FC<TraroElementPageProps> = () => {
  const { t } = useTranslation();

  const { traroElement, traroElementResponse, rfpResponseId, rfpId, traroElementSpecific } = useTraroElement();
  const { schema, initialValues, onSubmit } = useTraroElementForm(traroElement, traroElementResponse);

  const tabsRef = useRef<TabsRef>({ setActiveKey: () => null });

  return (
    <div className="enkrateia-element-page">
      <Header />
      <Formik validationSchema={schema} initialValues={initialValues} onSubmit={onSubmit}>
        <Form className="content">
          <SideBar variant="traro">
            <GoBackPanel
              target={`/rfp/${rfpId}/rfp-response/${rfpResponseId}`}
              label={t('pages.traroElement.goBack')}
            />
            <ProblemSpringerArc title={traroElement.element_name} activeElement={traroElementSpecific} />
            <Scoring
              score={traroElementResponse.question_score ?? '-'}
              maxScore={traroElement.max_score ?? 0}
              scoringRubric={traroElement.scoring_rubric}
            />
            <Submit questionStatus={traroElementResponse.question_status} />
            <History elementId={traroElementResponse.id} traroElement={traroElement} />
          </SideBar>
          <main>
            <div>
              <Tabs initialTab="context" innerRef={tabsRef}>
                <Tabs.Tab tabKey="context" label={t('pages.traroElement.tabContext')}>
                  <ElementContext traroElement={traroElement} questionStatus={traroElementResponse.question_status} />
                </Tabs.Tab>
                <Tabs.Tab tabKey="question" label={t('pages.traroElement.tabQuestion')}>
                  <QuestionResponse traroElement={traroElement} />
                </Tabs.Tab>
                <Tabs.Tab
                  tabKey="comments"
                  label={t('pages.traroElement.tabComments', {
                    count: traroElementResponse.traro_element_comment?.length ?? 0,
                  })}
                >
                  <Comments traroElementResponse={traroElementResponse} />
                </Tabs.Tab>
              </Tabs>
            </div>
          </main>
          <ArchBricksSidePanel title={t('pages.traroElement.sidePanel.title')} />
        </Form>
      </Formik>
      <TraroElementSubmittedModal springerArcTitle={traroElement.element_name} />
    </div>
  );
};

export default TraroElementPage;
