import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InputOption } from 'components/forms/raw/SelectInput';
import TicketSourceType from 'types/TicketSourceType';

const useCategoryOptions = (): InputOption<TicketSourceType>[] => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        value: TicketSourceType.SOURCE_NOT_ASSIGNED,
        label: t(`common.tickets.sources.${TicketSourceType.SOURCE_NOT_ASSIGNED}`),
      },
      {
        value: TicketSourceType.SOURCE_QUESTION_TYPE,
        label: t(`common.tickets.sources.${TicketSourceType.SOURCE_QUESTION_TYPE}`),
      },
      {
        value: TicketSourceType.SOURCE_REMEDIAL_ACTION,
        label: t(`common.tickets.sources.${TicketSourceType.SOURCE_REMEDIAL_ACTION}`),
      },
      {
        value: TicketSourceType.SOURCE_IT_ISSUE,
        label: t(`common.tickets.sources.${TicketSourceType.SOURCE_IT_ISSUE}`),
      },
    ],
    [t],
  );
};

export default useCategoryOptions;
