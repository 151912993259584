import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import ArchElementSpecific from 'types/ArchElementSpecific';
import Note from 'components/Note/Note';
import CommonTransComponents from 'i18n/CommonTransComponents';

import { useArchContextForElement } from '../../context/ArchContext';

import './NoElementSelectedPlaceholder.scss';

export interface NoElementSelectedPlaceholderProps {}

const NoElementSelectedPlaceholder: FC<NoElementSelectedPlaceholderProps> = () => {
  const { t } = useTranslation();
  const { element: springerLeft } = useArchContextForElement(ArchElementSpecific.SPRINGER_LEFT);
  const { element: springerRight } = useArchContextForElement(ArchElementSpecific.SPRINGER_RIGHT);
  const { element: keystone } = useArchContextForElement(ArchElementSpecific.KEYSTONE);

  return (
    <div className="enkrateia-no-element-selected-placeholder">
      <div className="main">
        <h2>{t('pages.projectOverview.archFramework.title')}</h2>
        <p>{t('pages.projectOverview.archFramework.description')}</p>

        <div>
          <h3>{t('pages.projectOverview.archFramework.step1.title')}</h3>
          <Trans
            t={t}
            i18nKey="pages.projectOverview.archFramework.step1.description"
            components={CommonTransComponents}
          />
        </div>

        <div>
          <h3>{t('pages.projectOverview.archFramework.step2.title')}</h3>
          <Trans
            t={t}
            i18nKey="pages.projectOverview.archFramework.step2.description"
            components={CommonTransComponents}
          />
        </div>

        <div>
          <h3>{t('pages.projectOverview.archFramework.step3.title')}</h3>
          <Trans
            t={t}
            i18nKey="pages.projectOverview.archFramework.step3.description"
            components={CommonTransComponents}
          />
        </div>
      </div>
      <Note>
        <p>{t('pages.projectOverview.archFramework.sideNote.title')}</p>
        <div>
          <ol>
            <li>{springerLeft.element_name}</li>
            <li>{springerRight.element_name}</li>
            <li>{keystone.element_name}</li>
          </ol>
          <p>{t('pages.projectOverview.archFramework.sideNote.description')}</p>
        </div>
      </Note>
    </div>
  );
};

export default NoElementSelectedPlaceholder;
