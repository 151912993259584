import { useMemo } from 'react';

import TraroElement from 'types/api/TraroElement';
import ArchElementGeneric from 'types/ArchElementGeneric';

import { useSelector } from 'store';

const compareBricks = (brickA: TraroElement, brickB: TraroElement) => {
  const a = brickA.arch_element_specific.substring(5, 7).padStart(2, '0');
  const b = brickB.arch_element_specific.substring(5, 7).padStart(2, '0');

  return a.localeCompare(b);
};

const useBricks = () => {
  const traroElementList = useSelector((store) => store.rfp.data.traro_element_list);

  return useMemo(
    () =>
      [...traroElementList]
        .filter((element) => element.arch_element_generic === ArchElementGeneric.BRICK)
        .sort(compareBricks),
    [traroElementList],
  );
};

export default useBricks;
