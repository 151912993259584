import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import lydionLogoSmall from 'assets/img/lydionLogoSmall.png';

import './PoweredBy.scss';

export interface PoweredByProps {}

const PoweredBy: FC<PoweredByProps> = () => {
  const { t } = useTranslation();

  return (
    <div className="enkrateia-powered-by">
      {t('common.poweredBy')} <img src={lydionLogoSmall} alt={t('common.lydionLogo') as string} />
    </div>
  );
};

export default PoweredBy;
