import React, { FC } from 'react';
import ArchElementSpecific from 'types/ArchElementSpecific';
import { isCompleted, isUpdateRequired } from 'store/rfpResponse/helpers';

import CheckedCircle from '../CheckedCircle/CheckedCircle';
import ExclamationCircle from '../ExclamationCircle/ExclamationCircle';
import { useArchContextForElement } from '../../../../context/ArchContext';
import useElementSelection from '../../../../hooks/useElementSelection';

import './Text.scss';

export interface TextProps {
  x: number | string;
  y: number | string;
  align: 'start' | 'end';
  id: ArchElementSpecific;
}

const Text: FC<TextProps> = ({ align, x, y, id }) => {
  const { element, response } = useArchContextForElement(id);
  const [isSelected, select] = useElementSelection(id);

  return (
    <svg
      x={x}
      y={y}
      width="260"
      height="25"
      style={{ background: 'red' }}
      opacity={isSelected ? 1 : 0.3}
      onClick={select}
      cursor="pointer"
      className="enkrateia-arch-text"
    >
      {isCompleted(response) && <CheckedCircle x={align === 'end' ? '235' : '0'} y="0" />}
      {isUpdateRequired(response) && <ExclamationCircle x={align === 'end' ? '235' : '0'} y="0" />}
      <text x={align === 'end' ? '225' : '35'} y="18" fill="#fff" textAnchor={align}>
        {element.element_name}
      </text>
    </svg>
  );
};

export default Text;
