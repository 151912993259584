import React, { FC, useCallback } from 'react';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loader from 'components/Loader/Loader';
import Button from 'components/Button/Button';
import { useDispatch, useSelector } from 'store';
import { openModal } from 'store/modals';
import { saveRfpResponse } from 'store/rfpResponse';
import RFPSubmitResponseStatus from 'types/RFPSubmitResponseStatus';
import RFPResponseStatus from 'types/RFPResponseStatus';

import './SubmitForReview.scss';

export interface SubmitProps {}

const SubmitForReview: FC<SubmitProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const updating = useSelector((store) => store.rfpResponse.updating);
  const responseStatus = useSelector((store) => store.rfpResponse.data.rfp_response_status);
  const submitResponseStatus = useSelector((store) => store.rfpResponse.data.rfp_submit_response_status);
  const count = useSelector((store) => store.rfpResponse.data.submissions_remaining);
  const rfpId = useSelector((store) => store.rfp.data.id);

  const handleSubmit = useCallback(async () => {
    try {
      await dispatch(saveRfpResponse({ rfpId: rfpId })).unwrap();
      dispatch(openModal({ modal: 'rfpSubmitModal', data: undefined }));
    } catch {
      // Shh!
    }
  }, [dispatch, rfpId]);

  return (
    <div className="enkrateia-submit-for-review">
      <Button
        disabled={submitResponseStatus !== RFPSubmitResponseStatus.ALLOWED || updating}
        variant="primary"
        onClick={handleSubmit}
      >
        {updating ? <Loader /> : t('pages.projectOverview.sidePanel.submitForReview')}
      </Button>
      {submitResponseStatus === RFPSubmitResponseStatus.INCOMPLETE_QUESTIONS && (
        <span>{t('pages.projectOverview.sidePanel.missingQuestions')}</span>
      )}
      <span>{t('pages.projectOverview.sidePanel.submissionsRemaining', { count })}</span>
      {responseStatus === RFPResponseStatus.IN_REVIEW && (
        <span>{t('pages.projectOverview.sidePanel.awaitingReview')}</span>
      )}
      {/*<Link to="/history">{t('pages.projectOverview.sidePanel.history')}</Link>*/}
    </div>
  );
};

export default SubmitForReview;
