import { useMutation } from '@tanstack/react-query';

import API from 'services/api';
import FormSubmitError from 'errors/FormSubmitError';
import { AxiosError } from 'axios';

interface ResetPasswordParams<T = any> {
  email: string;
  token: string;
  meta: T;
}

interface ResetPasswordResponse {}

const resetPassword = async <T = any>({ email, token }: ResetPasswordParams<T>) => {
  try {
    return (await API.post<ResetPasswordResponse>('/reset-password', { email, captcha_token: token })).data;
  } catch (e) {
    let message = 'An error occurred while sending request to the server. Please try again.';

    if (e instanceof AxiosError && e.response?.data.error_message !== undefined) {
      message = e.response.data.error_message;
    }

    throw new FormSubmitError(message);
  }
};

interface UseResetPasswordMutationProps<T> {
  onSuccess?: (data: ResetPasswordResponse, variables: ResetPasswordParams<T>) => Promise<void>;
  onError?: (error: Error, variables: ResetPasswordParams<T>) => Promise<void>;
}

const useResetPasswordMutation = <T = any>({ onSuccess, onError }: UseResetPasswordMutationProps<T>) =>
  useMutation<ResetPasswordResponse, FormSubmitError, ResetPasswordParams<T>>(resetPassword, {
    onSuccess,
    onError,
  });

export default useResetPasswordMutation;
