import React, { FC, useMemo } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Modal, { ModalProps } from 'components/Modal/Modal';

import DetailsFormStep from './components/DetailsFormStep/DetailsFormStep';
import DisclaimerFormStep from './components/DisclaimerFormStep/DisclaimerFormStep';
import SuccessStep from './components/SuccessStep/SuccessStep';
import useInitialValues, { NewAssessmentForm } from './hooks/useInitialValues';
import useNewAssessmentSubmit from './hooks/useNewAssessmentSubmit';
import useSchema from './hooks/useSchema';

import './NewAssessmentModal.scss';

export type NewAssessmentModalProps = ModalProps & {
  isSaving: boolean;
};

const NewAssessmentModalContent: FC<NewAssessmentModalProps> = ({ isSaving, isOpen, onRequestClose }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<NewAssessmentForm>();

  const title = useMemo(() => {
    if (values.page === 'success') {
      return t('pages.dashboard.createAssessment.successTitle');
    }

    if (values.page === 'details') {
      return t('pages.dashboard.createAssessment.newAssessment');
    }

    return t('pages.dashboard.createAssessment.assessmentDisclaimer');
  }, [values.page, t]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      variant="kliparo"
      className="enkrateia-new-assessment-modal"
      title={title}
    >
      <div className="pages">
        {values.page === 'details' && <DetailsFormStep isSaving={isSaving} />}
        {values.page === 'disclaimer' && <DisclaimerFormStep />}
        {values.page === 'success' && <SuccessStep onClose={onRequestClose} />}
      </div>
    </Modal>
  );
};

const AssessmentForm: FC<ModalProps> = ({ isOpen, onRequestClose }) => {
  const initialValues = useInitialValues();
  const schema = useSchema();
  const [saveForm, isSaving] = useNewAssessmentSubmit();

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={saveForm}>
      <Form>
        <NewAssessmentModalContent isOpen={isOpen} onRequestClose={onRequestClose} isSaving={isSaving} />
      </Form>
    </Formik>
  );
};

const NewAssessmentModal: FC<ModalProps> = ({ isOpen, onRequestClose }) => {
  if (!isOpen) {
    return null;
  }

  return <AssessmentForm isOpen={isOpen} onRequestClose={onRequestClose} />;
};

export default NewAssessmentModal;
