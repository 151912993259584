import QuestionStatus from 'types/QuestionStatus';

const statusesList: QuestionStatus[] = [
  QuestionStatus.DONE,
  QuestionStatus.NOT_REQUIRED,
  QuestionStatus.IN_PROGRESS,
  QuestionStatus.BEGIN,
  QuestionStatus.REVIEW_ONLY,
  QuestionStatus.UPDATE_REQUIRED,
  QuestionStatus.REVIEW_COMPLETE,
];

const statusesRanking: QuestionStatus[] = [
  QuestionStatus.UPDATE_REQUIRED,
  QuestionStatus.INCOMPLETE,
  QuestionStatus.IN_PROGRESS,
  QuestionStatus.BEGIN,
  QuestionStatus.REVIEW_ONLY,
  QuestionStatus.REVIEW_COMPLETE,
  QuestionStatus.DONE,
  QuestionStatus.NOT_REQUIRED,
];

export const getSectionStatus = (statuses: (QuestionStatus | null | undefined)[]): QuestionStatus => {
  if (statuses.length === 0) {
    return QuestionStatus.BEGIN;
  }

  const filteredStatuses = statuses.filter((element) =>
    statusesList.includes(element as QuestionStatus),
  ) as QuestionStatus[];

  if (filteredStatuses.length === 0) {
    return QuestionStatus.BEGIN;
  }

  const lowestStatusIndex = Math.min(...filteredStatuses.map((status) => statusesRanking.indexOf(status)));

  return statusesRanking[lowestStatusIndex];
};
