/* eslint-disable @typescript-eslint/naming-convention */
import axios from 'axios';
import { responseErrorInterceptor, responseInterceptor } from './helpers/interceptors';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

axiosInstance.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

export interface ApiResponse {
  error: boolean;
  error_message?: string;
}

export default axiosInstance;
