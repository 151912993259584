import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useFormikContext } from 'formik';

import TraroElement from 'types/api/TraroElement';
import TraroElementResponse from 'types/api/TraroElementResponse';
import QuestionStatus from 'types/QuestionStatus';

import { AutoSaveIndicator, useAutoSaveContext } from 'components/AutoSaveWatcher';
import Note from 'components/Note/Note';
import Button from 'components/Button/Button';
import { useDispatch, useSelector } from 'store';
import RFPResponseStatus from 'types/RFPResponseStatus';
import { canAnswerRFPQuestions, canMarkAsReviewed, canSubmitAnswers } from 'services/permissions';
import useUser from 'hooks/useUser';

import KliparoQuestion from './components/KliparoQuestion/KliparoQuestion';
import { FormValues } from '../../hooks/useInitialValues';

import './QuestionResponse.scss';
import UpdateResponseStatusAction from './components/UpdateResponseStatusAction/UpdateResponseStatusAction';
import useIsUpdateResponseStatusActionVisible from './components/UpdateResponseStatusAction/hooks/useIsUpdateResponseStatusActionVisible';
import { updateKliparoRfpElementStatus } from '../../../../store/rfpResponse';

export interface QuestionResponseProps {
  traroElement: TraroElement;
  rfpElementResponse: TraroElementResponse;
}

const QuestionResponse: FC<QuestionResponseProps> = ({ traroElement, rfpElementResponse }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useUser();
  const { submitForm, values, isValid } = useFormikContext<FormValues>();
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const ref = useRef<HTMLParagraphElement>(null);
  const isUpdateResponseStatusActionVisible = useIsUpdateResponseStatusActionVisible(rfpElementResponse);
  const rfpId = useSelector((state) => state.rfp.data.id);
  const rfpResponse = useSelector((state) => state.rfpResponse.data);

  const { loading: isAutoSaveLoading } = useAutoSaveContext();

  const handleSubmit = useCallback(() => {
    if (canSubmitAnswers(user, rfpResponse) && isValid && rfpElementResponse.id !== undefined) {
      let status = rfpElementResponse.question_status;

      if (rfpResponse.rfp_response_status === RFPResponseStatus.IN_REVIEW) {
        status = QuestionStatus.REVIEW_ONLY;
      } else if (rfpResponse.rfp_response_status === RFPResponseStatus.OPEN) {
        if (values.questionStatus === QuestionStatus.DONE) {
          status = QuestionStatus.IN_PROGRESS;
        } else if (
          values.questionStatus === QuestionStatus.IN_PROGRESS ||
          values.questionStatus === QuestionStatus.BEGIN ||
          values.questionStatus === QuestionStatus.UPDATE_REQUIRED
        ) {
          status = QuestionStatus.DONE;
        }
      }

      dispatch(
        updateKliparoRfpElementStatus({
          rfpId,
          rfpResponseId: rfpResponse.id,
          traroElementId: rfpElementResponse.id,
          status,
        }),
      );
    } else {
      setWasSubmitted(true);
      submitForm();

      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [
    user,
    rfpId,
    rfpResponse,
    isValid,
    rfpElementResponse.id,
    rfpElementResponse.question_status,
    dispatch,
    values.questionStatus,
    submitForm,
  ]);

  const sortedQuestions = useMemo(
    () => [...traroElement.kliparo_question_list].sort((a, b) => a.question_number - b.question_number),
    [traroElement.kliparo_question_list],
  );

  return (
    <div className="enkrateia-kliparo-question-response">
      <Note variant="kliparo">
        <div>
          <ReactMarkdown skipHtml linkTarget="_blank" className="question-1">
            {traroElement.question_text1}
          </ReactMarkdown>
          {traroElement.question_text2 && (
            <ReactMarkdown linkTarget="_blank" className="question-2">
              {traroElement.question_text2}
            </ReactMarkdown>
          )}
        </div>
      </Note>

      <AutoSaveIndicator />

      <div className="form-data">
        {sortedQuestions.map((question) => (
          <KliparoQuestion
            key={question.id}
            disabled={!canAnswerRFPQuestions(user, rfpResponse, rfpElementResponse)}
            question={question}
          />
        ))}
      </div>

      {canMarkAsReviewed(user, rfpResponse, rfpElementResponse) && (
        <Button
          variant={rfpElementResponse.question_status === QuestionStatus.DONE ? 'primary' : 'tertiary-filled'}
          onClick={handleSubmit}
          disabled={rfpElementResponse.question_status === QuestionStatus.REVIEW_ONLY || isAutoSaveLoading}
        >
          {rfpElementResponse.question_status === QuestionStatus.DONE
            ? t('pages.traroElement.markAsInProgress')
            : t('pages.traroElement.markAsComplete')}
        </Button>
      )}
      {!isValid && canSubmitAnswers(user, rfpResponse) && wasSubmitted && (
        <p className="mark-as-complete-error" ref={ref}>
          {t('pages.traroElement.cannotMarkAsComplete')}
        </p>
      )}

      {isUpdateResponseStatusActionVisible && (
        <div className="score">
          <UpdateResponseStatusAction rfpElementResponse={rfpElementResponse} traroElement={traroElement} />
        </div>
      )}
    </div>
  );
};

export default QuestionResponse;
