import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'store';
import { setMode } from 'store/rfp';
import RfpMode from 'types/RfpMode';

import './ArchListSwitch.scss';

export interface ArchListSwitchProps {}

const ArchListSwitch: FC<ArchListSwitchProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mode = useSelector((store) => store.rfp.mode);

  const setArchMode = useCallback(() => dispatch(setMode(RfpMode.ARCH)), [dispatch]);
  const setListMode = useCallback(() => dispatch(setMode(RfpMode.LIST)), [dispatch]);

  return (
    <div className="enkrateia-arch-list-switch">
      <button type="button" className={mode === RfpMode.ARCH ? 'active' : ''} onClick={setArchMode}>
        {t('pages.projectOverview.mainPanel.switch.arch')}
      </button>
      <button type="button" className={mode === RfpMode.LIST ? 'active' : ''} onClick={setListMode}>
        {t('pages.projectOverview.mainPanel.switch.list')}
      </button>
    </div>
  );
};

export default ArchListSwitch;
