import React, { FC } from 'react';
import ArchElementSpecific from 'types/ArchElementSpecific';
import ArchElementGeneric from 'types/ArchElementGeneric';
import { isCompleted } from 'store/rfpResponse/helpers';

import { useArchContextForElement } from '../../../../context/ArchContext';
import useElementSelection from '../../../../hooks/useElementSelection';

export interface KeystoneProps {
  x: number | string;
  y: number | string;
}

const Keystone: FC<KeystoneProps> = ({ x, y }) => {
  const { response } = useArchContextForElement(ArchElementSpecific.KEYSTONE);
  const completed = isCompleted(response);
  const [isSelected, select] = useElementSelection(ArchElementSpecific.KEYSTONE);

  return (
    <svg x={x} y={y}>
      <path
        className={`enkrateia-arch-keystone ${ArchElementGeneric.KEYSTONE} ${ArchElementSpecific.KEYSTONE}`}
        d="M26.7483 133.986L2.42913 13.9862C1.17366 7.79126 5.90899 2 12.2299 2H108.912C115.186 2 119.911 7.71062 118.735 13.8736L95.8466 133.874C94.9472 138.589 90.824 142 86.0237 142H36.5491C31.7919 142 27.6932 138.649 26.7483 133.986Z"
        stroke="#B98765"
        fill={completed ? '#B98765' : 'transparent'}
        strokeWidth="3"
        opacity={isSelected ? 1 : 0.3}
        onClick={select}
        cursor="pointer"
      />
    </svg>
  );
};

export default Keystone;
