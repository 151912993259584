import React, { forwardRef } from 'react';

import useFormikConnection, { TouchTrigger, WithoutInjectedProps } from '../hooks/useFormikConnection';
import DatePickerInput, { DatePickerInputProps } from '../../raw/DatePickerInput';
import { DateTime } from 'luxon';

export interface FormikTextInputProps extends WithoutInjectedProps<DatePickerInputProps, 'onChange'> {
  name: string;
  touchedTrigger?: TouchTrigger;
}

const FormikDatePickerInput = forwardRef<HTMLInputElement, FormikTextInputProps>(({ name, ...props }, ref) => {
  const { setValue, ...other } = useFormikConnection<DateTime | null, HTMLInputElement>(name, props);

  return <DatePickerInput {...props} {...other} ref={ref} label={props.label} onChange={setValue} />;
});

export default FormikDatePickerInput;
