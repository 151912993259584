import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import SubmissionHistoryEntry from 'types/api/SubmissionHistoryEntry';

export interface ScoringEntryProps {
  entry: SubmissionHistoryEntry;
}

const SubmissionEntry: FC<ScoringEntryProps> = ({ entry: { final, number } }) => {
  const { t } = useTranslation();

  if (final) {
    return <>{t('pages.traroElement.historyModal.submission.final')}</>;
  }

  return <>{t('pages.traroElement.historyModal.submission.nth', { count: number, ordinal: true })}</>;
};

export default SubmissionEntry;
