import { createContext, Dispatch, SetStateAction, useContext } from 'react';

/**
 * Contains information about currently active tab.
 */
const TabsContext = createContext<{
  activeKey: string;
  setActiveKey: Dispatch<SetStateAction<string>>;
}>({
  activeKey: '',
  setActiveKey: () => null,
});

export const useTabsContext = () => useContext(TabsContext);

export default TabsContext;
