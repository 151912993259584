import { useCallback } from 'react';
import { FormikHelpers } from 'formik';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRfpResponsesWithStats } from 'api';

import { createRfpResponse } from 'services/rfp';

import { NewAssessmentForm } from './useInitialValues';

interface NewAssessmentCreationProps {
  values: NewAssessmentForm;
  helpers: FormikHelpers<NewAssessmentForm>;
}

const useNewAssessmentSubmit = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    async ({ values }: NewAssessmentCreationProps) =>
      createRfpResponse(values.rfp?.value as number, values.programName),
    {
      onSuccess: async (unused, variables) => {
        variables.helpers.setFieldValue('page', 'success');
        await queryClient.invalidateQueries({ queryKey: useRfpResponsesWithStats.getQueryKey() });
        await queryClient.refetchQueries({ queryKey: useRfpResponsesWithStats.getQueryKey() });
      },
      onError: (error: Error, variables) => {
        variables.helpers.setFieldValue('page', 'details');

        setTimeout(() => {
          variables.helpers.setFieldError('programName', error.message);
        }, 300);
      },
    },
  );

  const saveForm = useCallback(
    (values: NewAssessmentForm, helpers: FormikHelpers<NewAssessmentForm>) => mutateAsync({ values, helpers }),
    [mutateAsync],
  );

  return [saveForm, isLoading] as const;
};

export default useNewAssessmentSubmit;
