import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import TraroElement from 'types/api/TraroElement';

import FormikTextAreaInput from 'components/forms/formik/FormikTextAreaInput/FormikTextAreaInput';
import FormikTextInput from 'components/forms/formik/FormikTextInput/FormikTextInput';
import Note from 'components/Note/Note';
import useUser from 'hooks/useUser';

import './QuestionResponse.scss';
import { TraroElementForm } from '../../hooks/useTraroElementForm';

export interface QuestionResponseProps {
  traroElement: TraroElement;
}

const QuestionResponse: FC<QuestionResponseProps> = ({ traroElement }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<TraroElementForm>();
  const { isReviewer } = useUser();

  return (
    <div className="enkrateia-question-response">
      <Note>
        <div>
          <ReactMarkdown skipHtml linkTarget="_blank" className="question-1">
            {traroElement.question_text1}
          </ReactMarkdown>
          <ReactMarkdown linkTarget="_blank" className="question-2">
            {traroElement.question_text2}
          </ReactMarkdown>
        </div>
      </Note>

      <div className="form-data">
        <FormikTextAreaInput name="response" rows={15} maxLength={1000} disabled={isReviewer} />
        <span>
          ({values.response.length} / 1000 {t('pages.traroElement.characters')})
        </span>
      </div>

      {isReviewer && (
        <div className="score">
          <FormikTextInput
            label={t('pages.traroElement.score')}
            name="score"
            type="number"
            min={0}
            max={traroElement.max_score}
          />
        </div>
      )}
    </div>
  );
};

export default QuestionResponse;
