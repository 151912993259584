import { useMutation, useQueryClient } from '@tanstack/react-query';

import API from 'services/api';
import FormSubmitError from 'errors/FormSubmitError';

import { useTicket } from './index';
import { useUploadAttachmentMutation } from '../attachment';

interface AddTicketParams<T = any> {
  ticketId: number;
  ticketComment: string;
  published: boolean;
  attachment?: File | null;
  attachmentId?: number;
  meta: T;
}

interface AddTicketApiParams {
  ticket_comment: string;
  published: boolean;
  attachment_id?: number;
}

interface SaveCommentResponse {}

const addTicketComment = async <T = any>({
  ticketId,
  ticketComment,
  published,
  attachment,
  attachmentId,
}: AddTicketParams<T>) => {
  try {
    const data: AddTicketApiParams = { ticket_comment: ticketComment, published };

    if (attachmentId === undefined && attachment) {
      const { id } = await useUploadAttachmentMutation.queries.uploadAttachment(attachment);
      data.attachment_id = id;
    } else if (attachmentId !== undefined) {
      data.attachment_id = attachmentId;
    }

    return (await API.post<SaveCommentResponse>(`/ticket/${ticketId}/comment`, data)).data;
  } catch (e) {
    if (e instanceof FormSubmitError) {
      throw e;
    }

    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

interface UseAddTicketCommentMutationProps<T> {
  onSuccess?: (data: SaveCommentResponse, variables: AddTicketParams<T>) => Promise<void>;
  onError?: (error: Error, variables: AddTicketParams<T>) => Promise<void>;
}

const useAddTicketCommentMutation = <T = any>({ onSuccess, onError }: UseAddTicketCommentMutationProps<T>) => {
  const queryClient = useQueryClient();

  return useMutation<SaveCommentResponse, FormSubmitError, AddTicketParams<T>>(addTicketComment, {
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({ queryKey: useTicket.getQueryKey(variables.ticketId) });
      await queryClient.refetchQueries({ queryKey: useTicket.getQueryKey(variables.ticketId) });

      if (onSuccess) {
        await onSuccess(data, variables);
      }
    },
    onError: async (error: Error, variables) => {
      if (onError) {
        await onError(error, variables);
      }
    },
  });
};

useAddTicketCommentMutation.queries = {
  addTicketComment,
} as const;

export default useAddTicketCommentMutation;
