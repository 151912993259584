import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useRecentTickets } from 'api';
import Table from 'components/Table/Table';
import CreateTicketButton from 'components/CreateTicketButton/CreateTicketButton';
import LinkWithIcon from 'components/LinkWithIcon/LinkWithIcon';

import useColumns from './hooks/useColumns';

import './RecentTickets.scss';

export interface RecentTicketsProps {}

const RecentTickets: FC<RecentTicketsProps> = () => {
  const { t } = useTranslation();
  const columns = useColumns();
  const { data } = useRecentTickets();

  return (
    <div className="enkrateia-dashboard-recent-tickets">
      <div className="title">
        <div className="left">
          <h2>{t('pages.dashboard.tickets.title')}</h2>
          <LinkWithIcon to="/tickets" color="#4A4A4A">
            {t('pages.dashboard.tickets.allTicketsLink')}
          </LinkWithIcon>
        </div>
        <div>
          <CreateTicketButton />
        </div>
      </div>
      <div className="content">
        <Table data={data} columns={columns} />
      </div>
    </div>
  );
};

export default RecentTickets;
