import React, { FC, ReactNode, useMemo } from 'react';
import { useFormikContext } from 'formik';

import Button, { ButtonProps } from 'components/Button/Button';
import Loader from 'components/Loader/Loader';

import './SubmitButton.scss';

export interface SubmitButtonProps extends Omit<ButtonProps, 'type'> {
  children: ReactNode;
  submitVariant?: 'function' | 'form';
}

const SubmitButton: FC<SubmitButtonProps> = ({
  children,
  submitVariant = 'function',
  className = '',
  disabled,
  ...props
}) => {
  const { isSubmitting, submitForm } = useFormikContext();
  const configProps = useMemo(() => {
    if (submitVariant === 'function') {
      return {
        type: 'button',
        onClick: submitForm,
      } as const;
    }

    return {
      type: 'submit',
    } as const;
  }, [submitForm, submitVariant]);

  return (
    <Button
      {...props}
      {...configProps}
      className={`enkrateia-submit-button ${className}`}
      disabled={disabled || isSubmitting}
    >
      {isSubmitting ? <Loader /> : children}
    </Button>
  );
};

export default SubmitButton;
