import API from 'services/api';

import RFPResponseStats from 'types/api/RFPResponseStats';
import FormSubmitError from 'errors/FormSubmitError';
import { useQuery } from '@tanstack/react-query';

const fetchRfpResponseStats = async (rfpResponsesIds: number[]): Promise<Record<number, RFPResponseStats>> => {
  try {
    return (await API.get<Record<number, RFPResponseStats>>(`/rfp-response-stats/${rfpResponsesIds.join(',')}`)).data;
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

const getQueryKey = (responseIds?: number[]) => {
  const key = ['rfpResponsesStats'];

  if (responseIds !== undefined) {
    key.push(responseIds.join(','));
  }

  return key;
};

const useRfpResponsesStats = (responseIds: number[]) =>
  useQuery(getQueryKey(responseIds), () => fetchRfpResponseStats(responseIds));

useRfpResponsesStats.getQueryKey = getQueryKey;
useRfpResponsesStats.queries = {
  fetchRfpResponseStats,
} as const;

export default useRfpResponsesStats;
