import { useMemo } from 'react';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import useUser from 'hooks/useUser';
import TraroElement from 'types/api/TraroElement';
import QuestionType from 'types/QuestionType';
import { canSubmitAnswers } from 'services/permissions';
import { useSelector } from 'store';

const getQuestionSchema = (questionType: string, t: TFunction) => {
  switch (questionType) {
    case QuestionType.TEXT:
    case QuestionType.LONG_TEXT:
      return yup
        .string()
        .min(1, t('errors.minLength', { count: 1 }))
        .required(t('errors.minLength', { count: 1 }));
    case QuestionType.URL:
      return yup
        .string()
        .min(1, t('errors.minLength', { count: 1 }))
        .when({
          is: 'N/A',
          then: yup.string().nullable(),
          otherwise: yup
            .string()
            .matches(/^(((http)s?\:)?\/\/)?([a-z0-9][\w\.\-]+\.)+([a-zA-Z0-9]){2,}(\/.*)?$/, t('errors.urlOrNa'))
            .required(t('errors.minLength', { count: 1 })),
        });
    case QuestionType.CHECKBOX:
      return yup.object();
    case QuestionType.SELECTION:
      return yup.object().nullable().required(t('errors.required'));
    case QuestionType.DISPLAY_ONLY:
      return yup.mixed().nullable();
    default:
      throw new Error('Invalid question type: ' + questionType);
  }
};

const useSchema = (traroElement: TraroElement) => {
  const user = useUser();
  const { t } = useTranslation();
  const rfpResponse = useSelector((state) => state.rfpResponse.data);

  return useMemo(() => {
    let shape: Record<string, yup.AnySchema> = {};

    if (canSubmitAnswers(user, rfpResponse)) {
      shape.questions = yup.object().shape(
        traroElement.kliparo_question_list.reduce((acc, question) => {
          acc[question.id] = yup.object().shape({
            value: getQuestionSchema(question.question_type, t),
          });
          return acc;
        }, {} as Record<number, yup.AnySchema>),
      );
    }

    if (user.isReviewer) {
      shape.score = yup
        .number()
        .min(0, t('errors.minNumber', { count: 0 }))
        .max(traroElement.max_score, t('errors.maxNumber', { count: traroElement.max_score }))
        .required(t('errors.minNumber', { count: 0 }));
    }

    return yup.object().shape(shape);
  }, [t, user, rfpResponse, traroElement.max_score, traroElement.kliparo_question_list]);
};

export default useSchema;
