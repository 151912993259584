import React, { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';

import FormikSelectInput from 'components/forms/formik/FormikSelectInput/FormikSelectInput';
import { InputOption } from 'components/forms/raw/SelectInput';
import RFP from 'types/api/RFP';

import useTicketAssignedUserOptions from './hooks/useTicketAssignedUserOptions';
import { TicketCreationForm } from '../../hooks/useInitialValues';
import useUser from '../../../../../../hooks/useUser';

export interface AssignedUserSelectorProps {
  id: string;
  disabled?: boolean;
  availableRfps: InputOption<number, RFP>[] | undefined;
  responseElementId?: number;
}

const AssignedUserSelector = ({ id, disabled, availableRfps, responseElementId }: AssignedUserSelectorProps) => {
  const { isReviewer } = useUser();
  const {
    values: { organization, assignedTo },
    setFieldValue,
  } = useFormikContext<TicketCreationForm>();
  const userOptions = useTicketAssignedUserOptions(organization.value, availableRfps, responseElementId);
  const userRef = useRef(assignedTo);

  userRef.current = assignedTo;

  useEffect(() => {
    // User auto-selection should work only for reviewer users
    if (!isReviewer) {
      return;
    }

    const organizationAdmin = userOptions.find((option) => option.meta.user_role === 'Organization Admin');
    const userToSelect = organizationAdmin || userOptions[0];

    if (userToSelect && userToSelect.value !== userRef.current?.value) {
      setFieldValue('assignedTo', userToSelect);
    }
  }, [setFieldValue, userOptions, userRef, isReviewer]);

  return (
    <FormikSelectInput fullWidth id={id} name="assignedTo" options={userOptions} variant="filled" disabled={disabled} />
  );
};

export default AssignedUserSelector;
