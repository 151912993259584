import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CommentHistoryEntry from 'types/api/CommentHistoryEntry';

import './CommentEntry.scss';

export interface CommentEntryProps {
  entry: CommentHistoryEntry;
}

const CommentEntry: FC<CommentEntryProps> = ({ entry }) => {
  const { t } = useTranslation();

  const comment = useMemo(() => {
    const words = entry.response_comment.split(' ');

    if (words.length < 10) {
      return entry.response_comment;
    }

    return words.slice(0, 10).join(' ') + '...';
  }, [entry.response_comment]);

  return (
    <div className="enkrateia-history-comment-entry">
      <span>{t('pages.traroElement.historyModal.comment', { name: entry.commenter_name })}</span>
      <span className="comment">"{comment}"</span>
    </div>
  );
};

export default CommentEntry;
