import React, { forwardRef, SVGProps } from 'react';

export interface ExclamationCircleProps extends SVGProps<SVGSVGElement> {}

const ExclamationCircle = forwardRef<SVGSVGElement, ExclamationCircleProps>((props, ref) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="transparent"
    className="enkrateia-arch-checked-circle"
    ref={ref}
    {...props}
  >
    <rect x="10.7139" y="4.46387" width="3.57143" height="10.7143" rx="1.78571" fill="white" />
    <rect width="3.57143" height="3.57143" rx="1.78571" transform="matrix(1 0 0 -1 10.7139 20.5361)" fill="white" />
    <circle cx="12.5" cy="12.5" r="11" stroke="white" strokeWidth="3" />
  </svg>
));

export default ExclamationCircle;
