import React, { FC } from 'react';
import RFPResponseStatus from 'types/RFPResponseStatus';
import { useTranslation } from 'react-i18next';

import './StatusCell.scss';

export interface StatusCellProps {
  getValue: () => RFPResponseStatus;
}

const StatusCell: FC<StatusCellProps> = ({ getValue }) => {
  const { t } = useTranslation();
  const value = getValue();

  return (
    <div className={`enkrateia-rfp-response-status-cell ${value}`}>
      <div className="dot"></div>
      <div className="text">{t(`common.rfpStatus.${value}`)}</div>
    </div>
  );
};

export default StatusCell;
