import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useTickets } from 'api';
import Table from 'components/Table/Table';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import CreateTicketButton from 'components/CreateTicketButton/CreateTicketButton';
import useUser from 'hooks/useUser';

import useColumns from './hooks/useColumns';
import useSummary from './hooks/useSummary';
import useInitialTableState from './hooks/useInitialTableState';
import useRowClassName from './hooks/useRowClassName';
import TicketSummary from './components/TicketSummary/TicketSummary';

import './TicketsOverviewPage.scss';

export interface TicketsOverviewPageProps {}

const TicketsOverviewPage: FC<TicketsOverviewPageProps> = () => {
  const { t } = useTranslation();
  const columns = useColumns();
  const { data } = useTickets();
  const summary = useSummary(data);
  const initialState = useInitialTableState();
  const { isReviewer } = useUser();
  const getRowClassName = useRowClassName();

  return (
    <div className="enkrateia-overview-page">
      <Header />
      <main>
        <div className="summary">
          <TicketSummary
            to="/tickets?search=open-tickets"
            loading={data === undefined}
            count={summary.open}
            variant="open"
          />
          <TicketSummary
            to="/tickets?search=my-tickets"
            loading={data === undefined}
            count={summary.assigned}
            variant="assigned"
          />
          <TicketSummary
            to="/tickets?search=past-due"
            loading={data === undefined}
            count={summary.past}
            variant="past"
          />
          <TicketSummary
            to="/tickets?search=closed-tickets"
            loading={data === undefined}
            count={summary.closed}
            variant="closed"
          />
        </div>
        <div className="title">
          <h2>{t('pages.tickets.title')}</h2>
          <CreateTicketButton />
        </div>
        <div className={`data ${isReviewer ? 'reviewer' : ''}`}>
          <Table
            data={data}
            columns={columns}
            enableFilters
            enableSorting
            initialState={initialState}
            getRowClassName={getRowClassName}
          />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default TicketsOverviewPage;
