import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Footer from 'components/Footer/Footer';

import ICVCM from 'assets/img/ICVCMFullRes.svg';

import './Wrapper.scss';

export interface FormWrapperProps {
  children: React.ReactNode | React.ReactNode[];
}

const Wrapper: FC<FormWrapperProps> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <div className="enkrateia-splash-form-wrapper">
      <div className="form-container">
        <img src={ICVCM} alt={t('branding.icvcmLogo')} />
        <h2 className="app-platform">{t('common.header.title')}</h2>
        {children}
      </div>
      <div className="footer">
        <div className="contact-us">
          <p>
            <Trans i18nKey="pages.login.needHelp">
              Need help?
              <a href={t('common.header.userGuideUrl')} target="_blank" rel="noreferrer">
                See the User Guide
              </a>
              .
            </Trans>
          </p>
          <p>
            <Trans i18nKey="pages.login.contactUs">
              <a href={t('common.footer.links.contact.url')} target="_blank" rel="noreferrer">
                Or contact us at contact email
              </a>
            </Trans>
          </p>
        </div>
        <Footer noContactUs />
      </div>
    </div>
  );
};

export default Wrapper;
