import React, { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'store';
import CookieBanner from 'components/CookieBanner/CookieBanner';

export interface ProtectedRouteProps {
  /**
   * Route to render
   */
  children: React.ReactNode;

  /**
   * If router outlet should be rendered for sub-routers
   */
  noOutlet?: boolean;

  /**
   * Route only accesible to reviewers
   */
  reviewerOnly?: boolean;
}

/**
 * Companion util-component for react-router that checks if user is logged in, and if not,
 * redirects the user to login page.
 */
const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, noOutlet, reviewerOnly }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userType = useSelector((state) => state.auth.user.user_type);

  if (isLoggedIn && (reviewerOnly ? userType === 'RER' : true)) {
    return (
      <>
        <CookieBanner />
        {children}
        {!noOutlet && <Outlet />}
      </>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoute;
