import { isEmpty, uniqBy } from 'lodash';
import { useMemo } from 'react';

import { useOrganizationUsers } from 'api';
import { useSelector } from 'store';
import { InputOption } from 'components/forms/raw/SelectInput';
import RFP from 'types/api/RFP';
import UserDto from 'types/api/UserDto';

const useTicketAssignedUserOptions = (
  selectedOrganizationId: number,
  availableRfps: InputOption<number, RFP>[] | undefined,
  responseElementId?: number,
): InputOption<number, UserDto>[] => {
  const rfpPointOfContact = useSelector((state) => state.rfp.data.point_of_contact);
  const rfpResponsePointOfContact = useSelector((state) => state.rfpResponse.data.point_of_contact);
  const responsesList = useSelector((state) => state.rfpResponse.data.traro_element_response_list);

  const elementPointOfContact = responsesList.find((response) => response.id === responseElementId)?.point_of_contact;
  const { data: organizationUsers } = useOrganizationUsers(selectedOrganizationId);

  return useMemo(() => {
    const users: InputOption<number, UserDto>[] = (organizationUsers ?? []).map((user) => ({
      value: user.id,
      label: `${user.first_name} ${user.last_name}`,
      meta: user,
    }));

    if (availableRfps && availableRfps.length > 0) {
      const RFPPointOfContact = availableRfps[0].meta.point_of_contact;

      if (!isEmpty(RFPPointOfContact)) {
        users.push({
          value: RFPPointOfContact.id,
          label: `${RFPPointOfContact.first_name} ${RFPPointOfContact.last_name}`,
          meta: RFPPointOfContact,
        });
      }
    }

    if (!isEmpty(rfpPointOfContact)) {
      users.push({
        value: rfpPointOfContact.id,
        label: `${rfpPointOfContact.first_name} ${rfpPointOfContact.last_name}`,
        meta: rfpPointOfContact,
      });
    }

    if (!isEmpty(rfpResponsePointOfContact)) {
      users.push({
        value: rfpResponsePointOfContact.id,
        label: `${rfpResponsePointOfContact.first_name} ${rfpResponsePointOfContact.last_name}`,
        meta: rfpResponsePointOfContact,
      });
    }

    if (!isEmpty(elementPointOfContact)) {
      users.push({
        value: elementPointOfContact.id,
        label: `${elementPointOfContact.first_name} ${elementPointOfContact.last_name}`,
        meta: elementPointOfContact,
      });
    }

    return uniqBy(users, 'value');
  }, [organizationUsers, rfpPointOfContact, rfpResponsePointOfContact, elementPointOfContact, availableRfps]);
};

export default useTicketAssignedUserOptions;
