enum QuestionStatus {
  BEGIN = 'Begin',
  DONE = 'Done',
  REVIEW_ONLY = 'Review Only',
  UPDATE_REQUIRED = 'Update Required',
  INCOMPLETE = 'Incomplete',
  NOT_REQUIRED = 'Not Required',
  IN_PROGRESS = 'In Progress',
  REVIEW_COMPLETE = 'Review Complete',
}

export default QuestionStatus;
