import React, { FC, useCallback, useMemo } from 'react';
import { OnChangeValue } from 'react-select';
import { Column, Table } from '@tanstack/react-table';
import { uniqBy } from 'lodash';

import SelectInput, { InputOption } from 'components/forms/raw/SelectInput';
import TicketWithConnection from 'types/TicketWithConnection';

import './TicketUserFilter.scss';

export interface TicketUserFilterProps {
  column: Column<TicketWithConnection, string>;
  table: Table<TicketWithConnection>;
}

const TicketUserFilter: FC<TicketUserFilterProps> = ({ column, table }) => {
  const columnFilterValue = (column.getFilterValue() as number | null) ?? null;

  const coreRowModel = table.getCoreRowModel();
  const options: InputOption<number>[] = useMemo(() => {
    const allEntries = coreRowModel.rows.map((row) => ({
      value: row.original.assigned_user as unknown as number,
      label: `${row.original.assigned_first_name} ${row.original.assigned_last_name}`,
    }));

    return uniqBy(allEntries, 'value');
  }, [coreRowModel]);

  const onChange = useCallback(
    (newValue: OnChangeValue<InputOption<number>, false>) => {
      column.setFilterValue(newValue?.value ?? null);
    },
    [column],
  );

  return (
    <SelectInput
      value={options.find((o) => o.value === columnFilterValue) ?? null}
      onChange={onChange as any}
      options={options}
      variant="filled"
      className="enkrateia-table-filter enkrateia-ticket-user-filter"
      isClearable
    />
  );
};

export default TicketUserFilter;
