import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormikSelectInput from 'components/forms/formik/FormikSelectInput/FormikSelectInput';
import FormikTextAreaInput from 'components/forms/formik/FormikTextAreaInput/FormikTextAreaInput';
import SubmitButton from 'components/SubmitButton/SubmitButton';

export interface DisclaimerFormStepProps {}

const DisclaimerFormStep: FC<DisclaimerFormStepProps> = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="form-elements">
        <label>{t('pages.dashboard.createAssessment.organization')}*</label>
        <FormikSelectInput required fullWidth name="organization" options={[]} disabled variant="filled" />
      </div>
      <div className="disclaimer">
        <FormikTextAreaInput name="terms" variant="filled" readOnly rows={16} />
      </div>
      <div className="info bottom">{t('pages.dashboard.createAssessment.disclaimerCta')}</div>
      <div className="submit-container">
        <SubmitButton variant="primary">{t('pages.dashboard.createAssessment.ok')}</SubmitButton>
      </div>
    </div>
  );
};

export default DisclaimerFormStep;
