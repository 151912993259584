import { useMemo } from 'react';

export interface ResetPasswordForm {
  email: string;
}

const useInitialValues = () =>
  useMemo(
    (): ResetPasswordForm => ({
      email: '',
    }),
    [],
  );

export default useInitialValues;
