import React from 'react';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';

import SplashScreenTemplate from 'components/SplashScreenTemplate/SplashScreenTemplate';
import FormikTextInput from 'components/forms/formik/FormikTextInput/FormikTextInput';
import SubmitButton from 'components/SubmitButton/SubmitButton';

import { useTranslation } from 'react-i18next';
import useInitialValues from './hooks/useInitialValues';
import useSchema from './hooks/useSchema';
import useCreatePasswordSubmit from './hooks/useCreatePasswordSubmit';

import './CreatePasswordPage.scss';

const CreatePasswordPage = () => {
  const { t } = useTranslation();

  const initialValues = useInitialValues();
  const schema = useSchema();
  const [onSubmit, isLoading, isSuccess] = useCreatePasswordSubmit();

  return (
    <SplashScreenTemplate className="enkrateia-create-password-page">
      {!isSuccess && (
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
          <Form>
            <FormikTextInput
              label={t('pages.createPassword.form.password')}
              name="password"
              type="password"
              fullWidth
              autoComplete="new-password"
              disabled={isLoading}
            />
            <FormikTextInput
              label={t('pages.createPassword.form.confirmPassword')}
              name="confirmPassword"
              type="password"
              fullWidth
              autoComplete="new-password"
              disabled={isLoading}
            />
            <SubmitButton variant="primary">{t('pages.createPassword.form.submit')}</SubmitButton>
          </Form>
        </Formik>
      )}
      {isSuccess && (
        <div className="success">
          <h3>{t('pages.createPassword.success.title')}</h3>
          <p>{t('pages.createPassword.success.description')}</p>
          <Link to="/login">{t('pages.createPassword.success.backToLogin')}</Link>
        </div>
      )}
    </SplashScreenTemplate>
  );
};

export default CreatePasswordPage;
