import { useMemo } from 'react';
import { useSelector } from 'store';

import { EligibilityOption } from 'hooks/useEligibilityOptions';

export interface ChangeAssessmentStateModalFormValues {
  programEligibility: EligibilityOption | null;
}

const useInitialValues = (options: EligibilityOption[]): ChangeAssessmentStateModalFormValues => {
  const score = useSelector((store) => store.rfpResponse.data.rfp_response_score);

  return useMemo(() => {
    if (!score) {
      return { programEligibility: null };
    }

    return {
      programEligibility:
        options.find((option) => option.meta.low_score <= score && score <= option.meta.high_score) ?? null,
    };
  }, [score, options]);
};

export default useInitialValues;
