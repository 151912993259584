import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import TraroElementResponse from 'types/api/TraroElementResponse';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import useUser from 'hooks/useUser';
import RFPResponseStatus from 'types/RFPResponseStatus';
import { useSelector } from 'store';

import Comment from './components/Comment/Comment';
import CommentEntry from './components/CommentEntry/CommentEntry';

import './Comments.scss';

export interface CommentsProps {
  traroElementResponse: TraroElementResponse;
}

const Comments: FC<CommentsProps> = ({ traroElementResponse }) => {
  const { t } = useTranslation();
  const responseStatus = useSelector((store) => store.rfpResponse.data.rfp_response_status);

  const { isReviewer, isProponent } = useUser();
  const commentsEnabled = useMemo(() => {
    if (isReviewer) {
      return responseStatus !== RFPResponseStatus.FINAL;
    }

    if (isProponent) {
      return responseStatus !== RFPResponseStatus.IN_REVIEW && responseStatus !== RFPResponseStatus.FINAL;
    }

    return false;
  }, [isReviewer, isProponent, responseStatus]);

  return (
    <ErrorBoundary>
      <div className="enkrateia-element-comments">
        {commentsEnabled && <CommentEntry traroElementResponse={traroElementResponse} />}
        {traroElementResponse.traro_element_comment.length > 0 ? (
          <div className="comments">
            {traroElementResponse.traro_element_comment.map((comment) => (
              <Comment comment={comment} key={comment.id} />
            ))}
          </div>
        ) : (
          <div className="no-comments">{t('pages.traroElement.comments.noComments')}</div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default Comments;
