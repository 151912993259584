import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

import Modal from 'components/Modal/Modal';
import FormikTextAreaInput from 'components/forms/formik/FormikTextAreaInput/FormikTextAreaInput';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import LoaderOverlay from 'components/LoaderOverlay/LoaderOverlay';
import { InputOption } from 'components/forms/raw/SelectInput';
import UserDto from 'types/api/UserDto';
import TicketDto from 'types/api/TicketDto';

import useInitialValues from './hooks/useInitialValues';
import useSchema from './hooks/useSchema';
import useSubmitChangeTicketStatusForm from './hooks/useSubmitChangeTicketStatusForm';

import './RequestTicketCloseModal.scss';

export interface ChangeTicketStatusModalProps {
  isOpen: boolean;
  ticket: TicketDto;
  onClose: () => void;
  selectableUsers: InputOption<number, UserDto>[];
}

const RequestTicketCloseModal: FC<ChangeTicketStatusModalProps> = ({ isOpen, onClose, ticket, selectableUsers }) => {
  const { t } = useTranslation();
  const initialValues = useInitialValues(ticket, selectableUsers);
  const schema = useSchema();
  const [onSubmit, isLoading] = useSubmitChangeTicketStatusForm(onClose, ticket.id);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      variant="kliparo"
      className="request-ticket-close-modal"
      title={t(`pages.ticketDetails.requestTicketClose.title`)}
    >
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
        <Form>
          <p>{t(`pages.ticketDetails.requestTicketClose.help`)}</p>
          <FormikTextAreaInput fullWidth variant="filled" name="comment" rows={4} />
          <SubmitButton variant="primary">{t(`pages.ticketDetails.requestTicketClose.confirm`)}</SubmitButton>
          <LoaderOverlay open={isLoading} />
        </Form>
      </Formik>
    </Modal>
  );
};

export default RequestTicketCloseModal;
