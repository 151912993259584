import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export interface CreatePasswordForm {
  encodedEmail: string;
  token: string;
  password: string;
  confirmPassword: string;
}

const useInitialValues = () => {
  const { encodedEmail = '', token = '' } = useParams<{ encodedEmail: string; token: string }>();

  return useMemo(
    (): CreatePasswordForm => ({
      encodedEmail,
      token,
      password: '',
      confirmPassword: '',
    }),
    [encodedEmail, token],
  );
};

export default useInitialValues;
