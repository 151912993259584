import React, { FC, useCallback, useMemo } from 'react';
import { OnChangeValue } from 'react-select';
import { Column } from '@tanstack/react-table';

import SelectInput, { InputOption } from 'components/forms/raw/SelectInput';
import TicketWithConnection from 'types/TicketWithConnection';
import TicketStatus from 'types/TicketStatus';

import './TicketStatusFilter.scss';

export interface TicketStatusFilterProps {
  column: Column<TicketWithConnection, TicketStatus>;
}

const TicketStatusFilter: FC<TicketStatusFilterProps> = ({ column }) => {
  const columnFilterValue = (column.getFilterValue() as TicketStatus | null) ?? null;

  const options: InputOption<TicketStatus>[] = useMemo(
    () => [
      { value: TicketStatus.OPEN, label: TicketStatus.OPEN },
      { value: TicketStatus.CLOSED, label: TicketStatus.CLOSED },
    ],
    [],
  );

  const onChange = useCallback(
    (newValue: OnChangeValue<InputOption<TicketStatus>, false>) => {
      column.setFilterValue(newValue?.value ?? null);
    },
    [column],
  );

  return (
    <SelectInput
      value={options.find((o) => o.value === columnFilterValue) ?? null}
      onChange={onChange as any}
      options={options}
      variant="filled"
      className="enkrateia-table-filter enkrateia-ticket-status-filter"
      isClearable
    />
  );
};

export default TicketStatusFilter;
