import { createContext, FC, ReactNode, useContext, useMemo } from 'react';
import { traroListToObject } from 'store/rfp/helpers';
import { traroResponseListToObject } from 'store/rfpResponse/helpers';
import { useSelector } from 'store';
import ArchElementSpecific from 'types/ArchElementSpecific';

type ArchOverviewState = {
  elements: ReturnType<typeof traroListToObject>;
  responses: ReturnType<typeof traroResponseListToObject>;
};

const ArchContext = createContext<ArchOverviewState>({} as never as ArchOverviewState);

interface ArchContextProviderProps {
  children: ReactNode;
}

export const ArchContextProvider: FC<ArchContextProviderProps> = ({ children }) => {
  const traroElementList = useSelector((store) => store.rfp.data.traro_element_list);
  const traroElementResponseList = useSelector((store) => store.rfpResponse.data.traro_element_response_list);

  const contextState = useMemo(
    () => ({
      elements: traroListToObject(traroElementList),
      responses: traroResponseListToObject(traroElementResponseList),
    }),
    [traroElementList, traroElementResponseList],
  );

  return <ArchContext.Provider value={contextState}>{children}</ArchContext.Provider>;
};

export const useArchContext = () => useContext<ArchOverviewState>(ArchContext);

export const useArchContextForElement = (archElement: ArchElementSpecific) => {
  const { elements, responses } = useArchContext();

  return {
    element: elements[archElement],
    response: responses[archElement],
  } as const;
};

export default ArchContext;
