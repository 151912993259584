import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import useUser from 'hooks/useUser';
import { FilledHelpIcon } from 'components/SvgIcons';
import CommonTransComponents from 'i18n/CommonTransComponents';

import './OrganizationDetails.scss';

const OrganizationDetails = () => {
  const { t } = useTranslation();
  const { organization } = useUser();

  return (
    <section className="enkrateia-organization-profile-details">
      <h2>{t('pages.organizationProfile.organization.title')}</h2>
      <div className="details">
        <span>{t('pages.organizationProfile.organization.name')}</span>
        <span className="value">{organization.name}</span>
        <span>{t('pages.organizationProfile.organization.country')}</span>
        <span className="value">{organization.country}</span>
        <span>{t('pages.organizationProfile.organization.address')}</span>
        <span className="value">
          {organization.address.split('\n').map((line) => (
            <span key={line}>{line}</span>
          ))}
        </span>
      </div>
      <div className="help-row">
        <FilledHelpIcon color="#2D2C64" size={25} />
        <Trans i18nKey="pages.organizationProfile.organization.help" components={CommonTransComponents} />
      </div>
    </section>
  );
};

export default OrganizationDetails;
