import React, { FC } from 'react';
import { Trans } from 'react-i18next';

import PoweredBy from 'components/PoweredBy/PoweredBy';

import './Splash.scss';
import BuiltOnEnkrateia from '../../../../components/BuiltOnEnkrateia/BuiltOnEnkrateia';

export interface SplashProps {}

const Splash: FC<SplashProps> = () => (
  <div className="enkrateia-splash">
    <div className="content">
      <p className="cta">
        <Trans i18nKey="pages.login.ctaText">
          <p>A</p>
          <p>B</p>
        </Trans>
      </p>
    </div>
    <div className="footer">
      <BuiltOnEnkrateia />
      <PoweredBy />
    </div>
  </div>
);

export default Splash;
