import { useQuery } from '@tanstack/react-query';
import TicketDto from '../../types/api/TicketDto';
import API from '../../services/api';
import FormSubmitError from '../../errors/FormSubmitError';

interface RfpResponseTicketsResponse {
  error: boolean;
  error_message: string;
  ticket_list: TicketDto[];
}

export const fetchRfpElementTickets = async (responseElementId: number | undefined | null): Promise<TicketDto[]> => {
  try {
    if (!responseElementId) {
      return [];
    }

    return (await API.get<RfpResponseTicketsResponse>(`/ticket-list/response_element/${responseElementId}`)).data
      .ticket_list;
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

const getQueryKey = (elementId?: number | undefined | null) => {
  const key: unknown[] = ['responseElementTickets'];

  if (elementId !== undefined) {
    key.push(elementId);
  }

  return key;
};

const useRfpElementTickets = (elementId: number | undefined | null) =>
  useQuery(getQueryKey(elementId), () => fetchRfpElementTickets(elementId));

useRfpElementTickets.getQueryKey = getQueryKey;
useRfpElementTickets.queries = {
  fetchRfpElementTickets,
} as const;

export default useRfpElementTickets;
