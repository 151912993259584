import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'store';
import { DateTime } from 'luxon';

import { loadElementResponseHistory, reset } from 'store/elementHistory';

const parseDate = (date: string) => {
  const parsedDate = DateTime.fromISO(date);

  if (!parsedDate.isValid) {
    return '-';
  }

  return parsedDate.toFormat('M/d/y');
};

const useElementHistory = (elementId: number) => {
  const { loading, data } = useSelector((store) => store.elementHistory);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadElementResponseHistory(elementId));

    return () => {
      dispatch(reset());
    };
  }, [dispatch, elementId]);

  const mappedData = useMemo(() => data.map((entry) => ({ ...entry, timestamp: parseDate(entry.timestamp) })), [data]);

  return [mappedData, loading] as const;
};

export default useElementHistory;
