/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from '@reduxjs/toolkit';
import RFPResponseWithRFP from 'types/api/RFPResponseWithRFP';
import { loadRfpResponsesList } from './thunks';

interface Slice {
  data: RFPResponseWithRFP[];
  loading: boolean;
  error: string | undefined;
}

export const rfpReviewerListSlice = createSlice({
  name: 'rfpReviewerList',
  initialState: {
    data: [],
    error: undefined,
    loading: false,
  } as Slice,
  reducers: {
    reset: (state) => {
      state.data = [];
      state.loading = false;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadRfpResponsesList.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(loadRfpResponsesList.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(loadRfpResponsesList.rejected, (state, action) => {
      state.data = [];
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default rfpReviewerListSlice.reducer;
