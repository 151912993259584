import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import TicketStatus from 'types/TicketStatus';

export interface ChangeTicketStatusForm {
  comment: string;
  requestedStatus: TicketStatus;
}

const useInitialValues = (requestedStatus: TicketStatus): ChangeTicketStatusForm => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      comment: t(`pages.ticketDetails.changeStateTo.${requestedStatus}.comment`),
      requestedStatus,
    }),
    [requestedStatus, t],
  );
};

export default useInitialValues;
