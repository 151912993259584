import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { InitialTableState } from '@tanstack/react-table';

import useUser from 'hooks/useUser';
import TicketStatus from 'types/TicketStatus';
import { DateTime } from 'luxon';

const getColumnFilter = (id: string, value: unknown) => ({
  id,
  value,
});

const getColumnSort = (id: string, desc: boolean) => ({
  id,
  desc,
});

const useInitialTableState = (): InitialTableState => {
  const [searchParams] = useSearchParams();
  const user = useUser();

  return useMemo(() => {
    const search = searchParams.get('search');

    switch (search) {
      case 'open-tickets':
        return {
          columnFilters: [getColumnFilter('ticket_status', TicketStatus.OPEN)],
        };
      case 'my-tickets':
        return {
          columnFilters: [getColumnFilter('assigned', user.id)],
        };
      case 'past-due':
        return {
          columnFilters: [
            getColumnFilter('due_date', {
              from: null,
              to: DateTime.now(),
            }),
          ],
          sorting: [getColumnSort('due_date', false)],
        };
      case 'closed-tickets':
        return {
          columnFilters: [getColumnFilter('ticket_status', TicketStatus.CLOSED)],
        };
      default:
        return {};
    }
  }, [searchParams, user]);
};

export default useInitialTableState;
