import React, { FC, useCallback } from 'react';

import { useDispatch, useSelector } from 'store';
import { closeModal } from 'store/modals';

import Modal from 'components/Modal/Modal';
import ErrorOverlay from 'components/ErrorOverlay/ErrorOverlay';

import './ErrorModal.scss';

export interface ErrorModalProps {}

const ErrorModal: FC<ErrorModalProps> = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector((store) => store.modals.errorModal.open);
  const message = useSelector((store) => store.modals.errorModal.data.message);

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal('errorModal'));
  }, [dispatch]);

  return (
    <Modal isOpen={isOpen} className="enkrateia-error-modal">
      <ErrorOverlay error={message} onClose={handleCloseModal} />
    </Modal>
  );
};

export default ErrorModal;
