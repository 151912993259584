import React, { FC } from 'react';
import Timeline from 'components/Timeline/Timeline';

export interface LoadingPlaceholderProps {}

const LoadingPlaceholder: FC<LoadingPlaceholderProps> = () => (
  <Timeline>
    <Timeline.EntryPlaceholder dateWidth={72} contentWidth={360} contentHeight={18} />
    <Timeline.EntryPlaceholder dateWidth={72} contentWidth={400} contentHeight={70} />
    <Timeline.EntryPlaceholder dateWidth={72} contentWidth={320} contentHeight={18} />
  </Timeline>
);

export default LoadingPlaceholder;
