import React, { FC, ReactElement } from 'react';
import TimelineEntry, { TimelineEntryProps } from './components/TimelineEntry/TimelineEntry';
import TimelineEntryPlaceholder, {
  TimelineEntryPlaceholderProps,
} from './components/TimelineEntryPlaceholder/TimelineEntryPlaceholder';

import './Timeline.scss';

export interface TimelineProps {
  /**
   * Children elements (they must be instances of ReactElement with TabProps props)
   */
  children: ReactElement<TimelineEntryProps | TimelineEntryPlaceholderProps>[];
}

const Timeline: FC<TimelineProps> & {
  Entry: typeof TimelineEntry;
  EntryPlaceholder: typeof TimelineEntryPlaceholder;
} = ({ children }) => <div className="enkrateia-timeline">{children}</div>;

Timeline.Entry = TimelineEntry;
Timeline.EntryPlaceholder = TimelineEntryPlaceholder;

export default Timeline;
