import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

import Modal from 'components/Modal/Modal';
import TicketStatus from 'types/TicketStatus';
import FormikTextAreaInput from 'components/forms/formik/FormikTextAreaInput/FormikTextAreaInput';
import SubmitButton from 'components/SubmitButton/SubmitButton';
import LoaderOverlay from 'components/LoaderOverlay/LoaderOverlay';

import useInitialValues from './hooks/useInitialValues';
import useSchema from './hooks/useSchema';
import useSubmitChangeTicketStatusForm from './hooks/useSubmitChangeTicketStatusForm';

import './ChagneTicketStatusModal.scss';

export interface ChangeTicketStatusModalProps {
  isOpen: boolean;
  requestedStatus: TicketStatus;
  ticketId: number;
  onClose: () => void;
}

const ChangeTicketStatusModal: FC<ChangeTicketStatusModalProps> = ({ isOpen, onClose, requestedStatus, ticketId }) => {
  const { t } = useTranslation();
  const initialValues = useInitialValues(requestedStatus);
  const schema = useSchema();
  const [onSubmit, isLoading] = useSubmitChangeTicketStatusForm(onClose, ticketId);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      variant="kliparo"
      className="change-ticket-status-modal"
      title={t(`pages.ticketDetails.changeStateTo.${requestedStatus}.title`)}
    >
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
        <Form>
          <p>{t(`pages.ticketDetails.changeStateTo.${requestedStatus}.help`)}</p>
          <FormikTextAreaInput fullWidth variant="filled" name="comment" rows={4} />
          <SubmitButton variant="primary">
            {t(`pages.ticketDetails.changeStateTo.${requestedStatus}.confirm`)}
          </SubmitButton>
          <LoaderOverlay open={isLoading} />
        </Form>
      </Formik>
    </Modal>
  );
};

export default ChangeTicketStatusModal;
