import React from 'react';

import Tooltip from 'components/Tooltip/Tooltip';
import Markdown from 'components/Markdown/Markdown';
import EllipsisTextCell from '../EllipsisTextCell/EllipsisTextCell';

import './MarkdownHoverCell.scss';

export interface MarkdownCellProps {
  getValue: () => string;
}

const MarkdownHoverCell = ({ getValue }: MarkdownCellProps) => (
  <Tooltip overlayClassName="markdown-overlay-tooltip" tooltip={<Markdown>{getValue()}</Markdown>} placement="left">
    <EllipsisTextCell getValue={getValue} noFullText />
  </Tooltip>
);

export default MarkdownHoverCell;
