import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import QuestionStatus from 'types/QuestionStatus';
import Button, { ButtonProps } from 'components/Button/Button';

import exclamationCircleIcon from 'assets/img/exclamationCircleIcon.svg';
import checkmarkCircleIcon from 'assets/img/checkmarkCircleIcon.svg';

import './QuestionStatusButton.scss';

export interface QuestionStatusButtonProps extends Omit<ButtonProps, 'variant' | 'children'> {
  status: QuestionStatus;
}

const getConfig = (status: QuestionStatus) => {
  switch (status) {
    case QuestionStatus.BEGIN:
      return {
        image: undefined,
        label: 'common.statusButton.begin',
        className: 'enkrateia-question-status-button begin',
      } as const;
    case QuestionStatus.UPDATE_REQUIRED:
      return {
        image: exclamationCircleIcon,
        label: 'common.statusButton.updateRequired',
        className: 'enkrateia-question-status-button incomplete',
        // As per https://lydion.atlassian.net/browse/ENK-47 we do not use orange Update Required but we use incomplete style
        // image: undefined,
        // label: 'common.statusButton.updateRequired',
        // className: 'enkrateia-question-status-button update-required',
      } as const;
    case QuestionStatus.REVIEW_ONLY:
      return {
        image: undefined,
        label: 'common.statusButton.reviewOnly',
        className: 'enkrateia-question-status-button review-only',
      } as const;
    case QuestionStatus.INCOMPLETE:
      return {
        image: exclamationCircleIcon,
        label: 'common.statusButton.incomplete',
        className: 'enkrateia-question-status-button incomplete',
      } as const;
    case QuestionStatus.DONE:
    default:
      return {
        image: checkmarkCircleIcon,
        label: 'common.statusButton.done',
        className: 'enkrateia-question-status-button done',
      } as const;
  }
};

const QuestionStatusButton: FC<QuestionStatusButtonProps> = ({ status, ...props }) => {
  const { t } = useTranslation();

  const { image, label, className } = getConfig(status);

  return (
    <Button variant="primary" className={className} {...props}>
      {image && <img src={image} alt="" />}
      <span>{t(label)}</span>
    </Button>
  );
};

export default QuestionStatusButton;
