import React, { Component, ErrorInfo, ReactNode } from 'react';
import ErrorOverlay from '../ErrorOverlay/ErrorOverlay';

export interface ErrorBoundaryProps {
  children: ReactNode;
  onClose?: () => void;
  inLine?: boolean;
}

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <ErrorOverlay
          error={this.state.error}
          errorInfo={this.state.errorInfo}
          onClose={this.props.onClose}
          inLine={this.props.inLine}
        />
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
