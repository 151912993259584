import React, { FC } from 'react';
import ArchElementGeneric from 'types/ArchElementGeneric';
import ArchElementSpecific from 'types/ArchElementSpecific';
import { isCompleted } from 'store/rfpResponse/helpers';

import CheckedCircle from '../CheckedCircle/CheckedCircle';
import { useArchContextForElement } from '../../../../context/ArchContext';
import useElementSelection from '../../../../hooks/useElementSelection';

import './Brick.scss';

const bricks = [
  ArchElementSpecific.BRICK_1,
  ArchElementSpecific.BRICK_2,
  ArchElementSpecific.BRICK_3,
  ArchElementSpecific.BRICK_4,
  ArchElementSpecific.BRICK_5,
  ArchElementSpecific.BRICK_6,
  ArchElementSpecific.BRICK_7,
  ArchElementSpecific.BRICK_8,
  ArchElementSpecific.BRICK_9,
  ArchElementSpecific.BRICK_10,
] as const;

export interface BrickProps {
  id: typeof bricks[number];
  x: number;
  y: number;
  text: string;
}

const STROKE = 3;

const Brick: FC<BrickProps> = ({ id, x, y, text }) => {
  const { response } = useArchContextForElement(id);
  const completed = isCompleted(response);
  const [isSelected, select] = useElementSelection(id, ArchElementGeneric.BRICK);

  return (
    <svg
      x={x - STROKE}
      y={y - STROKE}
      width={100 + 2 * STROKE}
      height={36 + 2 * STROKE}
      className={`enkrateia-arch-brick ${ArchElementGeneric.BRICK} ${id}`}
      opacity={isSelected ? 1 : 0.3}
      onClick={select}
      cursor="pointer"
    >
      <rect
        width="100"
        x={STROKE}
        y={STROKE}
        height="34"
        rx="6.5"
        stroke="#DDB483"
        fill={completed ? '#DDB483' : 'transparent'}
        strokeWidth="3"
      />
      <text fill="#fff" textAnchor="middle" x="50" y="26">
        {text}
      </text>
      {completed && <CheckedCircle x={12} y={8} />}
    </svg>
  );
};

export default Brick;
