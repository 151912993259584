import React, { FC } from 'react';

import LinkWithIcon from 'components/LinkWithIcon/LinkWithIcon';

import './TicketIdCell.scss';

export interface TicketIdCellProps {
  getValue: () => number;
}

const TicketIdCell: FC<TicketIdCellProps> = ({ getValue }) => (
  <LinkWithIcon to={`/ticket/${getValue()}`} color="#4A4A4A" className="enkrateia-ticket-id-cell">
    {getValue().toString().padStart(6, '0')}
  </LinkWithIcon>
);

export default TicketIdCell;
