import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as yup from 'yup';

const useSchema = () => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      yup.object().shape({
        rfp: yup.mixed().required(t('errors.required')),
        programName: yup.string().required(t('errors.required')),
      }),
    [t],
  );
};

export default useSchema;
