import React, { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useSelector } from 'store';
import ArchElementsMap from 'types/ArchElementsMap';
import ArchElementGeneric from 'types/ArchElementGeneric';
import { isCompleted } from 'store/rfpResponse/helpers';

import './CompletionStatus.scss';
import ArchElementSpecific from '../../../../../../../../../../types/ArchElementSpecific';

export interface CompletionStatusProps {
  x: string | number;
  y: string | number;
}

const CompletionStatus: FC<CompletionStatusProps> = ({ x, y }) => {
  const { t } = useTranslation();
  const responses = useSelector((store) => store.rfpResponse.data.traro_element_response_list);

  const completion = useMemo(() => {
    const nonBrickElements = [ArchElementGeneric.SPRINGER, ArchElementGeneric.VOUSSOIR, ArchElementGeneric.KEYSTONE];
    const nonBricksResponses = responses.filter((response) =>
      nonBrickElements.includes(ArchElementsMap[response.arch_element_specific as ArchElementSpecific]),
    );

    return Math.round((100 * nonBricksResponses.filter(isCompleted).length) / nonBricksResponses.length);
  }, [responses]);

  return (
    <text className="enkrateia-arch-completion-status" x={x} y={y} textAnchor="middle">
      <Trans t={t} i18nKey="pages.projectOverview.mainPanel.arch.completed" count={completion}>
        <tspan>0%</tspan>
        Completed
      </Trans>
    </text>
  );
};

export default CompletionStatus;
