import { useCallback } from 'react';

import { updateRfpResponseScoreAndStatus } from 'store/rfpResponse';
import RFPResponseStatus from 'types/RFPResponseStatus';
import { useDispatch, useSelector } from 'store';

import { ChangeAssessmentStateModalFormValues } from '../../ChangeAssessmentStateModal/hooks/useInitialValues';

const useOnSubmit = (setOpen: (isOpen: boolean) => void) => {
  const dispatch = useDispatch();
  const rfpId = useSelector((store) => store.rfp.data.id);

  return useCallback(
    async ({ programEligibility }: ChangeAssessmentStateModalFormValues) => {
      await dispatch(
        updateRfpResponseScoreAndStatus({
          rfpId,
          score: programEligibility?.meta?.low_score ?? null,
          status: RFPResponseStatus.IN_REVIEW,
        }),
      ).unwrap();

      setOpen(false);
    },
    [dispatch, rfpId, setOpen],
  );
};

export default useOnSubmit;
