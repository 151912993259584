import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from 'components/Loader/Loader';

import './TicketSummary.scss';
import { Link } from 'react-router-dom';

export type Variant = 'open' | 'assigned' | 'past' | 'closed';

export interface TicketSummaryProps {
  count: number;
  variant: Variant;
  loading?: boolean;
  to: string;
}

const LABELS = {
  open: 'pages.tickets.summary.open',
  assigned: 'pages.tickets.summary.assigned',
  past: 'pages.tickets.summary.past',
  closed: 'pages.tickets.summary.closed',
} as const;

const TicketSummary: FC<TicketSummaryProps> = ({ count, variant, loading, to }) => {
  const { t } = useTranslation();

  return (
    <Link className={`enkrateia-ticket-summary ${variant}`} to={to}>
      <span className="number">{loading ? <Loader /> : count}</span>
      <span className="label">{t(LABELS[variant])}</span>
    </Link>
  );
};

export default TicketSummary;
