import { useQuery } from '@tanstack/react-query';

import API from 'services/api';
import FormSubmitError from 'errors/FormSubmitError';
import UserDto from 'types/api/UserDto';
import OrganizationDto from 'types/api/OrganizationDto';

export const fetchOrganizationUsers = async (organizationId: number, disabled?: boolean): Promise<UserDto[]> => {
  try {
    if (disabled) {
      return [];
    }

    return (await API.get<OrganizationDto & { user_list: UserDto[] }>(`/organization-user-list/${organizationId}`)).data
      .user_list;
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

const getQueryKey = (organizationId: number) => ['organization/organizationUsers', organizationId];

const useOrganizationUsers = (organizationId: number, disabled?: boolean) =>
  useQuery(getQueryKey(organizationId), () => fetchOrganizationUsers(organizationId, disabled));

useOrganizationUsers.getQueryKey = getQueryKey;
useOrganizationUsers.queries = {
  fetchOrganizationUsers,
} as const;

export default useOrganizationUsers;
