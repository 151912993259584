import { useCallback } from 'react';
import { Row } from '@tanstack/react-table';

import TicketSourceType from 'types/TicketSourceType';
import TicketWithConnection from 'types/TicketWithConnection';

const useRowClassName = () =>
  useCallback((row: Row<TicketWithConnection>) => {
    if (row.original.source === TicketSourceType.SOURCE_REMEDIAL_ACTION) {
      return 'remedial-action-row';
    }

    return '';
  }, []);

export default useRowClassName;
