/* eslint-disable @typescript-eslint/naming-convention */
import TraroElement from 'types/api/TraroElement';
import ArchElementSpecific from 'types/ArchElementSpecific';
import ArchElementsMap from 'types/ArchElementsMap';
import QuestionSection from 'types/api/QuestionSection';
import { isNumber } from 'lodash';

export const getDefaultTraroElement = (specificElement: ArchElementSpecific, elementNumber: number): TraroElement => ({
  arch_element_generic: ArchElementsMap[specificElement],
  arch_element_specific: specificElement,
  context0: '',
  context1: '',
  context2: '',
  question_text1: '',
  question_text2: '',
  max_score: 0,
  scoring_rubric: [],
  element_name: '',
  element_number: elementNumber,
  kliparo_question_list: [],
});

export const traroListToObject = (traroElementList: TraroElement[]): Record<ArchElementSpecific, TraroElement> => {
  // @ts-ignore
  const result: Record<ArchElementSpecific, TraroElement> = {};
  let maxNumber = 0;

  traroElementList.forEach((element) => {
    result[element.arch_element_specific] = element;
    maxNumber = Math.max(maxNumber, element.element_number);
  });

  Object.getOwnPropertyNames(ArchElementsMap).forEach((element) => {
    const specificElement = element as keyof typeof ArchElementsMap;

    if (result[specificElement] === undefined) {
      result[specificElement] = getDefaultTraroElement(specificElement, ++maxNumber);
    }
  });

  return result;
};

const getQuestionWeightReducer = (acc: number, value: string, index: number, allNumbers: string[]) =>
  acc + (isNumber(value) ? value : 0) * 10 ** (index - allNumbers.length);

export const sortKliparoQuestion = (a: QuestionSection, b: QuestionSection) => {
  const aWeight = String(a.question_number).split('.').reduce(getQuestionWeightReducer, 0);
  const bWeight = String(b.question_number).split('.').reduce(getQuestionWeightReducer, 0);

  return aWeight - bWeight;
};
