import { DateTime } from 'luxon';

const useFormattedDateTime = (date: string): string => {
  const parsedDate = DateTime.fromISO(date);

  if (!parsedDate.isValid) {
    return '-';
  }

  return parsedDate.toFormat('M/d/y hh:mma').toLowerCase();
};

export default useFormattedDateTime;
