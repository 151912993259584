import React from 'react';

const ChevronCell = () => (
  <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5849 8.13538C7.94045 7.7366 7.94045 7.09004 7.5849 6.69126L6.7027 5.70179C6.6728 5.66411 6.64175 5.62718 6.60954 5.59105L1.96034 0.376564C1.51251 -0.12571 0.786448 -0.12571 0.338625 0.376562C-0.109199 0.878836 -0.109198 1.69318 0.338626 2.19545L4.98805 7.41019L0.336196 12.6276C-0.111627 13.1299 -0.111628 13.9443 0.336196 14.4465C0.784019 14.9488 1.51009 14.9488 1.95791 14.4465L7.5849 8.13538Z"
      fill="#014F71"
    />
  </svg>
);
export default ChevronCell;
