import React from 'react';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SplashScreenTemplate from 'components/SplashScreenTemplate/SplashScreenTemplate';
import FormikTextInput from 'components/forms/formik/FormikTextInput/FormikTextInput';
import SubmitButton from 'components/SubmitButton/SubmitButton';

import useInitialValues from './hooks/useInitialValues';
import useLoginSubmit from './hooks/useLoginSubmit';
import useSchema from './hooks/useSchema';

import './LoginPage.scss';

const LoginPage = () => {
  const { t } = useTranslation();
  const initialValues = useInitialValues();
  const schema = useSchema();
  const onSubmit = useLoginSubmit();

  return (
    <SplashScreenTemplate className="enkrateia-login-page">
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
        <Form>
          <FormikTextInput label={t('pages.login.form.email')} name="email" type="email" fullWidth />
          <FormikTextInput label={t('pages.login.form.password')} name="password" type="password" fullWidth />
          <SubmitButton submitVariant="form" variant="primary">
            {t('pages.login.form.submit')}
          </SubmitButton>
        </Form>
      </Formik>
      <Link to="/register">{t('pages.login.form.needAccount')}</Link>
      <Link to="/reset-password">{t('pages.login.form.forgotPassword')}</Link>
    </SplashScreenTemplate>
  );
};

export default LoginPage;
