import { useMemo } from 'react';

export interface SubmitAssessmentFormValues {
  checkbox: boolean;
}

const useInitialValues = (): SubmitAssessmentFormValues =>
  useMemo(
    () => ({
      checkbox: false,
      iHaveReadTerms: false,
    }),
    [],
  );

export default useInitialValues;
