import React, { FC } from 'react';
import ArchElementSpecific from 'types/ArchElementSpecific';
import ArchElementGeneric from 'types/ArchElementGeneric';
import { isCompleted } from 'store/rfpResponse/helpers';

import { useArchContextForElement } from '../../../../context/ArchContext';
import useElementSelection from '../../../../hooks/useElementSelection';

type SpringerId = ArchElementSpecific.SPRINGER_LEFT | ArchElementSpecific.SPRINGER_RIGHT;

export interface SpringerProps {
  x: number | string;
  y: number | string;
  springer: SpringerId;
}

const paths: Record<SpringerId, string> = {
  [ArchElementSpecific.SPRINGER_LEFT]:
    'M28.6457 3.02321L117.098 34.7287C121.668 36.3666 124.401 41.0496 123.58 45.8339L115.538 92.6916C114.714 97.4916 110.552 101 105.682 101H11.8528C5.65714 101 0.952448 95.4236 1.99556 89.3164L15.4142 10.7531C16.4613 4.62253 22.791 0.92465 28.6457 3.02321Z',
  [ArchElementSpecific.SPRINGER_RIGHT]:
    'M97.3543 3.02321L8.90157 34.7287C4.33206 36.3666 1.59878 41.0496 2.41993 45.8339L10.4625 92.6916C11.2863 97.4916 15.4482 101 20.3184 101H114.147C120.343 101 125.048 95.4236 124.004 89.3164L110.586 10.7531C109.539 4.62253 103.209 0.92465 97.3543 3.02321Z',
};

const Springer: FC<SpringerProps> = ({ x, y, springer }) => {
  const { response } = useArchContextForElement(springer);
  const completed = isCompleted(response);
  const [isSelected, select] = useElementSelection(springer);

  return (
    <svg x={x} y={y}>
      <path
        className={`enkrateia-arch-springer ${ArchElementGeneric.SPRINGER} ${springer}`}
        d={paths[springer]}
        stroke="#D99F50"
        fill={completed ? '#D99F50' : 'transparent'}
        strokeWidth="3"
        opacity={isSelected ? 1 : 0.3}
        onClick={select}
        cursor="pointer"
      />
    </svg>
  );
};
export default Springer;
