import React, { FC, useMemo } from 'react';
import ScoringRubric from 'types/api/ScoringRubric';

import './ScoringRubricRow.scss';

export interface ScoringRubricRowProps {
  scoringRubric: ScoringRubric;
  active?: boolean;
}

const ScoringRubricRow: FC<ScoringRubricRowProps> = ({ scoringRubric, active }) => {
  const rubricRange = useMemo(() => {
    if (scoringRubric.low_score === scoringRubric.high_score) {
      return scoringRubric.low_score;
    }

    return `${scoringRubric.low_score} - ${scoringRubric.high_score}`;
  }, [scoringRubric]);

  const className = `enkrateia-scoring-rubric-row ${active ? 'active' : ''} ${scoringRubric.highlight_color}`;

  return (
    <>
      <span className={`${className} scoring-range`}>[{rubricRange}] - </span>
      <span className={`${className} description`}>{scoringRubric.description}</span>
    </>
  );
};

export default ScoringRubricRow;
