import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import TicketDto from 'types/api/TicketDto';
import TicketSourceType from 'types/TicketSourceType';

const useTicketTitle = (ticket: TicketDto) => {
  const { t } = useTranslation();

  return useMemo(() => {
    let title = `${t('pages.ticketDetails.ticketNo')} ${ticket.id}`;
    let className = 'header';

    if (ticket.source === TicketSourceType.SOURCE_REMEDIAL_ACTION) {
      title += ` - ${t(`common.tickets.sources.${TicketSourceType.SOURCE_REMEDIAL_ACTION}`)}`;
      className += ' header-remedial-action';
    }

    return { title, className };
  }, [ticket, t]);
};

export default useTicketTitle;
