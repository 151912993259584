import { useMutation, useQueryClient } from '@tanstack/react-query';

import API from 'services/api';
import FormSubmitError from 'errors/FormSubmitError';

import { useRecentTickets, useRfpElementTickets, useTicketConnectedElement, useTickets } from '../ticket';
import { useRfpResponse, useRfpResponsesStats, useSingleRfpResponseStats } from './index';
import { useRfp } from '../rfp';

interface CreateReviewTicketParams<T = any> {
  responseId: number;
  meta?: T;
}

const createReviewTickets = async <T = any>({ responseId }: CreateReviewTicketParams<T>) => {
  try {
    return (await API.post(`/rfp-response/create-review-tickets/${responseId}`)).data;
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

interface UseCreateReviewTicketsMutationParams<T> {
  onSuccess?: (variables: CreateReviewTicketParams<T>) => Promise<void> | void;
  onError?: (error: Error, variables: CreateReviewTicketParams<T>) => Promise<void> | void;
}

const useCreateReviewTicketsMutation = <T = any>({
  onSuccess,
  onError,
}: UseCreateReviewTicketsMutationParams<T> = {}) => {
  const queryClient = useQueryClient();

  return useMutation<void, FormSubmitError, CreateReviewTicketParams<T>>(createReviewTickets, {
    onSuccess: async (_, variables) => {
      for (const value of [
        useTicketConnectedElement.getQueryKey(),
        useRfpElementTickets.getQueryKey(),
        useRecentTickets.getQueryKey(),
        useTickets.getQueryKey(),
        useRfpResponsesStats.getQueryKey(),
        useSingleRfpResponseStats.getQueryKey(),
        useRfp.getQueryKey(),
        useRfpResponse.getQueryKey(),
      ]) {
        await queryClient.invalidateQueries({ queryKey: value });
        await queryClient.refetchQueries({ queryKey: value });
      }

      if (onSuccess) {
        await onSuccess(variables);
      }
    },
    onError: async (error: Error, variables) => {
      if (onError) {
        await onError(error, variables);
      }
    },
  });
};

useCreateReviewTicketsMutation.queries = {
  createReviewTickets,
} as const;

export default useCreateReviewTicketsMutation;
