import { useSelector } from 'store';
import TraroElementResponse from 'types/api/TraroElementResponse';
import UserDto from 'types/api/UserDto';
import { InputOption } from 'components/forms/raw/SelectInput';

const useICContact = (elementResponse?: TraroElementResponse): [string, string, InputOption<number, UserDto>] => {
  const rfpPointOfContact = useSelector((state) => state.rfp.data.point_of_contact);
  const rfpResponsePointOfContact = useSelector((state) => state.rfpResponse.data.point_of_contact);
  const elementPointOfContact = elementResponse?.point_of_contact;

  if (elementPointOfContact) {
    return [
      elementPointOfContact.email,
      `${elementPointOfContact.first_name} ${elementPointOfContact.last_name}`,
      {
        value: elementPointOfContact.id,
        label: `${elementPointOfContact.first_name} ${elementPointOfContact.last_name}`,
        meta: elementPointOfContact,
      },
    ];
  }

  if (rfpResponsePointOfContact) {
    return [
      rfpResponsePointOfContact.email,
      `${rfpResponsePointOfContact.first_name} ${rfpResponsePointOfContact.last_name}`,
      {
        value: rfpResponsePointOfContact.id,
        label: `${rfpResponsePointOfContact.first_name} ${rfpResponsePointOfContact.last_name}`,
        meta: rfpResponsePointOfContact,
      },
    ];
  }

  return [
    rfpPointOfContact.email,
    `${rfpPointOfContact.first_name} ${rfpPointOfContact.last_name}`,
    {
      value: rfpPointOfContact.id,
      label: `${rfpPointOfContact.first_name} ${rfpPointOfContact.last_name}`,
      meta: rfpPointOfContact,
    },
  ];
};

export default useICContact;
