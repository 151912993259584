import React, { FC, useCallback, useMemo } from 'react';
import { OnChangeValue } from 'react-select';
import { Column } from '@tanstack/react-table';

import SelectInput, { InputOption } from 'components/forms/raw/SelectInput';
import TicketWithConnection from 'types/TicketWithConnection';
import TicketSourceType from 'types/TicketSourceType';

import './TicketSourceFilter.scss';

export interface TicketStatusFilterProps {
  column: Column<TicketWithConnection, TicketSourceType>;
}

const TicketSourceFilter: FC<TicketStatusFilterProps> = ({ column }) => {
  const columnFilterValue = (column.getFilterValue() as TicketSourceType | null) ?? null;

  const options: InputOption<TicketSourceType>[] = useMemo(
    () => [
      { value: TicketSourceType.SOURCE_QUESTION_TYPE, label: TicketSourceType.SOURCE_QUESTION_TYPE },
      { value: TicketSourceType.SOURCE_REMEDIAL_ACTION, label: TicketSourceType.SOURCE_REMEDIAL_ACTION },
      { value: TicketSourceType.SOURCE_IT_ISSUE, label: TicketSourceType.SOURCE_IT_ISSUE },
      { value: TicketSourceType.SOURCE_NOT_ASSIGNED, label: TicketSourceType.SOURCE_NOT_ASSIGNED },
    ],
    [],
  );

  const onChange = useCallback(
    (newValue: OnChangeValue<InputOption<TicketSourceType>, false>) => {
      column.setFilterValue(newValue?.value ?? null);
    },
    [column],
  );

  return (
    <SelectInput
      value={options.find((o) => o.value === columnFilterValue) ?? null}
      onChange={onChange as any}
      options={options}
      variant="filled"
      className="enkrateia-table-filter enkrateia-ticket-source-filter"
      isClearable
    />
  );
};

export default TicketSourceFilter;
