import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Button from 'components/Button/Button';
import CommonTransComponents from 'i18n/CommonTransComponents';

export interface SuccessStepProps {
  onClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
}

const SuccessStep = ({ onClose }: SuccessStepProps) => {
  const { t } = useTranslation();

  return (
    <div className="success-step">
      <div className="submit-container">
        <Button variant="primary" onClick={onClose}>
          {t('pages.dashboard.createAssessment.ok')}
        </Button>
      </div>
      <p className="info">
        <Trans
          i18nKey="pages.dashboard.createAssessment.info"
          components={CommonTransComponents}
          url={t('common.footer.links.contact.url')}
        >
          For questions Contact us.
        </Trans>
      </p>
    </div>
  );
};

export default SuccessStep;
