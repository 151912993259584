import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TicketDto from 'types/api/TicketDto';
import TicketStatus from 'types/TicketStatus';
import Button from 'components/Button/Button';

import ChangeTicketStatusModal from './components/ChangeTicketStatusModal/ChangeTicketStatusModal';

export interface ChangeTicketStatusButtonProps {
  ticket: TicketDto;
  disabled?: boolean;
}

const ChangeTicketStatusButton: FC<ChangeTicketStatusButtonProps> = ({ ticket, disabled }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const requestedStatus = ticket.ticket_status === TicketStatus.OPEN ? TicketStatus.CLOSED : TicketStatus.OPEN;
  const variant = requestedStatus === TicketStatus.OPEN ? 'primary' : 'black-filled';

  return (
    <>
      <Button variant={variant} onClick={() => setIsOpen(true)} disabled={disabled}>
        {t(`pages.ticketDetails.changeStateTo.${requestedStatus}.title`)}
      </Button>
      <ChangeTicketStatusModal
        isOpen={isOpen}
        requestedStatus={requestedStatus}
        ticketId={ticket.id}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

export default ChangeTicketStatusButton;
