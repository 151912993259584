import React, { FC } from 'react';
import { useSelector } from 'store';

import externalLink from 'assets/img/externalLink.svg';
import RfpMode from 'types/RfpMode';

import ArchListSwitch from './components/ArchListSwitch/ArchListSwitch';
import ListOverview from './components/ListOverview/ListOverview';
import ArchOverview from './components/ArchOverview/ArchOverview';

import './TraroProjectOverview.scss';

export interface ProjectOverviewProps {}

const TraroProjectOverview: FC<ProjectOverviewProps> = () => {
  const name = useSelector((store) => store.rfp.data.name);
  const url = useSelector((store) => store.rfp.data.RFP_URL);
  const mode = useSelector((store) => store.rfp.mode);

  return (
    <div className="enkrateia-project-overview">
      <div className="head">
        <div className="title">
          <h1>{name}</h1>
          <a href={url} target="_blank" rel="noreferrer">
            <img src={externalLink} alt="link" /> {url}
          </a>
        </div>
        <ArchListSwitch />
      </div>
      <div className="body">{mode === RfpMode.ARCH ? <ArchOverview /> : <ListOverview />}</div>
    </div>
  );
};
export default TraroProjectOverview;
