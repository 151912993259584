/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface Modal<T> {
  open: boolean;
  data: T;
}

interface Slice {
  rfpSubmitModal: Modal<void>;
  traroElementSubmitModal: Modal<void>;
  questionStatusesHelpModal: Modal<void>;
  registrationSuccessModal: Modal<{
    email: string;
    name: string;
    organizationName: string;
    country: {
      label: string;
      value: number | string;
    } | null;
  }>;
  errorModal: Modal<{ message: string }>;
}

interface ModalOpenPayload<T extends keyof Slice> {
  modal: T;
  data: Slice[T]['data'];
}

export const modalsSlice = createSlice({
  name: 'modals',
  initialState: {
    rfpSubmitModal: {
      open: false,
    },
    traroElementSubmitModal: {
      open: false,
    },
    questionStatusesHelpModal: {
      open: false,
    },
    errorModal: {
      open: false,
      data: { message: '' },
    },
  } as Slice,
  reducers: {
    openModal: <T extends keyof Slice>(state: Draft<Slice>, action: PayloadAction<ModalOpenPayload<T>>) => {
      // @ts-ignore
      state[action.payload.modal] = {
        open: true,
        data: action.payload.data,
      };
    },
    closeModal: <T extends keyof Slice>(state: Draft<Slice>, action: PayloadAction<T>) => {
      state[action.payload].open = false;
    },
  },
});

export default modalsSlice.reducer;
