/* eslint-disable @typescript-eslint/naming-convention */
import ArchElementSpecific from 'types/ArchElementSpecific';
import ArchElementsMap from 'types/ArchElementsMap';
import TraroElementResponse from 'types/api/TraroElementResponse';
import QuestionStatus from 'types/QuestionStatus';
import KliparoSection from '../../types/api/KliparoSection';
import QuestionSection from '../../types/api/QuestionSection';

export const isCompleted = (element: TraroElementResponse) =>
  element.question_status === QuestionStatus.DONE || element.question_status === QuestionStatus.REVIEW_ONLY;

export const isUpdateRequired = (element: TraroElementResponse) =>
  element.question_status === QuestionStatus.UPDATE_REQUIRED;

export const getDefaultResponseElement = (
  traroElementId: number | null,
  specificElement: ArchElementSpecific | null = null,
): TraroElementResponse => ({
  arch_element_specific: specificElement as ArchElementSpecific,
  traro_element_comment: [],
  traro_element_score: [],
  response_text: '',
  question_status: QuestionStatus.BEGIN,
  question_score: null,
  traro_element: traroElementId as number,
  kliparo_response_list: [],
});

export const traroResponseListToObject = (
  traroElementList: TraroElementResponse[],
): Record<ArchElementSpecific, TraroElementResponse> => {
  // @ts-ignore
  const result: Record<ArchElementSpecific, TraroElementResponse> = {};

  traroElementList.forEach((element) => {
    result[element.arch_element_specific!] = element;
  });

  Object.getOwnPropertyNames(ArchElementsMap).forEach((element) => {
    const specificElement = element as keyof typeof ArchElementsMap;

    if (result[specificElement] === undefined) {
      result[specificElement] = getDefaultResponseElement(null, specificElement);
    }
  });

  return result;
};

export const getQuestionSectionForResponse = (
  kliparoSections: KliparoSection[],
  traroElementResponse: TraroElementResponse,
): QuestionSection | null => {
  for (const kliparoSection of kliparoSections) {
    for (const question of kliparoSection.question_section_list) {
      if (question.traro_element.id === traroElementResponse.traro_element) {
        return question;
      }
    }
  }

  return null;
};
