import { DateTime } from 'luxon';
import { useSelector } from 'store';

import { InputOption } from 'components/forms/raw/SelectInput';
import TraroElementResponse from 'types/api/TraroElementResponse';
import useUser from 'hooks/useUser';
import RFP from 'types/api/RFP';
import UserDto from 'types/api/UserDto';
import TicketSeverityType from 'types/TicketSeverityType';
import TicketSourceType from 'types/TicketSourceType';

import useQuestionNumberInitialValue from './useQuestionNumberInitialValue';

export interface TicketCreationForm {
  organization: InputOption<number>;
  questionNumber: InputOption<number | null> | null;
  ticketTitle: string;
  assignedTo: InputOption<number>;
  dueDate: DateTime;
  severity: InputOption<TicketSeverityType> | null;
  category: InputOption<TicketSourceType> | null;
  attachment: File | null;
}

const useOrganization = (rfpRelated?: boolean) => {
  const rfpOrganization = useSelector((state) => state.rfpResponse.data.organization);
  const { organization: userOrganization } = useUser();

  if (rfpRelated) {
    return rfpOrganization ?? userOrganization;
  }

  return userOrganization;
};

const usePointOfContact = (
  rfpRelated: boolean | undefined,
  traroElementResponse: TraroElementResponse | undefined,
  availableRfps: InputOption<number, RFP>[] | undefined,
): UserDto => {
  const rfpPointOfContact = useSelector((state) => state.rfp.data.point_of_contact);
  const rfpResponsePointOfContact = useSelector((state) => state.rfpResponse.data.point_of_contact);
  const elementPointOfContact = traroElementResponse?.point_of_contact;

  const user = useUser();
  const firstAvailableRfpPOC = availableRfps?.map((rfp) => rfp.meta.point_of_contact)[0];

  if (rfpRelated) {
    return elementPointOfContact ?? rfpPointOfContact ?? firstAvailableRfpPOC ?? rfpResponsePointOfContact ?? user;
  }

  return firstAvailableRfpPOC ?? user;
};

const useInitialValues = (
  questionNumberOptions: InputOption<number | null>[],
  traroElementResponse: TraroElementResponse | undefined,
  availableRfps: InputOption<number, RFP>[] | undefined,
  severityOptions: InputOption<TicketSeverityType>[],
  categoryOptions: InputOption<TicketSourceType>[],
  rfpRelated?: boolean,
): TicketCreationForm => {
  const { isReviewer } = useUser();
  const organization = useOrganization(rfpRelated);
  const pointOfContact = usePointOfContact(rfpRelated, traroElementResponse, availableRfps);
  const questionNumber = useQuestionNumberInitialValue(questionNumberOptions, traroElementResponse);

  return {
    organization: {
      value: organization.id,
      label: organization.name,
    },
    questionNumber,
    ticketTitle: '',
    assignedTo: {
      value: pointOfContact.id,
      label: `${pointOfContact.first_name} ${pointOfContact.last_name}`,
    },
    dueDate: DateTime.now().plus({ days: 7 }),
    severity: isReviewer ? severityOptions[0] : null,
    category: categoryOptions[0],
    attachment: null,
  };
};

export default useInitialValues;
