import React from 'react';
import { useFormikContext } from 'formik';

import KliparoResponseStatusIndicator from 'components/KliparoResponseStatusIndicator/KliparoResponseStatusIndicator';
import QuestionStatus from 'types/QuestionStatus';

import { UpdateResponseStatusFormValues } from '../../hooks/useInitialValues';

export interface QuestionStatusSwitchButtonsProps {
  status: QuestionStatus;
}

const QuestionStatusSwitchButton = ({ status }: QuestionStatusSwitchButtonsProps) => {
  const { values, setFieldValue } = useFormikContext<UpdateResponseStatusFormValues>();

  return (
    <KliparoResponseStatusIndicator
      variant="large"
      status={status}
      className={status === values.status ? 'active' : 'inactive'}
      onClick={() => setFieldValue('status', status)}
    />
  );
};

export default QuestionStatusSwitchButton;
