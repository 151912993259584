import { useCallback } from 'react';

const useGReCaptcha = (): (() => Promise<string>) =>
  useCallback(
    () =>
      new Promise<string>((resolve) => {
        grecaptcha.ready(() => {
          grecaptcha.execute(window.reCaptchaSiteKey, { action: 'submit' }).then((token) => {
            resolve(token);
          });
        });
      }),
    [],
  );

export default useGReCaptcha;
