import { useQuery } from '@tanstack/react-query';

import TicketDto from 'types/api/TicketDto';
import API from 'services/api';
import FormSubmitError from 'errors/FormSubmitError';

interface RfpResponseTicketsResponse {
  error: boolean;
  error_message: string;
  ticket_list: TicketDto[];
}

export const fetchRecentTickets = async () => {
  try {
    return (await API.get<RfpResponseTicketsResponse>(`/ticket-list/?recent`)).data.ticket_list;
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

const getQueryKey = () => ['recentTickets'];

const useRecentTickets = () => useQuery(getQueryKey(), () => fetchRecentTickets());

useRecentTickets.getQueryKey = getQueryKey;
useRecentTickets.queries = {
  fetchRecentTickets,
} as const;

export default useRecentTickets;
