import React, { FC, ReactNode } from 'react';

export interface TimelineEntryProps {
  date: string;
  children: ReactNode;
}

const TimelineEntry: FC<TimelineEntryProps> = ({ date, children }) => (
  <>
    <div className="date">{date}</div>
    <div className="point">
      <div className="dot"></div>
      <div className="line"></div>
    </div>
    <div className="content">{children}</div>
  </>
);

export default TimelineEntry;
