import React from 'react';
import { useTranslation } from 'react-i18next';

import KliparoResponseStatusIndicator from 'components/KliparoResponseStatusIndicator/KliparoResponseStatusIndicator';
import QuestionStatus from 'types/QuestionStatus';

export interface ResponseStatusHelpRowProps {
  status: QuestionStatus;
}

const ResponseStatusHelpRow = ({ status }: ResponseStatusHelpRowProps) => {
  const { t } = useTranslation();

  return (
    <>
      <KliparoResponseStatusIndicator status={status} />
      <KliparoResponseStatusIndicator status={status} variant="small" />
      <label>{t(`responseStatusHelp.${status}`)}</label>
    </>
  );
};

export default ResponseStatusHelpRow;
