import React, { FC, ReactNode, useCallback, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useTabsContext } from '../../context/TabsContext';

export interface TabProps {
  tabKey: string;
  label: string | ReactNode;
  children: ReactNode;
  saveScroll?: boolean;
}

const Tab: FC<TabProps> = ({ children, tabKey, saveScroll }) => {
  const { activeKey } = useTabsContext();
  const scrollPositionRef = useRef<{ left: number; top: number }>({ left: 0, top: 0 });

  const onPositionSave = useCallback(
    (htmlNode: HTMLElement) => {
      scrollPositionRef.current.left = htmlNode.parentElement?.scrollLeft ?? 0;
      scrollPositionRef.current.top = htmlNode.parentElement?.scrollTop ?? 0;
    },
    [scrollPositionRef],
  );

  const onPositionLoad = useCallback(
    (htmlNode: HTMLElement) => {
      if (!saveScroll) {
        return;
      }

      htmlNode.parentElement?.scrollTo({
        left: scrollPositionRef.current.left,
        top: scrollPositionRef.current.top,
      });
    },
    [scrollPositionRef, saveScroll],
  );

  return (
    <CSSTransition
      in={activeKey === tabKey}
      key={tabKey}
      classNames="scale-y"
      timeout={300}
      mountOnEnter
      unmountOnExit
      onExit={onPositionSave}
      onEntering={onPositionLoad}
    >
      <div className="enkrateia-tabs-tab">{children}</div>
    </CSSTransition>
  );
};

export default Tab;
