import { useQuery } from '@tanstack/react-query';

import API, { ApiResponse } from 'services/api';
import RFP from 'types/api/RFP';
import FormSubmitError from 'errors/FormSubmitError';
import { InputOption } from '../../components/forms/raw/SelectInput';

export const fetchAvailableRFPs = async () => {
  try {
    return (await API.get<ApiResponse & { rfp_list: RFP[] }>('/rfp-list/')).data.rfp_list;
  } catch (e) {
    throw new FormSubmitError('An error occurred while sending request to the server. Please try again.');
  }
};

export const fetchMappedAvailableRFPs = async (): Promise<InputOption<number, RFP>[]> => {
  const rfps = await fetchAvailableRFPs();

  return rfps.map((rfp) => ({
    value: rfp.id,
    label: rfp.name,
    meta: rfp,
  }));
};

const getQueryKey = () => ['availableRfps'];

const INITIAL_DATA: InputOption<number, RFP>[] = [];

const useAvailableRFPs = (initialData: InputOption<number, RFP>[] | undefined = INITIAL_DATA) =>
  useQuery(getQueryKey(), fetchMappedAvailableRFPs, { initialData });

useAvailableRFPs.getQueryKey = getQueryKey;
useAvailableRFPs.queries = {
  fetchAvailableRFPs,
  fetchMappedAvailableRFPs,
} as const;

export default useAvailableRFPs;
