import { useMemo } from 'react';

export interface LoginFormData {
  email: string;
  password: string;
}

const useInitialValues = () =>
  useMemo(
    (): LoginFormData => ({
      email: '',
      password: '',
    }),
    [],
  );

export default useInitialValues;
