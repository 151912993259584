import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'store';
import { setRfpData } from 'store/rfp';
import { setRfpResponseData } from 'store/rfpResponse';
import { useRfp, useRfpResponse } from 'api';

const useDataLoader = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((store) => store.auth.isLoggedIn);

  if (!isLoggedIn) {
    throw new Error('User is not logged in');
  }

  const { rfpId, rfpResponseId } = useParams<{ rfpId: string; rfpResponseId: string }>();

  const { data: rfp, isLoading: isRfpLoading, error: rfpError } = useRfp(Number(rfpId));
  const {
    data: rfpResponse,
    isLoading: isRfpResponseLoading,
    error: rfpResponseError,
  } = useRfpResponse(Number(rfpResponseId));

  const reduxRfpId = useSelector((state) => state.rfp.data.id);
  const reduxRfpResponseId = useSelector((state) => state.rfpResponse.data.id);

  useEffect(() => {
    if (
      rfp !== undefined &&
      rfpResponse !== undefined &&
      Number(rfpId) === rfp.id &&
      Number(rfpResponseId) === rfpResponse?.id
    ) {
      dispatch(setRfpData(rfp));
      dispatch(setRfpResponseData({ rfp, rfpResponse }));
    }
  }, [dispatch, rfp, rfpResponse, rfpId, rfpResponseId]);

  const renderChildren =
    rfp !== undefined &&
    rfpResponse !== undefined &&
    Number(rfpId) === rfp.id &&
    Number(rfpResponseId) === rfpResponse?.id &&
    Number(rfpId) === reduxRfpId &&
    Number(rfpResponseId) === reduxRfpResponseId;

  return [isRfpLoading || isRfpResponseLoading, rfpError ?? rfpResponseError, renderChildren] as const;
};

export default useDataLoader;
