import React, { ChangeEventHandler, FC, useCallback } from 'react';
import { Column, Table } from '@tanstack/react-table';

import DebouncedTextInput from 'components/forms/raw/DebouncedTextInput';

import './Filter.scss';

export interface FilterProps {
  column: Column<any>;
  table: Table<any>;
}

const Filter: FC<FilterProps> = ({ column, table }) => {
  const columnFilterValue = (column.getFilterValue() as string | number) ?? '';

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      column.setFilterValue(event.target.value);
    },
    [column],
  );

  const { CustomFilter } = column.columnDef.meta ?? {};
  if (CustomFilter) {
    return <CustomFilter column={column} table={table} />;
  }

  return (
    <DebouncedTextInput
      className="enkrateia-table-filter"
      value={columnFilterValue}
      onChange={onChange}
      debounce={200}
      variant="filled"
      noLabel
      fullWidth
      placeholder="Search..."
    />
  );
};

export default Filter;
