/* eslint-disable @typescript-eslint/naming-convention */
import UserDto from './api/UserDto';

export interface User extends UserDto {
  isReviewer: boolean;
  isProponent: boolean;
}

export interface ReviewerUser extends User {
  user_type: 'RER';
  isReviewer: true;
  isProponent: false;
}

export const isReviewer = (user: UserDto): user is ReviewerUser => {
  return user.user_type === 'RER';
};

export interface ProponentUser extends User {
  user_type: 'PRT';
  isReviewer: false;
  isProponent: true;
}

export const isProponent = (user: UserDto): user is ReviewerUser => {
  return user.user_type === 'PRT';
};

export default User;
