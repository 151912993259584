import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import { FilledPlusIcon } from 'components/SvgIcons';
import LoaderOverlay from 'components/LoaderOverlay/LoaderOverlay';
import { useCreateReviewTicketsMutation } from 'api';

import './CreateReviewTickets.scss';

interface CreateReviewTicketsProps {
  responseId: number;
}

const CreateReviewTickets = ({ responseId }: CreateReviewTicketsProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { mutateAsync: createReviewTickets, isLoading } = useCreateReviewTicketsMutation({
    onSuccess: () => {
      setIsOpen(false);
    },
  });

  return (
    <>
      <Button variant="tertiary" onClick={() => setIsOpen(true)}>
        <FilledPlusIcon className="plus-icon" size={20} color="#2C2B63" />
        {t('pages.projectOverview.mainPanel.createReviewTickets.button')}
      </Button>
      <Modal
        variant="kliparo"
        title={t('pages.projectOverview.mainPanel.createReviewTickets.modalTitle')}
        className="enkrateia-create-review-tickets-modal"
        isOpen={isOpen}
      >
        <div className="enkrateia-create-review-tickets-modal-content">
          <Button variant="primary" onClick={() => createReviewTickets({ responseId })}>
            {t('pages.projectOverview.mainPanel.createReviewTickets.confirm')}
          </Button>
          <Button variant="light-grey-filled" onClick={() => setIsOpen(false)}>
            {t('pages.projectOverview.mainPanel.createReviewTickets.cancel')}
          </Button>
        </div>
        <LoaderOverlay open={isLoading} />
      </Modal>
    </>
  );
};

export default CreateReviewTickets;
