import { useCallback } from 'react';
import { FormikHelpers } from 'formik';

import { useCreatePasswordMutation } from 'api';

import { CreatePasswordForm } from './useInitialValues';

const useCreatePasswordSubmit = () => {
  const { mutateAsync, isLoading, isSuccess } = useCreatePasswordMutation({
    onSuccess: async (data, variables) => {
      variables.meta.resetForm();
    },
    onError: async (error, variables) => {
      variables.meta.setFieldError('password', error.message);
    },
  });

  const submit = useCallback(
    async (values: CreatePasswordForm, helpers: FormikHelpers<CreatePasswordForm>) => {
      return mutateAsync({ ...values, meta: helpers });
    },
    [mutateAsync],
  );

  return [submit, isLoading, isSuccess] as const;
};

export default useCreatePasswordSubmit;
