import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import TicketCommentDto from 'types/api/TicketCommentDto';
import useUser from 'hooks/useUser';
import useFormattedDate, { DEFAULT_DATE_TIME_FORMAT } from 'hooks/useFormattedDate';
import { AttachmentIcon } from 'components/SvgIcons';

import './Comment.scss';

export interface CommentProps {
  comment: TicketCommentDto;
}

const Comment: FC<CommentProps> = ({ comment }) => {
  const { t } = useTranslation();
  const initials = comment.commenter.first_name[0] + comment.commenter.last_name[0];
  const user = useUser();

  const isMine = user.id === comment.commenter.id;
  const isPublished = comment.published;
  const ticketTimestamp = useFormattedDate(comment.comment_time, DEFAULT_DATE_TIME_FORMAT);

  return (
    <div className={`enkrateia-ticket-comment ${isMine ? 'my-comment' : ''} ${isPublished ? 'published' : 'internal'}`}>
      <div className="avatar">{initials}</div>
      <div className="content">
        <div className="head">
          <h3>
            {comment.commenter.first_name} {comment.commenter.last_name} ({comment.commenter.organization.name})
          </h3>
          <div className="comment-publicity">
            {isPublished ? t('pages.ticketDetails.comments.published') : t('pages.ticketDetails.comments.internal')}
          </div>
        </div>
        <p className="timestamp">{ticketTimestamp}</p>
        <p className="comment">{comment.ticket_comment}</p>
        {comment.attachment && (
          <a className="attachment" href={comment.attachment.url} target="_blank" rel="noopener noreferrer">
            <AttachmentIcon size={28} color="white" className="attachment-icon" />
            <span>{comment.attachment.file_name}</span>
          </a>
        )}
      </div>
    </div>
  );
};

export default Comment;
