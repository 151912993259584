import { useParams } from 'react-router-dom';
import { useSelector } from 'store';
import { useMemo } from 'react';

import { getDefaultResponseElement } from 'store/rfpResponse/helpers';
import TraroElementResponse from 'types/api/TraroElementResponse';
import QuestionSectionWithResponse from 'types/QuestionSectionWithResponse';
import QuestionSection from 'types/api/QuestionSection';
import KliparoSection from 'types/api/KliparoSection';

const getTraroElement = (elements: TraroElementResponse[], traroElementId: number | null | undefined) => {
  const traroElementResponse = elements.find((element) => element.traro_element === traroElementId);

  if (traroElementResponse !== undefined) {
    return traroElementResponse;
  }

  return getDefaultResponseElement(null);
};

const getQuestion = (
  sections: KliparoSection[],
  sectionNumber: string | undefined,
  questionNumber: string | undefined,
): [QuestionSection, KliparoSection] => {
  const selectedSection = sections.find((section) => String(section.section_number) === sectionNumber);

  if (selectedSection === undefined) {
    throw new Error('Invalid section number: ' + questionNumber);
  }

  const selectedQuestion = selectedSection.question_section_list.find(
    (question) => String(question.question_number) === questionNumber,
  );

  if (selectedQuestion === undefined) {
    throw new Error('Invalid question number: ' + questionNumber);
  }

  return [selectedQuestion, selectedSection];
};

export interface QuestionSectionWithResponseAndContext extends Required<QuestionSectionWithResponse> {
  section: KliparoSection;
}

const useKliparoElement = (): QuestionSectionWithResponseAndContext => {
  const { sectionNumber, questionNumber } = useParams<{ sectionNumber: string; questionNumber: string }>();
  const kliparoSections = useSelector((store) => store.rfp.data.kliparo_section_list);
  const traroElementResponseList = useSelector((store) => store.rfpResponse.data.traro_element_response_list);

  return useMemo(() => {
    const [question, section] = getQuestion(kliparoSections, sectionNumber, questionNumber);
    const response = getTraroElement(traroElementResponseList, question.traro_element.id);

    return {
      ...question,
      rfpElementResponse: response,
      section,
    };
  }, [questionNumber, kliparoSections, traroElementResponseList]);
};

export default useKliparoElement;
