import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import Skeleton from 'react-loading-skeleton';
import { isEmpty } from 'lodash';

import { useDashboardMessages } from 'api';
import Announcement from 'components/Announcement/Announcement';
import useUserPersistedState from 'hooks/useUserPersistedState';

import './DashboardMessages.scss';

const DashboardMessages = () => {
  const { data, isLoading } = useDashboardMessages();
  const className = `enkrateia-dashboard-messages ${!isEmpty(data) || isLoading ? 'has-messages' : ''}`;
  const [dismissedAnnouncements, setDismissedAnnouncements] = useUserPersistedState<number[]>(
    'announcements.dismissed',
    [],
  );

  const filteredData = useMemo(
    () => data?.filter((message) => !dismissedAnnouncements.includes(message.id)),
    [data, dismissedAnnouncements],
  );

  return (
    <div className={className}>
      {isLoading && <Skeleton height={120} />}
      {filteredData?.map((message) => (
        <Announcement
          dismissible
          key={message.id}
          onDismissed={() => setDismissedAnnouncements((prev) => [...prev, message.id])}
        >
          <ReactMarkdown>{message.message}</ReactMarkdown>
        </Announcement>
      ))}
    </div>
  );
};

export default DashboardMessages;
