import React from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

import TicketDto from 'types/api/TicketDto';
import { useTicketConnectedElement } from 'api';
import FormikSelectInput from 'components/forms/formik/FormikSelectInput/FormikSelectInput';
import { useLinkedCriterionUrl } from 'hooks/useLinkedCriterionUrl';

import { LinkedCriterionInputOption } from '../../hooks/useInitialValues';

import './LinkedCriterionField.scss';

interface LinkedCriterionFieldProps {
  ticket: TicketDto;
  selectableLinkedCriteria: LinkedCriterionInputOption[];
  disabled?: boolean;
}

const LinkedCriterionField = ({ selectableLinkedCriteria, ticket, disabled }: LinkedCriterionFieldProps) => {
  const { t } = useTranslation();
  const { data: ticketConnectedElement, isFetching } = useTicketConnectedElement(
    ticket.id,
    !!ticket.traro_element_response_id,
  );

  const linkedCriterionUrl = useLinkedCriterionUrl(ticketConnectedElement);

  if (!ticket.traro_element_response_id) {
    return null;
  }

  return (
    <div className="enkrateia-ticket-linked-criterion-field">
      <FormikSelectInput
        variant="filled"
        name="linkedCriterion"
        options={selectableLinkedCriteria}
        disabled={disabled}
      />
      {!!ticketConnectedElement && !isFetching && (
        <Link to={linkedCriterionUrl}>{t('common.tickets.openLinkedCriterion')}</Link>
      )}
      {isFetching && <Skeleton width={80} height={28} />}
    </div>
  );
};

export default LinkedCriterionField;
