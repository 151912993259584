import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import DataLoader from 'store/DataLoader/DataLoader';

import LoginPage from './LoginPage/LoginPage';
import ProjectOverviewPage from './ProjectOverviewPage/ProjectOverviewPage';
import TraroElementPage from './TraroElementPage/TraroElementPage';
import Dashboard from './Dashboard/Dashboard';
import KliparoElementPage from './KliparoElementPage/KliparoElementPage';
import TicketsOverviewPage from './TicketsOverviewPage/TicketsOverviewPage';
import TicketPage from './TicketPage/TicketPage';
import RegisterPage from './RegisterPage/RegisterPage';
import ResetPasswordPage from './ResetPasswordPage/ResetPasswordPage';
import CreatePasswordPage from './CreatePasswordPage/CreatePasswordPage';
import OrganizationProfilePage from './OrganizationProfilePage/OrganizationProfilePage';

const Routing = () => {
  const location = useLocation();

  return (
    <>
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/create-password/:encodedEmail/:token" element={<CreatePasswordPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/tickets"
              element={
                <ProtectedRoute>
                  <TicketsOverviewPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/organization"
              element={
                <ProtectedRoute>
                  <OrganizationProfilePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/ticket/:id"
              element={
                <ProtectedRoute>
                  <TicketPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/rfp/:rfpId/rfp-response/:rfpResponseId"
              element={
                <ProtectedRoute>
                  <DataLoader>
                    <ProjectOverviewPage />
                  </DataLoader>
                </ProtectedRoute>
              }
            />
            <Route
              path="/rfp/:rfpId/rfp-response/:rfpResponseId/rfp-element-response/:traroElementSpecific"
              element={
                <ProtectedRoute>
                  <DataLoader>
                    <TraroElementPage />
                  </DataLoader>
                </ProtectedRoute>
              }
            />
            <Route
              path="/rfp/:rfpId/rfp-response/:rfpResponseId/rfp-question-response/:sectionNumber/:questionNumber"
              element={
                <ProtectedRoute>
                  <DataLoader>
                    <KliparoElementPage />
                  </DataLoader>
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate replace to="/dashboard" />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

export default Routing;
