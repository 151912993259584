import React, { FC } from 'react';

import Splash from './components/Splash/Splash';
import Wrapper from './components/Wrapper/Wrapper';

import './SplashScreenTemplate.scss';

export interface SplashScreenTemplateProps {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}

const SplashScreenTemplate: FC<SplashScreenTemplateProps> = ({ children, className = '' }) => (
  <main className={`enkrateia-splash-screen-template ${className}`}>
    <Splash />
    <Wrapper>{children}</Wrapper>
  </main>
);

export default SplashScreenTemplate;
