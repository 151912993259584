import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormikTextAreaInput from 'components/forms/formik/FormikTextAreaInput/FormikTextAreaInput';

export interface UrlQuestionProps {
  disabled?: boolean;
  name: string;
}

const UrlQuestion: FC<UrlQuestionProps> = ({ disabled, name }) => {
  const { t } = useTranslation();

  return (
    <FormikTextAreaInput
      name={name}
      type="url"
      className="url-question"
      rows={1}
      variant="filled"
      placeholder={t('common.form.textPlaceholder')}
      disabled={disabled}
      autoHeight
    />
  );
};

export default UrlQuestion;
