import React from 'react';
import { useFormikContext } from 'formik';

import { UpdateResponseStatusFormValues } from '../../hooks/useInitialValues';

const QuestionStatusSwitchError = () => {
  const { errors, touched } = useFormikContext<UpdateResponseStatusFormValues>();

  return <>{touched.status && errors.status && <p className="status-switch-error">{errors.status}</p>}</>;
};

export default QuestionStatusSwitchError;
