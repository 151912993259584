import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const useSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    organization: yup.object().required(t('common.form.validation.required')),
    questionNumber: yup.object().nullable(),
    ticketTitle: yup.string().required(t('common.form.validation.required')),
    assignedTo: yup.object().required(t('common.form.validation.required')),
    dueDate: yup.mixed().required(t('common.form.validation.required')),
  });
};

export default useSchema;
